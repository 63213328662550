import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {MainTableBodyProps} from '../utils/tableUtils';
import {concatName, Rest, TableUtils} from '../utils/utils';
import View from './OperadoresList';
import OperadorData from './enteties/operadorData';
import _ from 'lodash';
import {FormInstance, notification} from 'antd';
import OperadorFormContainer from './OperadorFormContainer';
import DownloadDocItem from '../providers/entities/DownloadDocItem';
import OperadorDocument from './enteties/OperadorDocument';
import EnumModuloType from '../enum/EnumModuloType';

interface IProps {
}

export interface IState extends MainTableBodyProps {
    limit: number;
    page?: number;
    offset?: number;
    dataCount?: number;

    operadorData?: OperadorData[];
    highlightOperadorName?: string[];
    operadorNameFilter?: { value: string }[];

    lastFilter?: any;
    filtersCollapsed?: boolean;
    filterKey?: number;
    nFilters?: number;

    //Operador Form
    currentOperador?: OperadorData;
    operadorFormVisible?: boolean;
    operadorFormKey?: number;
    operadorFormTitle?: string;
    downloadDocList: DownloadDocItem[];


}

class OperadoresListContenedor extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {
        limit: 0,
        offset: 0,
        filtersCollapsed: true,
        downloadDocList: [],

    }

    public componentDidMount() {
        this.setState(TableUtils.calculatePageSizeForMainTable(), () => this.loadData());
    }

    private loadData = (page?: number) => {
        const values = this.state.lastFilter;
        const filter = {} as any;
        if (values) {
            filter.operadorNombreCompleto = values.nameNombre ? values.nameNombre : undefined;
            filter.nif = values.codeIdentificacion ? values.codeIdentificacion : undefined;
            filter.typeEstado = (values.typeEstado && values.typeEstado.length > 0) ? values.typeEstado : undefined;
        }

        Rest<{ type: string; limit: number; offset: number }, { data: OperadorData[], dataCount?: number }>().operation({
                type: 'SelectOperadorListPagedEx',
                limit: this.state.limit,
                offset: (((page || 1) - 1) * this.state.limit),
                ...filter
            }
        ).then(response => {
            let operadores: OperadorData[] = [];
            if (response) {
                operadores = (response.data || []).map((operador: OperadorData) => {
                    operador.key = operador.idOperador;
                    operador.nameCompleto = concatName(operador.nameNombre, operador.nameApellido1, operador.nameApellido2)
                    operador.altaPor = concatName(operador.nameNombreAltaPor, operador.nameApellido1AltaPor, operador.nameApellido2AltaPor)
                    return operador;
                });
            }

            const highlightOperadorName = [];
            if (values) {
                values.nameNombre && highlightOperadorName.push(values.nameNombre);
                values.nameApellido1 && highlightOperadorName.push(values.nameApellido1);
                values.nameApellido2 && highlightOperadorName.push(values.nameApellido2);
            }


            this.setState({
                operadorData: operadores,
                dataCount: response.dataCount || 0,
                highlightOperadorName,
                lastFilter: values,
                page
            });
        });
    }

    public render() {
        return (
            <>
                <View
                    {...this.state}
                    onClickRRefreshState={this.onClickRefreshState}
                    onCollapseFilters={this.onCollapseFilters}
                    onClickNewOperador={this.onClickNewOperador}
                    onChangePage={this.onChangePage}
                    onShowDetails={this.onShowDetails}
                    searchOperadorName={this.searchOperadorName}
                    applyFilters={this.applyFilters}
                    resetFilters={this.resetFilters}
                    onConfirmChangeOperadorState={this.onConfirmChangeOperadorState}
                    onReenviarEnlaceDeRegistro={this.onReenviarEnlaceDeRegistro}
                    onDesbloquearOperador={this.onDesbloquearOperador}

                />
                <OperadorFormContainer
                    currentOperador={this.state.currentOperador}
                    operadorFormVisible={this.state.operadorFormVisible}
                    operadorFormKey={this.state.operadorFormKey}
                    operadorFormTitle={this.state.operadorFormTitle}
                    onCloseOperadorForm={this.onCloseOperadorForm}
                    onSaveOperadorFormData={this.onSaveOperadorFormData}
                    downloadDocList={this.state.downloadDocList}
                    updateDownloadDocList={this.updateDownloadDocList}
                    removeDocFromList={this.removeDocFromList}
                />
            </>
        );
    }

    private onChangePage = (page: number) => {
        this.loadData(page);
    }

    private onClickRefreshState = () => {
        this.loadData(this.state.page);
    }

    private onCollapseFilters = () => {
        this.setState({filtersCollapsed: !this.state.filtersCollapsed});
    }

    private onClickNewOperador = () => {
        this.setState({
            operadorFormKey: new Date().getTime(),
            operadorFormVisible: true,
            currentOperador: undefined,
            operadorFormTitle: this.props.t('nuevoOperador'),
            downloadDocList: []
        })
    }

    private onShowDetails = (id: number) => {
        const promises: Array<Promise<any>> = [];
        const rqOperadorDetailsData = Rest<{ type: string; idOperador: number }, OperadorData>();
        const rqOperadorDocuments = Rest<{ type: string, idOperador: number }, OperadorDocument[]>();

        promises.push(rqOperadorDetailsData.operation({type: 'SelectOperadorDataById', idOperador: id}));
        promises.push(rqOperadorDocuments.operation({type: 'SelectOperadorDocumentosUploaded', idOperador: id}));

        Promise.all(promises).then(response => {
            const doc: OperadorDocument[] = response[1];
            const downloadDoc: DownloadDocItem[] = doc.map(e => {
                let di: DownloadDocItem = {
                    uid: e.idArchivo.toString(), name: e.nameArchivo, status: 'done',
                    size: e.nbrtamano, type: e.typeMime, fileId: e.idArchivo, groupId: e.typeDocumento.toString()
                };
                return di;
            });
            this.setState({
                operadorFormKey: new Date().getTime(),
                operadorFormVisible: true,
                operadorFormTitle: this.props.t('editarOperador', {operador: response[0].nameCompleto}),
                currentOperador: response[0],
                downloadDocList: downloadDoc ? downloadDoc : [],
            })

        });
    }

    private searchOperadorName = _.debounce((value: string) => this.doSearchOperadorName(value), 500);

    private doSearchOperadorName(value: string) {

        if (value.length < 3) {
            return;
        }

        Rest<{ type: string; operadorNombreCompleto: string }, OperadorData[]>()
            .operation({type: 'SelectOperadorListFilteredPorNombre', operadorNombreCompleto: value}, true
            ).then(response => {
            this.setState({
                operadorNameFilter: response.map(e => {
                    return {value: concatName(e.nameNombre, e.nameApellido1, e.nameApellido2)}
                }),
                highlightOperadorName: undefined
            });
        });
    }

    private applyFilters = (form: FormInstance) => {
        var nFilters = 0;
        const values = form.getFieldsValue();
        var filters = values as OperadorData;
        if(filters.nameNombre != undefined && filters.nameNombre != ""){
            nFilters = nFilters+1;
        } if (filters.codeIdentificacion != undefined && filters.codeIdentificacion != ""){
            nFilters = nFilters+1;
        } if(filters.typeEstado != undefined && filters.typeEstado != 0){
            nFilters = nFilters+1;
        }
        this.setState({nFilters: nFilters, lastFilter: values, filtersCollapsed: true}, () => this.loadData(1));
    }

    private resetFilters = () => {
        this.setState({
            page: 1,
            nFilters: 0,
            lastFilter: undefined,
            filterKey: new Date().getTime()
        }, () => this.loadData(this.state.page))
    }

    private onConfirmChangeOperadorState = (changeOperadorState: { id: number; nextState: number }) => {
        Rest<{ type: string; idOperador: number; typeEstado: number }, any>()
            .operation({
                    type: 'SetOperadorEstado', idOperador: changeOperadorState.id,
                    typeEstado: changeOperadorState.nextState
                }, true
            ).then(() => {
            notification['success']({
                message: this.props.t('providerList:actionPerformed')
            });
            this.loadData(this.state.page);
        });

    }


    private onCloseOperadorForm = () => {
        this.setState({operadorFormVisible: false})
    }

    private onSaveOperadorFormData = () => {
        this.setState({operadorFormVisible: false});
        notification['success']({
            message: this.props.t('providerList:actionPerformed')
        });
        this.loadData(this.state.page);
    }

    private onReenviarEnlaceDeRegistro = (record: OperadorData) => {
        Rest<{ type: string; idOperador: number }, any>().operation({
            type: 'ReSendOperadorAltaMessage',
            idOperador: record.idOperador})
            .then(() => {
                notification['success']({
                    message: this.props.t('providerList:enlaceDeRegistroReenviado')
                });
                this.loadData(this.state.page);
            });
    }

    private onDesbloquearOperador = (record: OperadorData) => {
        Rest<{ type: string; idPersona: number, typeModulo: number }, any>().operation({
            type: 'UnblockInactiveUser',
            idPersona: record.idOperador,
            typeModulo: EnumModuloType.MODULO_ADMINISTRACION})
            .then(() => {
                notification['success']({
                    message: this.props.t('providerList:userUnblocked')
                });
                this.loadData(this.state.page);
            });

    }


    private updateDownloadDocList = (doclist: DownloadDocItem[]) => {
        this.setState({downloadDocList: doclist});
    }

    private removeDocFromList = (fileId: number) => {
        const dl: DownloadDocItem[] = this.state.downloadDocList;
        const idx = dl.findIndex(e => e.fileId === fileId);
        if (typeof idx !== 'undefined') {
            dl.splice(idx, 1);
            this.setState({downloadDocList: dl});
        }
    }
}

export default withTranslation('usuariosOperadoresList')(withRouter(OperadoresListContenedor));
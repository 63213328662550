import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Modal, notification, Popconfirm, Popover, Space, Table, Tooltip} from 'antd';
import {ICuenta, ICuentaLocation, ILicensiasParaCuenta} from './entities/Cuenta';
import {useTranslation} from 'react-i18next';
import {AlignType} from 'rc-table/lib/interface';
import {Rest, TableIcons} from '../utils/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faEdit, faMoneyCheck, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import moment, {Moment} from 'moment';
import {RangePickerProps} from 'antd/es/date-picker';

interface IProps {
    visible: boolean;
    locationList?: ICuentaLocation[];
    selectedAccount: ICuenta;
    onClickNewEditLocation: (record?: ICuentaLocation) => void;
    onClickDeleteLocation: (record: ICuentaLocation) => void;
    onClose: () => void;
}

const LocationListContainer = (props: IProps) => {

    const {t} = useTranslation(['providerAccountListContainer']);

    const actionRenderer = (value: number, record: ICuentaLocation) => {
        return (
            <Space size='small' style={{display: 'flex', justifyContent : 'center'}} key={record.idLocalizacion} >
                <Tooltip title={t('editLocation')} placement='left'>
                    <Button className={'button__prescription'} type='primary' icon={<FontAwesomeIcon icon={faEdit} style={{ color: '#ffffff', fontSize: '0.8rem' }}></FontAwesomeIcon>}
                            onClick={() => props.onClickNewEditLocation(record)}

                    />
                </Tooltip>
                <Popconfirm placement="bottom" title={t('deleteLocation')} 
                    onConfirm={() => props.onClickDeleteLocation!(record)} okText={t('buttons:confirmar')} cancelText={t('buttons:cancel')}>
                    <Button  className={'button__prescription'}  type="primary" danger icon={<FontAwesomeIcon icon={faTrash} style={{ color: '#ffffff', fontSize: '0.8rem' }} />} />
                </Popconfirm>
            </Space>
        );
    };


    const columns = [
        {
            title: t('nombreLocation'),
            dataIndex: 'nameLocalizacion',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '10rem',
        },
        {
            title: t('identificadorExterno'),
            dataIndex: 'idExterno',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '3rem',
        },

        {   dataIndex: 'idLocalizacion', 
            align: 'center' as AlignType,
            render: actionRenderer,
            width: '3rem',
        },
    ];

    return (
        <>
            <Modal
                title={t('locationCuentaList', {nameCuenta: props.selectedAccount ? props.selectedAccount.nameCuenta : ''})}
                visible={props.visible || false}
                style={{top: 40}}
                destroyOnClose
                width={800}
                footer={null}
                onCancel={props.onClose}
            >
                <Space size='small' className='table-button-bar'>
                    
                        <Button
                            icon={<FontAwesomeIcon icon={faPlus} style={TableIcons.PRIMARY_BUTTON_ICON_STYLE}/>}
                            onClick={() => props.onClickNewEditLocation()}
                        >
                            {t('newLocation')}
                        </Button>
                </Space>


                <div className='table-container'>
                    <Table className='table__custom-expandable-icon'
                           columns={columns}
                           size='small'
                           dataSource={props.locationList}
                           pagination={{
                               position: ['bottomRight'],
                               hideOnSinglePage: true,
                               showSizeChanger: false,
                           }}
                    />
                </div>

            </Modal>

        </>
    );

};

export default LocationListContainer;
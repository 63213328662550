import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IState as IStateContainer} from './OperadorFormContainer';
import {Col, Row, Form, FormInstance, Input, Modal, Select, Upload, Button, DatePicker, Checkbox} from 'antd';
import OperadorData from './enteties/operadorData';
import {UploadOutlined} from '@ant-design/icons';
import RcFileEx from '../providers/entities/RcFileEx';
import {UploadFile} from 'antd/lib/upload/interface';
import moment from 'moment';
import DownloadDocItem from '../providers/entities/DownloadDocItem';

interface IProps {

    currentOperador?: OperadorData;
    operadorFormVisible?: boolean;
    operadorFormKey?: number;
    operadorFormTitle?: string;
    acceptedFileTypes: string;
    onCloseOperadorForm: () => void;
    onSubmit: (form: FormInstance) => void;
    onFileUpload: (file: RcFileEx, groupId: string, form: FormInstance) => boolean;
    onFileRemove: (file: UploadFile) => void;
    onDownloadDocFile?: (info: any) => void;
    downloadDocList: DownloadDocItem[];

}

const OperadorForm = (props: IProps & IStateContainer) => {

    const {t} = useTranslation(['usuariosOperadoresList']);
    const [form] = Form.useForm();
    const {Option} = Select;

    useEffect(() => form.resetFields(), [form, props.operadorFormKey]);
    const [dniLenght, setDniLength] = useState<number>(9);

    const dniChange = (val: any) =>{
        if(val.key === 4) {
            setDniLength(20);
        }
        else {
            setDniLength(9);
            let x = form.getFieldValue('codeIdentificacion');
            x = typeof x === 'undefined'? undefined : x.toString().substring(0,9);
            form.setFieldsValue({ codeIdentificacion: x });
        }
    }

    let initialValues: any = {
        ...props.currentOperador,
        typeIdentificacion: props.currentOperador ? {value: props.currentOperador.typeIdentificacion} : {value: 1},
        typeSexo: props.currentOperador ?  {value: props.currentOperador?.typeSexo} : {value: 1},
        dateNacimiento: props.currentOperador ?  moment(props.currentOperador?.dateNacimiento) : undefined,

    };

    const nifPrefixSelector = (
        <Form.Item name='typeIdentificacion' noStyle>
            <Select labelInValue style={{ width: 120 }}
                    onChange={dniChange}
                    disabled={typeof props.currentOperador !== 'undefined'}
                    filterOption={(inputValue, option) =>
                        option!.toString().toLowerCase().includes(inputValue.toLowerCase())}>
                <Option key={1} value={1}>DNI</Option>
                <Option key={2} value={2}>NIE</Option>
                <Option key={4} value={4}>Pasaporte</Option>
            </Select>
        </Form.Item>
    );

    const sexoOficial = (
        <Form.Item label={t('providerList:sex')} name='typeSexo' rules={[{ required: true }]}>
            <Select labelInValue
                    filterOption={(inputValue, option) =>
                        option!.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())} >
                <Option key={1} value={1} >Hombre</Option>
                <Option key={2} value={2} >Mujer</Option>
                <Option key={0} value={0} >Otro</Option>
            </Select>
        </Form.Item>
    );

    const FileDocComp = (itemLabel: string, itemName: string, groupId: string, opt?: boolean) => {
        if (typeof props.currentOperador === 'undefined') {
            return (
                <Form.Item label={t(itemLabel)} name={itemName}
                           rules={[{ required: !opt }]}
                >
                    <Upload
                        multiple={false}
                        accept={props.acceptedFileTypes}
                        beforeUpload={(file) => props.onFileUpload(file, groupId, form)} onRemove={(file) => props.onFileRemove(file)}>
                        <Button
                            icon={<UploadOutlined />}>{t('providerList:seleccionaArchivos')}</Button>
                    </Upload>
                </Form.Item>
            )
        } else {
            return (
                <Form.Item label={t(itemLabel)} name={itemName}
                           rules={[{ required: !opt }]}
                           initialValue={props.downloadDocList ? props.downloadDocList.filter(e => e.groupId === groupId) : undefined}
                >
                    <Upload
                        multiple={false}
                        accept={props.acceptedFileTypes}
                        fileList={props.downloadDocList?.filter(e => e.groupId === groupId)}
                        onDownload={props.onDownloadDocFile}
                        showUploadList={{
                            showDownloadIcon: true,
                            showRemoveIcon: true,

                        }}
                        beforeUpload={(file) => props.onFileUpload(file, groupId, form)} onRemove={(file) => props.onFileRemove(file)}>
                        <Button
                            icon={<UploadOutlined />}>{t('providerList:seleccionaArchivos')}</Button>
                    </Upload>

                </Form.Item>
            );
        }

    }


    return (

        <Modal
            visible={props.operadorFormVisible}
            title={props.operadorFormTitle}
            onCancel={props.onCloseOperadorForm}
            cancelText={t('buttons:cancel')}
            okText={t('buttons:save')}
            key={props.operadorFormKey}
            onOk={() => props.onSubmit(form)}
            width={650}
            destroyOnClose
        >
            <Form layout='vertical' form={form} size='large' initialValues={initialValues}>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item label={t('providerList:documentoDeIdentidad')} name='codeIdentificacion'
                                   rules={[{required: true}]}>
                            <Input addonBefore={nifPrefixSelector} maxLength={dniLenght}
                                   disabled={typeof props.currentOperador !== 'undefined'}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {FileDocComp('documentoDeIdentidadEsc', 'subirDocIdentidad', '11', false)}
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item label={t('providerList:nombre')} name='nameNombre' rules={[{ required: true }]}>
                            <Input maxLength={120} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('providerList:primerApellido')} name='nameApellido1' rules={[{ required: true }]}>
                            <Input maxLength={120} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item label={t('providerList:segundoApellido')} name='nameApellido2' >
                            <Input maxLength={120} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('providerList:fechaDeNacimiento')} name='dateNacimiento' rules={[{ required: true }]}>
                            <DatePicker format='L'/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        {sexoOficial}
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item label={t('providerList:email')} name='valueEmail' rules={[{
                            required: true,
                            type: 'email', message: t('providerList:incorrectEmail'), validateTrigger: 'props.onSubmit'
                        }]}>
                            <Input maxLength={128} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('providerList:telefono')} name='valueTelefono' rules={[{ required: true }]}>
                            <Input maxLength={128} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item style={{marginBottom: '0px'}}
                            name={'flagSuperadmin'} valuePropName='checked' shouldUpdate>
                            <Checkbox style={{ fontWeight: 'bold' }}
                            >{t('hacerSuperAdm')}</Checkbox>
                        </Form.Item>
                        <div style={{marginLeft: '22px'}}>{t('superAdmExplic')}</div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default OperadorForm;

import React from 'react';

import { Col, Row, Dropdown, Menu, Divider } from 'antd'; 
import { useTranslation } from 'react-i18next';
import { Props as ContainerProps } from './AppHeaderContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import logo from '../images/logo.png';

const AppHeader = (props: {
    logout: () => void;
    changePassword: () => void;
} & ContainerProps) => {

    const { t } = useTranslation(['header']);
    

    const handleMenuClick = (e: any) => {
        if (e.key === '1') {
            props.changePassword();
        }
        if (e.key === '2') {
            props.logout();
        }
    }

    const buttonsMenu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key='1'>
                <FontAwesomeIcon icon={faKey} style={{ color: '#707070' }}/> {t('changePassword')}
            </Menu.Item>
            <Divider orientation='left' style={{margin: 0}}/>
            <Menu.Item key='2' >
                <FontAwesomeIcon icon={faDoorOpen} style={{ color: '#707070' }}/> {t('logout')}
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            <Row align='middle' justify='space-between'>
                <Col>
                    <div className='header--logo'>
                        <div className='header--logo--inner'>
                            <img src={logo} alt=''/>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='header-options--container' style={{lineHeight: '0'}}>
                        <Dropdown overlay={buttonsMenu} trigger={['click']}>
                            <FontAwesomeIcon icon={faUserCircle} style={{ color: 'gray' }} className='header-options--option'/>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default AppHeader;
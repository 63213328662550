import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select, Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'rc-field-form/lib/interface';
import { IState as IStateContainer } from './mailsPruebaContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import Modal from 'antd/lib/modal/Modal';
import { Rest } from '../utils/utils'; // Importa tu función Rest para hacer solicitudes al backend

const MailsPrueba = (props: {
    visible?: boolean;
    onCancel: () => void;
    onSubmit: (form: FormInstance) => void;
    typeNotificacion: number[];
    valueAsunto: string[];
} & IStateContainer) => {
    const [form] = Form.useForm();
    const { t } = useTranslation(['changePasswordForm']);

    const handlePlantillaChange = async (value: number) => {
        // Realiza la solicitud a la API
        const response = await Rest<{ type: string, idPlantilla: number }, { listaRemplazos: string[], valueAsunto: string }>().operation({
            type: 'ContarReemplazosPlantilla',
            idPlantilla: value,
        });
    
        if (response) {
            // Desestructura los datos de la respuesta
            const { listaRemplazos, valueAsunto } = response;
    
            // Verifica si la lista de reemplazos está vacía
            if (listaRemplazos.length === 0) {
                notification.warning({
                    message: "No hay reemplazos para esta plantilla",
                });
                // Limpiar los campos de reemplazos si no hay datos
                form.setFieldsValue({
                    remplazos: [],
                    valueAsunto: valueAsunto,
                });
                return; // Salir de la función
            }
    
            // Crear la estructura para los reemplazos
            const updatedRows = listaRemplazos.map((clave) => ({
                remplazoA: clave,
                remplazoB: '', // Inicia el valor B vacío
            }));
    
            // Establece los valores en el formulario
            form.setFieldsValue({
                remplazos: updatedRows,
                valueAsunto: valueAsunto,
            });
        }
    };

    const handleSave = () => {
        const fieldsValue = form.getFieldsValue();
        const remplazos = fieldsValue.remplazos || [];

        const formattedRemplazos = remplazos.map((item: { remplazoA: any; remplazoB: any; }, index: number) => ({
            [`nombre${index + 1}`]: item.remplazoA,
            [`valor${index + 1}`]: item.remplazoB,
        }));

        console.log('Remplazos formateados:', formattedRemplazos);

        props.onSubmit(form);
    };

    return (
        <Modal
            title={
                <>
                    <FontAwesomeIcon icon={faMailBulk} style={{ color: '#707070', marginRight: '0.5rem' }} />
                    <span>Enviar mails de prueba</span>
                </>
            }
            style={{ top: 40 }}
            onOk={handleSave}
            onCancel={props.onCancel}
            visible={props.visible}
            destroyOnClose
            width={586}
            okText={t('buttons:Enviar')}
            cancelText={t('buttons:cancel')}
        >
            <Form layout='vertical' form={form} size='large' style={{ marginTop: '1rem' }}>
                <Form.Item
                    label={'Selecciona un tipo de plantilla'}
                    name={'tipoPlantilla'}
                >
                    <Select onChange={handlePlantillaChange}>
                        {props.typeNotificacion && props.typeNotificacion.map((numero, index) => (
                            <Select.Option value={numero} key={index}>
                                {props.typeNotificacion[index]} - {props.valueAsunto[index]}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name='emailDesarrollador'
                >
                    <Input />
                </Form.Item>

                <Form.List name="remplazos">
                    {(fields) => (
                        <>
                            {fields.map((field, index) => (
                                <Row gutter={16} key={field.key}>
                                    <Col span={12}>
                                        <Form.Item
                                            {...field}
                                            label='Nombre del reemplazo'
                                            name={[field.name, 'remplazoA']} // Nombre único
                                            fieldKey={[field.fieldKey, 'remplazoA']}
                                        // Se inicializa el valor en función de la lista de claves recibidas
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            {...field}
                                            label="Valor del reemplazo"
                                            name={[field.name, 'remplazoB']} // Nombre único
                                            fieldKey={[field.fieldKey, 'remplazoB']}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>

            </Form>
        </Modal>
    );
};

export default MailsPrueba;

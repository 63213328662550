import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormInstance, Store } from 'rc-field-form/lib/interface';
import { Rest, FormUtils } from '../utils/utils';
import OperadorRegistroData from './entities/operadorRegistroData';
import FormErrorField from '../utils/form/formErrorField';
import ValidateAltaPasswordResult from './entities/validateAltaPasswordResult'
import WelcomeView from './welcomeView';
import WelcomeStepOneView from './welcomeStepOneView'
import WelcomeStepThreeView from './welcomeStepThreeView';
import WelcomeStepCompletedView from './welcomeStepCompletedView';

export interface IState {
    operadorRegistroData?: OperadorRegistroData;
    loaded?: boolean;
    stepHigh?: boolean;
    stepOne?: boolean;
    stepThree?: boolean;
    stepCompleted?: boolean;
    codeProvincia?: string;
    errorFields: FormErrorField[];
}

class WelcomeContainer extends React.Component<WithTranslation & RouteComponentProps<{ token: string }>, IState> {

    public token: string = this.props.match.params.token;

    public state: IState = {
        errorFields: []
    };

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        return (
            this.state.loaded ?
                <>
                    {this.state.stepHigh &&
                        <WelcomeView
                            operadorRegistroData={this.state.operadorRegistroData}
                            continueHigh={this.continueHigh}
                        />
                    }
                    {this.state.stepOne &&
                        <WelcomeStepOneView
                            operadorRegistroData={this.state.operadorRegistroData}
                            continueSecondStep={this.continueThirdStep}
                        />
                    }
                    {this.state.stepThree &&
                        <WelcomeStepThreeView
                            errorFields={this.state.errorFields}
                            onSubmit={this.submit}
                        />
                    }
                    {this.state.stepCompleted &&
                        <WelcomeStepCompletedView
                            access={this.access}
                        />
                    }
                </>
            :
            <></>
        )
    }

    private loadData = () => {
        Rest<{type: string, token: string}, OperadorRegistroData>().operation({
            type: 'SelectOperadorRegistroData',
            token: this.token,
        }).then(response => {
            if(response) {
                this.setState({operadorRegistroData: response, loaded: true, stepHigh: true});
            }
        });
    }
    private continueHigh =  () => {
        this.setState({stepHigh: false, stepOne: true});
    }

    private continueThirdStep =  () => {
        this.setState({stepHigh: false, stepOne: false, stepThree: true});
    }

    private submit = (form: FormInstance) => {
        this.setState({errorFields: []}, () => {
            this.formValidation(form);
        });
    }

    private formValidation = (form: FormInstance) => {
        form.validateFields().then(values => {
            this.internalValidation(values, true);
        }).catch(info => {
            this.internalValidation(info.values, false);
        });
    }

    private internalValidation = (values: Store, formValidationSucceded: boolean) => {
        this.validatePassword(values)?.then(errors => {

            if (formValidationSucceded && (!errors || errors.length === 0)) {
                this.save(values);
            } else {
                this.setState({errorFields: errors});
            }
        });
    }

    private validatePassword = (values: Store) => {

        return new Promise((resolve: (f: FormErrorField[]) => void) => {
            Rest<{type: string, newPassword: string, confirmNewPassword: string}, ValidateAltaPasswordResult>().operation({
                type: 'ValidateAltaPassword',
                newPassword: values.newPassword,
                confirmNewPassword: values.confirmNewPassword})
            .then(result => {
                let errors: FormErrorField[] = [];

                if (result && result.incorrectNewPassword) {
                    const passError = {fieldName: 'newPassword', 
                        errorMessage: this.props.t('incorrectPasswordSecurity')};

                    errors = FormUtils.addError(errors, passError);
                }

                if (result && result.incorrectPasswordConfirmation) {
                    const passError = {fieldName: 'confirmNewPassword', 
                        errorMessage: this.props.t('incorrectPasswordConfirmation')};

                    errors = FormUtils.addError(errors, passError);
                }

                resolve(errors);
            });
        });
    }

    private save = (values: Store) => {
        Rest<{type: string, token: string, newPassword: string, confirmNewPassword: string, typeModulo: number}, void>()
        .operation({
            type: 'CreateOrUpdateUser',
            token: this.token,
            newPassword: values.newPassword,
            confirmNewPassword: values.confirmNewPassword,
            typeModulo: 1,
        }).then(() => {
            this.setState({stepHigh: false, stepOne: false, stepThree: false, stepCompleted: true});
        });
    }

    private access = () => {
        this.props.history.push('/login');
    }
}

export default withTranslation('welcome')(withRouter(WelcomeContainer));
import React, {ClipboardEvent, useEffect, useState} from 'react';

import {Alert, Button, Input, Form, FormInstance} from 'antd';
import {useTranslation} from 'react-i18next';
import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined} from '@ant-design/icons';
import {Store} from 'rc-field-form/lib/interface';
import {IState as IStateContainer} from './LoginContainer';
import logo from '../images/logo.png';
import EnumCodeResultLogin from '../enum/EnumCodeResultLogin';
import ForgotPassword from './ForgotPassword';

const Login = (props: {
    valueUuId?: string;
    otpCancelled?: boolean;
    email?: string;
    codeResult?: number;
    onSubmit: (values: Store) => void;
    onContinue: (values: Store) => void;
    onOTPPaste: (event: ClipboardEvent<HTMLInputElement>, form: FormInstance) => void;
    onValidationFailed: () => void;
    onValidationOtp: () => void;
    tryAgain: () => void;
    onClickForgotPassword: () => void;
    onForgotPassword: (values: Store) => void;
    validationPasswordFailed: () => void;
    onOtpCancel: () => void;
    onNewOtpRequest: () => void;
    closePasswordForm: () => void;
} & IStateContainer) => {

    const {t} = useTranslation(['login']);

    const [formOTP] = Form.useForm();
    const [autocomplete, setAutocomplete] = useState<string>('');

    useEffect(() => {
        if (props.otpCancelled) {
            formOTP.setFieldsValue({password: 'new-password'});
            setAutocomplete('new-password');
        } else {
            setAutocomplete('');
        }

    }, [props.otpCancelled, formOTP]);

    const clearOtpValues = () => {
        formOTP.setFieldsValue({
            ...formOTP.getFieldsValue(),
            txt_Key_1: undefined,
            txt_Key_2: undefined,
            txt_Key_3: undefined,
            txt_Key_4: undefined,
            txt_Key_5: undefined,
            txt_Key_6: undefined,
        });
    }


    const onOtpCancel = () => {
        clearOtpValues();
        props.onOtpCancel();
    }

    const onNewOtpRequest = () => {
        clearOtpValues();
        props.onNewOtpRequest();
    }

    return (
        <div className='login-container'>
            <div className='login-container--mask'/>
            {!props.valueUuId && props.codeResult !== EnumCodeResultLogin.CODE_OTP_CADUCADO ?
                <div className='form'>
                    <div className='header'><img src={logo} alt=''/></div>
                    {!props.forgotPasswordVisible ?
                        <>
                            <Form
                                size='large' onFinish={props.onSubmit} onFinishFailed={props.onValidationFailed}>
                                <Form.Item name='username'
                                           rules={[{required: true}]}>
                                    <Input placeholder={t('username')} prefix={<UserOutlined/>}/>
                                </Form.Item>
                                <Form.Item name='password' rules={[{required: true}]}>
                                    <Input.Password placeholder={t('password')} autoComplete={autocomplete}
                                                    iconRender={visible => (visible ? <EyeTwoTone/> :
                                                        <EyeInvisibleOutlined/>)}
                                                    prefix={<LockOutlined/>}/>
                                </Form.Item>
                                {props.warningMessage &&
                                <Alert message={props.warningMessage} type='warning' showIcon/>}
                                {props.errorMessage &&
                                <Alert message={props.errorMessage} type='error' showIcon/>}
                                <Button type='primary' htmlType='submit'
                                        size='large' block
                                        style={(props.warningMessage || props.errorMessage) ? {marginTop: '1.5rem'} : undefined}>{t('login')}</Button>
                            </Form>
                            <Button type='link' block style={{marginTop: '1.0rem'}}
                                    onClick={() => props.onClickForgotPassword()}>{t('forgotPassword')}</Button>
                        </>
                        :
                        <ForgotPassword
                            mailSent={props.mailSent}
                            warningPasswordMessage={props.warningPasswordMessage}
                            errorPasswordMessage={props.errorPasswordMessage}
                            onForgotPassword={props.onForgotPassword}
                            validationPasswordFailed={props.validationPasswordFailed}
                            closePasswordForm={props.closePasswordForm}/>
                    }
                </div>
                : props.valueUuId && props.codeResult !== EnumCodeResultLogin.CODE_OTP_CADUCADO ?
                    <div className='form otp'>
                        <div className='header'><img src={logo} alt=''/></div>
                        <div>{t('accessCodeLabel')}</div>
                        <div><b>{props.email}</b></div>
                        <div>{t('accessCodeContinueLabel')}</div>

                        <Form form={formOTP} layout='inline' size='large' onFinish={props.onContinue}
                              onFinishFailed={props.onValidationOtp}>
                            <Form.Item name='txt_Key_1' className='login_otp_text' rules={[{required: true}]}>
                                <Input type='password' maxLength={1}
                                       onPaste={(event) => props.onOTPPaste(event, formOTP)}/>
                            </Form.Item>
                            <Form.Item name='txt_Key_2' className='login_otp_text'
                                       rules={[{required: true, message: ''}]}>
                                <Input type='password' maxLength={1}/>
                            </Form.Item>
                            <Form.Item name='txt_Key_3' className='login_otp_text' rules={[{required: true}]}>
                                <Input type='password' maxLength={1}/>
                            </Form.Item>
                            <Form.Item name='txt_Key_4' className='login_otp_text' rules={[{required: true}]}>
                                <Input type='password' maxLength={1}/>
                            </Form.Item>
                            <Form.Item name='txt_Key_5' className='login_otp_text' rules={[{required: true}]}>
                                <Input type='password' maxLength={1}/>
                            </Form.Item>
                            <Form.Item name='txt_Key_6' className='login_otp_text' rules={[{required: true}]}
                                       style={{marginRight: 0}}>
                                <Input type='password' maxLength={1}/>
                            </Form.Item>

                            {props.warningMessage &&
                            <Alert message={props.warningMessage} type='warning' showIcon className='warning_Message'/>}
                            {props.errorMessage &&
                            <Alert message={props.errorMessage} type='error' showIcon className='error_Message'/>}

                            <Button type='primary' htmlType='submit'
                                    size='large' block
                                    style={(props.warningMessage || props.errorMessage) ? {marginTop: '1.5rem'} : undefined}>{t('buttons:continue')}</Button>

                            <Button type='default'
                                    size='large' block
                                    style={{marginTop: '1.5rem'}}
                                    onClick={onOtpCancel}
                            >{t('buttons:cancel')}</Button>
                            <Button type='link'
                                    size='large' block
                                    style={{marginTop: '1.5rem'}}
                                    onClick={onNewOtpRequest}
                            >{t('reenviarCodigo')}</Button>


                        </Form>
                    </div>
                    :
                    <div className='form otp'>
                        <div className='header'><img src={logo} alt=''/></div>
                        {props.errorMessage &&
                        <Alert message={props.errorMessage} type='error' showIcon className='error_Message'/>}
                        <Button onClick={props.tryAgain} style={{marginTop: '1rem'}}>{t('tryAgainButton')}</Button>
                    </div>
            }
        </div>
    );
}

export default Login;
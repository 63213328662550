    const stateColors = ['#DCDCDC', '#A9A9A9', '#FADB15', '#6CC6F1', '#8EAD38', '#FF4D4F', '#FD9432', '#6CC6F1'];
    const stateNames = ['undefined', 'init', 'awaiting', 'validated', 'active', 'inactive', 'cancelled', 'service'];
    const stateStyle = { display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '8rem' };
    enum PROVIDER_STATE_NAMES {
        UNDEFINED,
        INIT,
        AWAITING,
        VALIDATED,
        ACTIVE,
        INACTIVE,
        CANCELLED,
        SERVICE
    }
        
export {stateColors, stateNames, stateStyle, PROVIDER_STATE_NAMES};
import React, { CSSProperties } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl';
import { faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons/faMoneyBillAlt';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';

enum TableIcon {
    filter,
    reload,
    chevronDownRow,
    chevronDown,
    chevronUp,
    chevronLeft,
    edit,
    trash,
    noAvatar,
    more,
    plus,
    download,
    list,
    sync,
    money,
    team
};

enum DotColor {
    green,
    red,
    gray,
    blue
}

const PRIMARY_BUTTON_ICON_STYLE: CSSProperties = {marginRight: '0.5rem', color: '#1890ff'};
const PRIMARY: CSSProperties = {color: '#1890ff'};

export default {

    TableIcon, DotColor, PRIMARY_BUTTON_ICON_STYLE, PRIMARY,

    getTableIcon(icon: TableIcon, fontSize?: string) {
        switch (icon) {
            case TableIcon.filter:
                return <FontAwesomeIcon icon={faFilter} />;
            case TableIcon.reload:
                return <FontAwesomeIcon icon={faSync} />;
            case TableIcon.chevronDownRow:
                return <FontAwesomeIcon icon={faChevronDown} style={{marginLeft: '0.5rem', color: '#1890ff'}}/>;
            case TableIcon.chevronDown:
                return <FontAwesomeIcon icon={faChevronDown} />;
            case TableIcon.chevronUp:
                return <FontAwesomeIcon icon={faChevronUp} />;
            case TableIcon.chevronLeft:
                return <FontAwesomeIcon icon={faChevronLeft} />;
            case TableIcon.edit:
                return <FontAwesomeIcon icon={faPen} />;
            case TableIcon.trash:
                return <FontAwesomeIcon icon={faTrashAlt} />;
            case TableIcon.noAvatar:
                return <FontAwesomeIcon icon={faUserCircle} style={{color: '#A2A2A2', fontSize: fontSize ? fontSize : '1.75rem'}} />
            case TableIcon.more:
                return <FontAwesomeIcon icon={faEllipsisV} />
            case TableIcon.plus:
                return <FontAwesomeIcon icon={faPlus} />  
            case TableIcon.download:
                return <FontAwesomeIcon icon={faDownload} />
            case TableIcon.list:
                return <FontAwesomeIcon icon={faListUl} style={{color: '#FA7D15'}} />
            case TableIcon.money:
                return <FontAwesomeIcon icon={faMoneyBillAlt} style={{color: '#40a9ff'}} />
            case TableIcon.team:
                return <FontAwesomeIcon icon={faUserFriends} />
            case TableIcon.sync:
                return <FontAwesomeIcon icon={faSync} />
            }
    },

    getStatusTypeIcon(text: string, color: DotColor, style?: CSSProperties) {

        const STYLE = {display:'flex', alignItems:'center', justifyContent:'flex-start', width: '8rem'};

        switch (color) {
            case DotColor.green:
                return <div style={style ? style : STYLE}>
                    <FontAwesomeIcon icon={faCircle} style={{color: '#8EAD38', marginRight: '0.5rem'}}/> {text} </div>;
            case DotColor.red:
                return <div style={style ? style : STYLE}>
                    <FontAwesomeIcon icon={faCircle} style={{color: '#CD6663', marginRight: '0.5rem'}}/> {text} </div>;
            case DotColor.gray:
                return <div style={style ? style : STYLE}>
                    <FontAwesomeIcon icon={faCircle} style={{color: '#A5A5A5', marginRight: '0.5rem'}}/> {text} </div>;
            case DotColor.blue:
                return <div style={style ? style : STYLE}>
                    <FontAwesomeIcon icon={faCircle} style={{color: '#4F94CD', marginRight: '0.5rem'}}/> {text} </div>;
        }
    }
}
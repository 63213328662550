import React from 'react';

import {Menu} from 'antd';
import MenuConfig from './menuConfig';
import {MenuInfo} from 'rc-menu/lib/interface';
import {useTranslation} from 'react-i18next';
import {faSeedling, faTools, faUserMd, faUserTie} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SubMenu from 'antd/es/menu/SubMenu';

const AppMenu = (props: {
    onMenuOptionSelect: (param: MenuInfo) => void;
    isOperadorMenuVisible?: boolean;
    openMailsPrueba?: () => void;
    openCreadorPlantillasMail?: () => void;
}) => {

    const {t} = useTranslation(['menu']);

    const listadoDePrestadoresOption = MenuConfig.getConfigById('prestadores');
    const listadoDeCuentas = MenuConfig.getConfigById('cuentas');
    const listadoDeUsuariosOperadores = MenuConfig.getConfigById('usuariosOperadores');

    return (
        <>
            <Menu onClick={props.onMenuOptionSelect} mode='inline'>
                <SubMenu key={listadoDePrestadoresOption.id} style={{fontSize: '24px'}} icon={<FontAwesomeIcon
                    icon={faUserMd}/>}>
                    <Menu.Item
                        key={listadoDePrestadoresOption.id}>{t(listadoDePrestadoresOption.menuCaption)}</Menu.Item>
                    <Menu.Item key={listadoDeCuentas.id}>{t(listadoDeCuentas.menuCaption)}</Menu.Item>
                </SubMenu>
                {props.isOperadorMenuVisible &&
                    <Menu.Item key={listadoDeUsuariosOperadores.id} icon={<FontAwesomeIcon
                        icon={faUserTie}/>}>{t(listadoDeUsuariosOperadores.menuCaption)}</Menu.Item>
                
                }
            </Menu>
            <Menu>
                <SubMenu style={{fontSize: '24px'}} icon={<FontAwesomeIcon icon={faTools} />}>
                    <Menu.Item onClick={() => props.openMailsPrueba!()}>Enviar mails de prueba</Menu.Item>
                    <Menu.Item onClick={() => props.openCreadorPlantillasMail!()}>Creador de plantillas</Menu.Item>
                </SubMenu>
            </Menu>
            
        </>
    );
};

export default AppMenu;
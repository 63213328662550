import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/lib/interface';
import { notification } from 'antd';
import View from './creadorPlantillasMail';
import { Rest } from '../utils/utils';
import RcFileEx from '../providers/entities/RcFileEx';
import DownloadDocItem from '../providers/entities/DownloadDocItem';

interface IProps {
    visible?: boolean;
    onClose?: () => void;
}

export interface IState {
    typeNotificacion?: number[];
    valueAsunto?: string[];
    visibleLoaded?: boolean;
    downloadDocList: DownloadDocItem[];
    fileList: RcFileEx[];
}

class MailsPruebaContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        visibleLoaded: false,
        downloadDocList: [],
        fileList: [],
    };

    readonly acceptedFileTypes = '.zip';

    public render() {
        return (
            <>
                <View
                    visible={this.state.visibleLoaded && this.props.visible}
                    onCancel={() => this.props.onClose!()}
                    onSubmit={this.submit}
                    typeNotificacion={this.state.typeNotificacion || []}
                    valueAsunto={this.state.valueAsunto || []}
                    onFileUpload={this.onFileUpload}
                    onFileRemove={this.onFileRemove}
                    acceptedFileTypes={this.acceptedFileTypes} 
                    downloadDocList={this.state.downloadDocList} 
                    fileList={this.state.fileList}                
                />
            </>
        );
    }

    private submit = (form: FormInstance) => {
        form.validateFields()
            .then((values) => {
                let formData = new FormData();
                formData.append('valueAsunto', values.valueAsunto);  // El string
                formData.append('tipoNotificacion', values.tipoNotificacion);  // El integer

                // Añadir el archivo ZIP directamente sin codificarlo
                if (this.state.fileList.length > 0) {
                    formData.append('file', this.state.fileList[0]);  // El archivo .zip
                } else {
                    notification.error({
                        message: this.props.t('error'),
                        description: this.props.t('noFileUploaded'), // Mensaje si no hay archivo cargado
                    });
                    return; // Evita enviar el formulario si no hay archivo
                }

                console.log(formData);  // Para verificar que los datos estén correctos
                this.subirPlantillaNueva(formData);
            })
            .catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo);
            });
    }

    public componentDidMount() {
        this.setState({ visibleLoaded: true });
    }

    private onFileUpload = (file: RcFileEx, groupId: string) => {
        const fileList = [...this.state.fileList]; 
        file.groupId = groupId;
        file.fileId = Date.now(); // Usa timestamp para fileId
        fileList.push(file);

        // Actualiza el estado con el nuevo archivo
        this.setState({ fileList });
        return false; // Para evitar que se suba automáticamente a Ant Design
    }

    private onFileRemove = (file: any) => {
        this.setState({ fileList: this.state.fileList.filter(f => f.fileId !== file.fileId) });
    }

    private subirPlantillaNueva = (formData: FormData) => {
        console.log(formData);
        Rest<void, any>()
            .fetchURL('/fileUpload?typeUpload=uploadNewPlantillaEmail', { method: 'POST', body: formData })
            .then(() => {
                notification.success({
                    message: this.props.t('success'),
                    description: this.props.t('uploadSuccess'),
                });

                
                if (this.props.onClose) {
                    this.props.onClose();
                }
            })
            .catch((error) => {
                // Manejo de error en caso de que la subida falle
                notification.error({
                    message: this.props.t('error'),
                    description: this.props.t('uploadFailed')
                });
            });
    }
}

export default withTranslation('mailsPrueba')(withRouter(MailsPruebaContainer));

import React from 'react';
import { Col, Form, Input, Row, Button, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { FormInstance } from 'rc-field-form/lib/interface';
import Modal from 'antd/lib/modal/Modal';
import { UploadFile } from 'antd/lib/upload/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import RcFileEx from '../providers/entities/RcFileEx';
import { IState as IStateContainer } from './creadorPlantillasMailContainer';

const CreadorPLantillasMail = (props: {
    visible?: boolean;
    onCancel: () => void;
    onSubmit: (form: FormInstance) => void;
    valueAsunto: string[];
    acceptedFileTypes: string;
    onFileUpload: (file: RcFileEx, groupId: string, form: FormInstance) => boolean;
    onFileRemove: (file: UploadFile, groupId: string) => void;
} & IStateContainer) => {
    const [form] = Form.useForm();
    const { t } = useTranslation(['changePasswordForm']);

    // Componente de subida de archivos
    const FileDocComp = (itemLabel: string, itemName: string, groupId: string) => {
        return (
            <Form.Item label={t(itemLabel)} name={itemName} style={{ width: '100%' }}>
                <Upload
                    multiple={false}
                    style={{ display: "flex" }}
                    accept={props.acceptedFileTypes}
                    beforeUpload={(file) => {
                        // Llama a la función de carga de archivo y espera que se complete antes de enviar el formulario
                        const isUploaded = props.onFileUpload(file, groupId, form);
                        if (isUploaded) {
                            // Llama a la función de envío aquí si el archivo se carga correctamente
                            handleSubmit();
                        }
                        return false; // Para evitar que se suba automáticamente a Ant Design
                    }}
                    onRemove={(file) => props.onFileRemove(file, groupId)}
                >
                    <Button style={{ width: "100%" }} icon={<UploadOutlined />}>
                        {t('seleccionaArchivos')}
                    </Button>
                </Upload>
            </Form.Item>
        );
    };

    // Función que maneja el envío del formulario
    const handleSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                // Llama a la función de submit pasada por props
                props.onSubmit(form);
            })
            .catch((errorInfo) => {
                console.log('Validation Failed:', errorInfo);
            });
    };

    return (
        <Modal
            title={
                <>
                    <FontAwesomeIcon icon={faMailBulk} style={{ color: '#707070', marginRight: '0.5rem' }} />
                    <span>Creador de plantillas de Emails</span>
                </>
            }
            style={{ top: 40 }}
            onCancel={props.onCancel}
            visible={props.visible}
            destroyOnClose
            width={586}
            okText={t('buttons:Enviar')}
            cancelText={t('buttons:cancel')}
            onOk={handleSubmit} // Enviar el formulario cuando se presiona "Enviar"
        >
            <Form layout='vertical' form={form} size='large' style={{ marginTop: '1rem' }}>
                <Form.Item
                    label="Asunto"
                    name='valueAsunto'
                    rules={[{ required: true, message: 'El asunto es obligatorio' }]} // Campo obligatorio
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Tipo Plantilla"
                    name='tipoNotificacion'
                    rules={[{ required: true, message: 'El tipo de plantilla es obligatorio' }]} // Campo obligatorio
                >
                    <Input />
                </Form.Item>

                <Row gutter={12} style={{ marginTop: '20px' }}>
                    <Col span={8} className='subirPlantillaCorreo'>
                        {FileDocComp('Seleccione archivos (.zip)', 'seleccionaArchivos', '1')}
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CreadorPLantillasMail;

import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './Dialogs.scss';
import {IconDefinition} from '@fortawesome/free-regular-svg-icons';

interface IProps {
    title?: string;
    closeable?: boolean;
    visible?: boolean;
    content: () => JSX.Element;
    confirm(): void;
    width?: number;
    dialogIcon?: {iconName: IconDefinition, iconColor: string};
    buttonText?: string;
}

const ConfirmationDialog = (props: IProps) => {
    const { t } = useTranslation(['buttons']);
    const defaultWidth = 320;

    return (
        <>
        <Modal
            style={{ top: 40 }}
            title={props.title}
            visible={props.visible}
            footer={null}
            width={typeof props.width === 'undefined'? defaultWidth: props.width}
            closable={typeof props.closeable === 'undefined' ? false : props.closeable }
            destroyOnClose
            onCancel={props.confirm}
        >
            <div className='dialog-confirmation'>
                <div className='dialog-confirmation-logo'><FontAwesomeIcon icon={typeof props.dialogIcon === 'undefined'? faCheckCircle : props.dialogIcon.iconName} style={{ color: typeof props.dialogIcon === 'undefined'? 'yellowGreen' : props.dialogIcon.iconColor, fontSize: '3rem' }} /></div>
                <div className='dialog-confirmation-text'>
                    {props.content()}
                </div>
                <div className='dialog-confirmation-button'><Button type='primary' onClick={props.confirm}>{typeof props.buttonText === 'undefined'? t('continue') : t(props.buttonText)}</Button></div>
            </div>
        </Modal>
        </>
    );

}
export default ConfirmationDialog;
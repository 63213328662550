import {
    Checkbox,
    Col,
    Form,
    FormInstance,
    Input,
    Modal,
    Row,
} from 'antd';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IPersonalizedEmailData} from './entities/Cuenta';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import FormErrorField from '../utils/form/formErrorField';


interface IProps {
    visible: boolean;
    formTitle?: string;
    onCancel: () => void;
    onSubmit: (form: FormInstance) => void;
    idCuenta: number;
    nameCuenta: string;
    personalizedEmailData?: IPersonalizedEmailData;
}

const PersonalizedEmailForm = (
    props: IProps,
) => {

    const {t} = useTranslation(['providerAccountListContainer']);
    const [form] = Form.useForm();
    const [, setErrorFields] = useState<FormErrorField[]>([]);
    const [usePersonalizedEmail, setUsePersonalizedEmail] = useState(false);

    useEffect(() => {
        if (props.visible) {
            setErrorFields([]);
            form.resetFields();
            form.setFieldsValue({idCuenta: props.idCuenta, nameCuenta: props.nameCuenta,
            });

            setUsePersonalizedEmail(!!props.personalizedEmailData);

            form.setFieldsValue({usePersonalizedEmail: props.personalizedEmailData? 1 : undefined});

            if (props.personalizedEmailData) { //form data
                form.setFieldsValue({...props.personalizedEmailData});
            }

        }
    }, [props.visible]);


    const onSubmit = () => {
        form.validateFields().then(() => {
            props.onSubmit(form);
        }).catch(() => {
            if (!props.personalizedEmailData) {
                props.onSubmit(form);
            }
        });
    };

    const onChangeUsePersonalizedEmail = (e: CheckboxChangeEvent) => {
        setUsePersonalizedEmail(e.target.checked);
        form.setFieldsValue({usePersonalizedEmail: e.target.checked ? 1 : undefined});
    };


    return (
        <Modal
            title={props.nameCuenta ? t('personalizedEmailFor', {nameCuenta: props.nameCuenta}) : t('personalizedEmailFor')}
            visible={props.visible || false}
            style={{top: 40}}
            destroyOnClose
            width={1200}
            cancelText={t('buttons:cancel')}
            okText={t('buttons:save')}
            onCancel={props.onCancel}
            onOk={onSubmit}
        >

            <Form layout='vertical' form={form} size='large'>
                <Row gutter={8} style={{display: 'flex', alignItems: 'center'}}>
                    <Col span={24}>
                        <Form.Item name='anadirLocalizacionPorDefecto'>
                            <Checkbox
                                checked={usePersonalizedEmail}
                                onChange={onChangeUsePersonalizedEmail}>{t('anadirPersonalizedEmail')}</Checkbox>
                        </Form.Item>

                    </Col>
                </Row>
                <div style={{display: usePersonalizedEmail ? '' : 'none'}}>
                    <Row gutter={8} style={{display: 'flex', alignItems: 'center'}}>
                        <Col span={24}>
                            <Form.Item label={t('valueAsunto')} name='valueAsunto' rules={[{required: true}]}>
                                <Input maxLength={128}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col span={24}>
                            <Form.Item
                                label={t('valuecuerpo')} name='valuecuerpo' rules={[{required: true}]}>
                                <TextArea rows={15}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name='idCuenta' hidden/>
                    <Form.Item name='idPlantilla' hidden/>
                    <Form.Item name='usePersonalizedEmail' hidden/>
                </div>
            </Form>

        </Modal>

    );

};

export default PersonalizedEmailForm;
import React from 'react';

import {useTranslation} from 'react-i18next';
import {Button, Row, Col, Input, Form, Select, DatePicker} from 'antd';
import LOGOERECETA_BACKOFFICE from '../images/logo.png';
import OperadorRegistroData from './entities/operadorRegistroData';
import cx from 'classnames';
import moment from 'moment';

interface IProps {
    operadorRegistroData?: OperadorRegistroData;
    continueSecondStep: () => void;
}

const WelcomeStepOneView = (props: IProps) => {
    const {t} = useTranslation(['welcome']);
    const {Option} = Select;
    const initialValues = {
        ...props.operadorRegistroData,
        typeIdentificacion: props.operadorRegistroData ? {value: props.operadorRegistroData.typeIdentificacion} : {value: 1},
        typeSexo: props.operadorRegistroData ? {value: props.operadorRegistroData?.typeSexo} : {value: 1},
        dateNacimiento: props.operadorRegistroData?.dateNacimiento && moment(props.operadorRegistroData.dateNacimiento),
    }

    const sexoOficial = (
        <Form.Item label={t('providerList:sex')} name='typeSexo'>
            <Select labelInValue disabled={true}
                    filterOption={(inputValue, option) =>
                        option!.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())}>
                <Option key={1} value={1}>Hombre</Option>
                <Option key={2} value={2}>Mujer</Option>
                <Option key={0} value={0}>Otro</Option>
            </Select>
        </Form.Item>
    );

    const nifPrefixSelector = (
        <Form.Item name='typeIdentificacion' noStyle>
            <Select labelInValue style={{ width: 120 }}
                    disabled={true}
                    filterOption={(inputValue, option) =>
                        option!.toString().toLowerCase().includes(inputValue.toLowerCase())}>
                <Option key={1} value={1}>DNI</Option>
                <Option key={2} value={2}>NIE</Option>
                <Option key={4} value={4}>Pasaporte</Option>
            </Select>
        </Form.Item>
    );

    return (
        <div className='enlace_token_container'>
            <div className='enlace_token_card_container'>
                <div className='enlace_token_card' style={{width: '720px'}}>
                    <div className='enlace_token_logo'><img src={LOGOERECETA_BACKOFFICE} alt={''}/></div>
                    <div className='enlace_token_title'>{t('stepOneTitle')}</div>
                    <div className={cx('enlace_token_operador', {'welcome': true})}>
                        <p dangerouslySetInnerHTML={{
                            __html:
                                t('stepOneLabel')
                        }}/>
                    </div>
                    <Form layout='vertical' size='large' className='form-alta-prescriptor'
                          initialValues={initialValues}>
                        <Row gutter={12}>
                            <Col span={8}>
                                <Form.Item label={t('codeIdentificationLabel')} name='codeIdentificacion'>
                                    <Input addonBefore={nifPrefixSelector}
                                           defaultValue={props.operadorRegistroData && props.operadorRegistroData.codeIdentificacion}
                                           disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t('birthDateLabel')} name='dateNacimiento'>
                                    <DatePicker format='L' style={{width: '100%'}} disabled />
                                    {/*<Input*/}
                                    {/*    defaultValue={props.operadorRegistroData && props.operadorRegistroData.dateNacimiento*/}
                                    {/*    && Functions.dateToString(props.operadorRegistroData.dateNacimiento)} disabled/>*/}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                {sexoOficial}
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={8}>
                                <Form.Item label={t('nameLabel')} name='nameNombre'>
                                    <Input
                                        defaultValue={props.operadorRegistroData && props.operadorRegistroData.nameNombre}
                                        disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t('surnameLabel')} name='nameApellido1'>
                                    <Input
                                        defaultValue={props.operadorRegistroData && props.operadorRegistroData.nameApellido1}
                                        disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t('secondSurnameLabel')} name='nameApellido2'>
                                    <Input
                                        defaultValue={props.operadorRegistroData && props.operadorRegistroData.nameApellido2}
                                        disabled/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item label={t('providerList:email')} name='valueEmail'>
                                    <Input maxLength={128} disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={t('providerList:telefono')} name='valueTelefono'>
                                    <Input maxLength={128} disabled/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div className='alta-buttons'>
                        <Button type='primary' onClick={() => props.continueSecondStep()} size='middle'>
                            {t('buttons:continue')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeStepOneView;
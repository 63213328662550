    const stateColors = ['#DCDCDC', '#A9A9A9', '#8EAD38', '#FF4D4F'];
    const stateNames = ['undefined', 'Activación pendiente', 'Activo', 'Inactivo'];
    const stateStyle = { display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '8rem' };

    enum OPERADOR_STATE_NAMES {
        UNDEFINED,
        AWAITING,
        ACTIVE,
        INACTIVE,
    }

        
export {stateColors, stateNames, stateStyle, OPERADOR_STATE_NAMES};
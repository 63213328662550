import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import OperadorForm from './OperadorForm';
import OperadorData from './enteties/operadorData';
import {FormInstance} from 'antd';
import RcFileEx from '../providers/entities/RcFileEx';
import DownloadDocItem from '../providers/entities/DownloadDocItem';
import {URL} from '../utils/rest';
import {Rest, validateDNI} from '../utils/utils';
import Persona from '../providers/entities/Persona';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

interface IProps {
    currentOperador?: OperadorData;
    operadorFormVisible?: boolean;
    operadorFormKey?: number;
    operadorFormTitle?: string;
    onCloseOperadorForm: () => void;
    onSaveOperadorFormData: () => void;

    downloadDocList: DownloadDocItem[];
    updateDownloadDocList: (docList: DownloadDocItem[]) => void;
    removeDocFromList: (fileId: number) => void;
}

export interface IState {
    fileList: RcFileEx[];
    base64File?: string;
    confirmDialogVisible?: boolean;
    operadorEmail?: string;
}

class OperadorFormContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        fileList: [],
    }
    readonly fsLimit = 5 * 1024 * 1024;
    readonly PASAPORTE_KEY = 4;
    readonly acceptedFileTypes = 'image/jpeg,image/jpg,image/png,image/bmp,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    readonly acceptedFileTypesArray: string[] = this.acceptedFileTypes.split(',');

    public render() {
        return (
            <>
                <OperadorForm
                    currentOperador={this.props.currentOperador}
                    operadorFormVisible={this.props.operadorFormVisible}
                    operadorFormKey={this.props.operadorFormKey}
                    operadorFormTitle={this.props.operadorFormTitle}
                    onCloseOperadorForm={this.props.onCloseOperadorForm}
                    onSubmit={this.onSubmit}
                    onFileUpload={this.onFileUpload}
                    onFileRemove={this.onFileRemove}
                    onDownloadDocFile={this.onDownloadDocFile}
                    fileList={this.state.fileList}
                    downloadDocList={this.props.downloadDocList}
                    acceptedFileTypes={this.acceptedFileTypes}
                />
                <ConfirmationDialog
                    width={400}
                    visible={this.state.confirmDialogVisible}
                    confirm={this.onCloseConfirmationDialog}
                    content={this.operadorConfirmation}
                    closeable={true}
                    buttonText='buttons:continue'
                />
            </>
        );
    }

    private onFileUpload = (file: RcFileEx, groupId: string) => {
        const fileList = [...this.state.fileList];
        file.groupId = groupId;
        // "-1" for new files to upload
        const fid = (-1) * (new Date().getTime());
        file.fileId = fid;
        fileList.push(file);
        getBase64(file, fileUrl => {
                if (this.props.currentOperador) {
                    const dl = this.props.downloadDocList;
                    const fd: DownloadDocItem = {
                        uid: fid.toString(), size: file.size, name: file.name, type: 'new',
                        status: 'success', fileId: fid, groupId: file.groupId
                    };
                    dl.push(fd);
                    this.props.updateDownloadDocList(dl);
                }
                this.setState({base64File: fileUrl, fileList,});
            }
        );
        return false;
    }

    private onFileRemove = (file: any) => {
        this.setState({base64File: undefined, fileList: this.state.fileList.filter(f => f.fileId !== file.fileId)});

        if (this.props.currentOperador) {
            const dl = this.props.downloadDocList;
            const fd: number = dl.findIndex(e => e.fileId === file.fileId);
            if (typeof fd !== 'undefined') {
                dl.splice(fd, 1);
            }
            this.props.updateDownloadDocList(dl);
        }
    }

    private onDownloadDocFile = (info: any) => {
        const file_path: string = URL + '/file?docFileRequest&idFile=' + info.fileId + '&name=' + info.name;
        const a = document.createElement('a');
        a.href = file_path;
        a.click();
        document.body.removeChild(a);
    }

    private onSubmit = (form: FormInstance) => {
        this.setState({operadorEmail: form.getFieldValue('valueEmail')});
        this.formValidationAndSave(form);
    }

    private formValidationAndSave = async (form: FormInstance) => {
        let hasErrors = false;
        form.setFields([]);
        //file/fileList inconsistent state
        const subirFieldNames: string[] = [];
        for (const [key, value] of Object.entries(form.getFieldsValue())) {
            let fieldName = key;
            let fieldValue: any = value;
            if (fieldName.startsWith('subir')) {
                subirFieldNames.push(fieldName);
                if (typeof fieldValue !== 'undefined' && typeof fieldValue['fileList'] !== 'undefined' && fieldValue['fileList'].length === 0) {
                    form.setFieldsValue({[fieldName]: undefined});
                }
            }
        }

        await form.validateFields().then(() => {
            hasErrors = false;
        }).catch(errors => {
            hasErrors = true;
            let errFields: string[] = errors.errorFields.filter((e: any) => e.name[0].startsWith('subir'))
                .map((f: any) => f.name[0]);

            errFields.forEach(e => {
                form.setFields([
                    {name: e, errors: [this.props.t('providerList:attachFile')]},
                ]);
            })
            this.checkLocalFieldErrors(form);
        }).finally(() => {
            if (!hasErrors) {
                hasErrors = hasErrors || this.checkLocalFieldErrors(form);
            }
        });

        if (hasErrors) {
            return;
        }

        if (!this.props.currentOperador) {
            //Local check passed, ok
            //validate data from server (NIE)  for new operador
            await Rest<{ type: string, typeIdentificacion: number, codeIdentificacion: string }, Persona>()
                .operation({
                    type: 'SelectOperadorByCodeIdentificacion',
                    typeIdentificacion: form.getFieldValue('typeIdentificacion').value!,
                    codeIdentificacion: form.getFieldValue('codeIdentificacion')
                }).then(
                    response => {
                        if (response != null) {
                            form.setFields([
                                {
                                    name: 'codeIdentificacion',
                                    errors: [this.props.t('usuariosOperadoresList:dniExiste')]
                                },
                            ]);

                            hasErrors = true;
                        }
                    });
        }

        //Prepare
        let formData = new FormData();
        let operadorData: { [k: string]: any } = {};

        for (const [key, value] of Object.entries(form.getFieldsValue())) {
            let v: any = value;

            if (v && (typeof v === 'object' && v['file'])) {  // Do not add files, later will be done whith added/deleted lists
            } else {
                if (v && (typeof v === 'object' && (typeof v['value'] !== 'undefined'))) {
                    operadorData[key] = v.value;  // For <Select> where the pair of key/value is used
                } else {
                    operadorData[key] = v;
                }
            }
        }

        operadorData['idOperador'] = typeof this.props.currentOperador === 'undefined' ? undefined : this.props.currentOperador.idOperador;
        operadorData['flagSuperadmin'] = (form.getFieldValue('flagSuperadmin') === true || form.getFieldValue('flagSuperadmin') === 1) ? 1 : 0;
        formData.append('operadorData', JSON.stringify(operadorData));
        //Sending updated file list in case
        if (typeof this.props.currentOperador !== 'undefined') {
            if ((typeof this.props.downloadDocList !== 'undefined') && this.props.downloadDocList.length > 0) {
                formData.append('operadorStateFileList', JSON.stringify(this.props.downloadDocList));
            }
        }


        this.state.fileList.forEach(e => {
            const attachedFieldErrors: string[] = [];
            const fldIdx = parseInt(e.groupId!, 10) - 11;
            if ((e.fileId! < 0 && e.size) > this.fsLimit) {
                attachedFieldErrors.push(this.props.t('providerList:fileIsTooLarge'));
            }

            if (e.fileId! < 0 && this.acceptedFileTypesArray.indexOf(e.type) === -1) {
                attachedFieldErrors.push(this.props.t('providerList:incorrectFileType'));
            }

            if (attachedFieldErrors.length > 0) {
                form.setFields([
                    {name: subirFieldNames[fldIdx], errors: attachedFieldErrors},
                ]);

                hasErrors = true;
            }

            formData.append('file', e, e.name + '|' + e.groupId);
        });
        // Save if all is ok
        if (!hasErrors) {
            this.processOperadorData(formData);
        }
    }

    private processOperadorData = (formData: FormData) => {
        Rest<void, any>()
            .fetchURL('/fileUpload?typeUpload=uploadOperadorForm', {method: 'POST', body: formData})
            .then(() => {
                this.props.onSaveOperadorFormData();
                this.setState({
                    fileList: [],
                    confirmDialogVisible: typeof this.props.currentOperador?.idOperador === 'undefined'
                })
            });
    }
    private checkLocalFieldErrors = (form: FormInstance): boolean => {
        const fv: any = form.getFieldsValue();
        let hasErrors: boolean = false;

        if (!fv.codeIdentificacion || fv.codeIdentificacion.length < 9) {
            form.setFields([
                {name: 'codeIdentificacion', errors: [''],},
            ]);

            hasErrors = true;
        }
        //Pasaporte/DNI/NIE value check
        if(fv.typeIdentificacion.key === this.PASAPORTE_KEY) {
            if(fv.codeIdentificacion.toString().length < 4) {
                form.setFields([
                    {name: 'codeIdentificacion', errors: [this.props.t('providerList:incorrectNif')],},
                ]);
                hasErrors = true;
            }
        } else if (!validateDNI(fv.codeIdentificacion)) { //DNI/NIE value check
            form.setFields([
                {name: 'codeIdentificacion', errors: [this.props.t('providerList:incorrectNif')],},
            ]);

            hasErrors = true;
        }

        return hasErrors;
    }

    private onCloseConfirmationDialog = () => {
        this.setState({confirmDialogVisible: false});
    }

    private operadorConfirmation = () => {
        return (
            <div style={{textAlign: 'center'}}>
                Se ha enviado un correo electrónico a <b>{this.state.operadorEmail}</b> con instrucciones para que
                proceda a la generación de sus credenciales de acceso
            </div>
        );
    }
}

const getBase64 = (img: Blob, callback: (result: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
}

export default withTranslation('operadoresListContenedor')(withRouter(OperadorFormContainer));

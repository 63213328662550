import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormInstance, Store } from 'rc-field-form/lib/interface';
import { notification } from 'antd';
import { FormUtils, Rest } from '../utils/utils';
import View from './ChangePasswordForm';
import FormErrorField from '../utils/form/formErrorField';
import ValidateChangePasswordResult from './entities/validateChangePasswordResult';

interface IProps {
    visible?: boolean;
    requiredChangePass?: boolean;
    onClose: () => void;
}

export interface IState {
    errorFields: FormErrorField[];
}

class ChangePasswordFormContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public render() {
        return (
            <View {...this.state}
                visible={this.props.visible}
                requiredChangePass={this.props.requiredChangePass}
                onCancel={() => this.props.onClose()}
                onSubmit={this.submit}
            />
        );
    }

    private submit = (form: FormInstance) => {
        this.setState({errorFields: []}, () => {
            this.formValidation(form);
        });
    }

    private formValidation = (form: FormInstance) => {
        form.validateFields().then(values => {
            this.internalValidation(values, true);
        }).catch(info => {
            this.internalValidation(info.values, false);
        });
    }

    private internalValidation = (values: Store, formValidationSucceded: boolean) => {
        this.validate(values)?.then(errors => {

            if (formValidationSucceded && (!errors || errors.length === 0)) {
                this.save(values);
            } else {
                this.setState({errorFields: errors});
            }
        });
    }

    private validate = (values: Store) => {

        return new Promise((resolve: (f: FormErrorField[]) => void) => {
            Rest<{type: string, currentPassword: string, newPassword: string, confirmNewPassword: string}, ValidateChangePasswordResult>().operation({
                type: 'ValidateChangePassword', 
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
                confirmNewPassword: values.confirmNewPassword})
            .then(result => {
                let errors: FormErrorField[] = [];

                if (result && result.incorrectCurrentPassword) {
                    const passError = {fieldName: 'currentPassword', 
                        errorMessage: this.props.t('incorrectCurrentPassword')};

                    errors = FormUtils.addError(errors, passError);
                }

                if (result && result.incorrectPasswordSecurity) {
                    const passError = {fieldName: 'newPassword', 
                        errorMessage: this.props.t('incorrectPasswordSecurity')};

                    errors = FormUtils.addError(errors, passError);
                }

                if (result && result.incorrectPasswordSame) {
                    const passError = {fieldName: 'newPassword', 
                        errorMessage: this.props.t('incorrectPasswordSame')};

                    errors = FormUtils.addError(errors, passError);
                }

                if (result && result.incorrectPasswordUsedOne) {
                    const passError = {fieldName: 'newPassword', 
                        errorMessage: this.props.t('incorrectPasswordUsedOne')};

                    errors = FormUtils.addError(errors, passError);
                }

                if (result && result.incorrectPasswordConfirmation) {
                    const passError = {fieldName: 'confirmNewPassword', 
                        errorMessage: this.props.t('incorrectPasswordConfirmation')};

                    errors = FormUtils.addError(errors, passError);
                }

                resolve(errors);
            });
        });
    }

    private save = (values: Store) => {
        Rest<{type: string, newPassword: string}, boolean>()
        .operation({
            type: 'UpdatePassword',
            newPassword: values.newPassword,
        }).then(response => {
            if (response) {
                notification['success']({ 
                    message: this.props.t('changePasswordSuccessful')
                });
                this.props.onClose();
            }
        });
    }
}

export default withTranslation('changePasswordForm')(withRouter(ChangePasswordFormContainer));
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormInstance } from 'rc-field-form/lib/interface';
import { notification } from 'antd';
import View from './mailsPrueba';
import { Rest } from '../utils/utils';
import { update } from 'lodash';

interface IProps {
    visible?: boolean;
    onClose?: () => void;
}

export interface IState {
    typeNotificacion?:number[];
    valueAsunto?:string[];
    visibleLoaded?:boolean;
}

class MailsPruebaContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        visibleLoaded: false,
    };

    public render() {
        return (
            <>
                <View
                    visible={this.state.visibleLoaded && this.props.visible}
                    onCancel={() => this.props.onClose!()}
                    onSubmit={this.submit}
                    typeNotificacion={this.state.typeNotificacion || []}
                    valueAsunto={this.state.valueAsunto || []}
                />
            </>
        );
    }

    private submit = (form: FormInstance) => {
        const values = form.getFieldsValue();

        console.log(values);
        console.log(typeof values.remplazos);
        console.log(Array.isArray(values.remplazos));  // Esto te dirá si es un array (true/false)

    
        this.save(values); // Guardamos con los datos formateados
    }
    
    private save = (values: any) => { 
        // Validación para comprobar si la lista de reemplazos está vacía
        if (!values.remplazos || values.remplazos.length === 0) {
            notification['error']({ 
                message: "La lista de reemplazos no puede estar vacía"
            });
            return;
        }
    
        // Crear listas separadas para claves y valores
        const listaClaves = values.remplazos.map((item: { remplazoA: string }) => item.remplazoA);
        const listaValores = values.remplazos.map((item: { remplazoB: string }) => item.remplazoB);
    
        Rest<{ 
            type: string, 
            emailDesarrollador: string, 
            listaClaves: string[], 
            listaValores: string[], 
            idPlantilla: number 
        }, boolean>()
            .operation({
                type: 'ReciboRemplazosMailsPrueba',
                emailDesarrollador: values.emailDesarrollador,
                listaClaves: listaClaves, // Enviamos la lista de claves
                listaValores: listaValores, // Enviamos la lista de valores
                idPlantilla: values.tipoPlantilla,
            })
            .then(() => {
                notification.success({
                    message: this.props.t('success'),
                    description: "Mail enviado correctamente. ",
                });
        
                /*if (this.props.onClose) {
                    this.props.onClose();
                }*/
            });
        }
    
    
    
    

    public componentDidMount() {
        this.loadData();
        this.setState({visibleLoaded:true})
    }

    private loadData() {
        Rest<{ type: string }, any>().operation({
            type: 'SelectTiposPlantillaMail'
        }).then(response => {
            if (response && Array.isArray(response)) {
                // Extraemos la lista de idTipo y valueAsunto
                const typeNotificacion = response.map(item => item.idPlantilla);
                const valueAsunto = response.map(item => item.valueAsunto); // Asumiendo que también recibes valueAsunto
    
                // Actualizamos el estado con las listas
                this.setState({ typeNotificacion, valueAsunto });
            }
        });
    }
    
}
export default withTranslation('mailsPrueba')(withRouter(MailsPruebaContainer));
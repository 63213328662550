import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IState as IStateContainer } from './ProviderAccountStatsContainer';
import { AlignType } from 'rc-table/lib/interface';
import { Checkbox, Col, Form, FormInstance, Input, Modal, Row, Select, Table } from 'antd';
import { FormUtils, getUnaccentText, Rest, validateCif, validateDNI } from '../utils/utils';
import Municipio from './entities/municipio';

interface IProps {
    visible: boolean;
    onCancel: () => void;
    filtrarListaPagoExterno: () => void;
    data: Array<{
        nameCuenta: string,
        countPrescriptoresTotales: number;
        countPrescriptoresUltimos30: number;
        countPrescripcionesUltimos30: number;
        countPrescripcionesEsteAno: number;
    }>;
}


const ProviderAccountStats = (props: IProps) => {
    const { t } = useTranslation(['providerAccountListContainer']);



    const columns = [
        {
            title: t('cuentaTitle'),
            dataIndex: 'nameCuenta',
            key: 'nameCuenta',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '4rem',
        },
        {
            title: t('prescriptoresTitle'),
            dataIndex: 'countPrescriptoresTotales',
            key: 'countPrescriptoresTotales',
            ellipsis: true,
            align: 'right' as AlignType,
            width: '2rem',
        },
        {
            title: t('prescriptoresUlt30Title'),
            dataIndex: 'countPrescriptoresUltimos30',
            key: 'countPrescriptoresUltimos30',
            ellipsis: true,
            align: 'right' as AlignType,
            width: '3rem',
        },
        {
            title: t('prescripcionesUlt30Title'),
            dataIndex: 'countPrescripcionesUltimos30',
            key: 'countPrescripcionesUltimos30',
            ellipsis: true,
            align: 'right' as AlignType,
            width: '3rem',
        },
        {
            title: t('prescripcionesEsteAnioTitle'),
            dataIndex: 'countPrescripcionesEsteAno',
            key: 'countPrescripcionesEsteAno',
            ellipsis: true,
            align: 'right' as AlignType,
            width: '3rem',
        },
    ];

    return (
        <Modal
            visible={props.visible}
            style={{ top: 100 }}
            destroyOnClose
            width={1400}
            onCancel={props.onCancel}
        >
            <div className="table-container">
                <strong>
                    <p style={{ borderBottom: '1px solid #ececec', paddingBottom: '5px' }}>
                        {t('estadisticasCuentasTitle')}
                    </p>
                </strong>
                <Checkbox
                    onChange={(e) => props.filtrarListaPagoExterno()}
                    style={{ marginBottom: "10px" }}
                >
                    {t('pagoExternoCheckBox')}
                </Checkbox>

                <Table
                    className="table__custom-expandable-icon"
                    columns={columns}
                    dataSource={props.data} // Aquí pasamos las filas a la tabla
                    size="small"
                    scroll={{ y: 400 }} // Altura para el scroll vertical
                />
            </div>
        </Modal>
    );
};

export default ProviderAccountStats;

import React, { useState } from 'react';
import {
    faArchway,
    faBirthdayCake,
    faCircle,
    faCity,
    faEnvelope,
    faPhone,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Dropdown, FormInstance, Menu, Space, Table, Tooltip, Timeline, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { IState as IStateContainer } from './ProviderListContainer';
import "@fortawesome/fontawesome-free/css/all.css";
import { Functions, TableIcons } from '../utils/utils';
import ProviderFilters from './ProviderFilters';
import { AlignType } from 'rc-table/lib/interface';
import Highlighter from 'react-highlight-words';
import { EnterOutlined } from '@ant-design/icons';
import { PROVIDER_STATE_NAMES, stateColors, stateNames, stateStyle } from './ProviderSharedData';
import ProviderListItem from './entities/ProviderListData';
import './Provider.scss';
import { strDate24HTime } from '../utils/utils'
import { StateConfirmDialog } from "../dialogs/StateConfirmDialog";
import { stateItem } from "../dialogs/dialogSharedData";

interface IProps {
    loaded?: boolean;
    onCollapseFilters: () => void;
    onClickRRefreshState: () => void;
    onClickNewProvider: () => void;
    onChangePage: (page: number) => void;
    applyFilters: (form: FormInstance) => void;
    resetFilters: () => void;
    onShowDetails: (evt: any) => void;
    onConfirmChangeProviderState: (changeProviderState: { id: number; nextState: number }) => void;
    onReenviarEnlaceDeRegistro: (record: ProviderListItem) => void;
    onDesbloquearOperador: (record: ProviderListItem) => void;
    cuentaList?: any[];
}

const ProviderList = (props: IProps & IStateContainer) => {
    const { t } = useTranslation(['providerList']);
    const [menuState, setMenuState] = useState(
        { stateItemVisible: false, menuStateKey: 0, idPrestador: 0 }
    );

    const [dialogState, setDialogState] = useState<{
        visible: boolean; currentState: stateItem; nextState: stateItem; dialogMessage: string;
    }>({ visible: false, currentState: {}, nextState: {}, dialogMessage: '' });


    const onCancelConfirmationDialog = () => {
        setDialogState(prevState => ({ ...prevState, visible: false }));
    }

    const onSaveSelectedState = () => {
        setDialogState(prevState => ({ ...prevState, visible: false }));
        props.onConfirmChangeProviderState({ id: menuState.idPrestador, nextState: dialogState.nextState.stateId! });
    }

    const EstadoHistorico = (rec: ProviderListItem) => {

        return (
            <div>
                {rec.ultimoLogin && <div className='div-history-header'>
                    <b>{t('lastConexion')}: </b>{` ${strDate24HTime(rec.ultimoLogin)}`}
                </div>}
                {rec.prestadorEstadoHistoricoList?.length != 0 && <div className='div-history-header'>
                    <b>{t('historicoDeEstados')}</b>
                </div>}
                {rec.prestadorEstadoHistoricoList?.length != 0 && <div className='div-history-content'>
                    <Timeline>
                        {rec.prestadorEstadoHistoricoList?.map(e =>
                            <Timeline.Item
                                dot={<FontAwesomeIcon icon={faCircle} style={{ color: stateColors[e.typeEstado] }} />}>
                                <div>
                                    <b>{t(stateNames[e.typeEstado])}</b> {` ${strDate24HTime(e.datecreacion)}`}
                                </div>
                                <div>
                                    {e.nameNombre ? e.prestadorNombreCompleto : ""}
                                </div>
                            </Timeline.Item>
                        )}
                    </Timeline>
                </div>}
            </div>
        );
    }

    const actionRenderer = (value: number, record: ProviderListItem) => {
        const onClick = (e: any) => {
            setMenuState({ stateItemVisible: true, menuStateKey: new Date().getTime(), idPrestador: record.idPrestador! });
            switch (e.key) {
                case 'RE':
                    setDialogState({
                        visible: true,
                        currentState: {
                            stateName: stateNames[record.estado],
                            stateId: record.estado,
                            color: stateColors[record.estado]
                        },
                        nextState: {
                            stateName: stateNames[PROVIDER_STATE_NAMES.INIT],
                            stateId: PROVIDER_STATE_NAMES.INIT,
                            color: stateColors[PROVIDER_STATE_NAMES.INIT]
                        },
                        dialogMessage: t('recuperarAltaMessage', { prestadorNombre: record.prestadorNombre })
                    });
                    break;
                case 'PV':
                    setDialogState({
                        visible: true,
                        currentState: {
                            stateName: stateNames[record.estado],
                            stateId: record.estado,
                            color: stateColors[record.estado]
                        },
                        nextState: {
                            stateName: stateNames[PROVIDER_STATE_NAMES.AWAITING],
                            stateId: PROVIDER_STATE_NAMES.AWAITING,
                            color: stateColors[PROVIDER_STATE_NAMES.AWAITING]
                        },
                        dialogMessage: t('validarMessage', { prestadorNombre: record.prestadorNombre })
                    });
                    break;
                case 'VA':
                    setDialogState({
                        visible: true,
                        currentState: {
                            stateName: stateNames[record.estado],
                            stateId: record.estado,
                            color: stateColors[record.estado]
                        },
                        nextState: {
                            stateName: stateNames[PROVIDER_STATE_NAMES.VALIDATED],
                            stateId: PROVIDER_STATE_NAMES.VALIDATED,
                            color: stateColors[PROVIDER_STATE_NAMES.VALIDATED]
                        },
                        dialogMessage: t('validarAltaMessage', { prestadorNombre: record.prestadorNombre })
                    });
                    break;
                case 'AC':
                    setDialogState({
                        visible: true,
                        currentState: {
                            stateName: stateNames[record.estado],
                            stateId: record.estado,
                            color: stateColors[record.estado]
                        },
                        nextState: {
                            stateName: stateNames[PROVIDER_STATE_NAMES.ACTIVE],
                            stateId: PROVIDER_STATE_NAMES.ACTIVE,
                            color: stateColors[PROVIDER_STATE_NAMES.ACTIVE]
                        },
                        dialogMessage: t('activarMessage', { prestadorNombre: record.prestadorNombre })
                    });
                    break;
                case 'DE':
                    setDialogState({
                        visible: true,
                        currentState: {
                            stateName: stateNames[record.estado],
                            stateId: record.estado,
                            color: stateColors[record.estado]
                        },
                        nextState: {
                            stateName: stateNames[PROVIDER_STATE_NAMES.INACTIVE],
                            stateId: PROVIDER_STATE_NAMES.INACTIVE,
                            color: stateColors[PROVIDER_STATE_NAMES.INACTIVE]
                        },
                        dialogMessage: t('desactivarMessage', { prestadorNombre: record.prestadorNombre })
                    });
                    break;
                case 'CA':
                    setDialogState({
                        visible: true,
                        currentState: {
                            stateName: stateNames[record.estado],
                            stateId: record.estado,
                            color: stateColors[record.estado]
                        },
                        nextState: {
                            stateName: stateNames[PROVIDER_STATE_NAMES.CANCELLED],
                            stateId: PROVIDER_STATE_NAMES.CANCELLED,
                            color: stateColors[PROVIDER_STATE_NAMES.CANCELLED]
                        },
                        dialogMessage: t('cancelarAltaMessage', { prestadorNombre: record.prestadorNombre })
                    });
                    break;
                case 'RM':
                    props.onReenviarEnlaceDeRegistro(record);
                    break;
                case 'DB':
                    props.onDesbloquearOperador(record);
                    break;
            }
        }

        const extraDropdownMenu = (
            <Menu onClick={onClick}>
                {(record.allowedStateFlags?.flagAccionCancelarAlta) &&
                    <Menu.Item key='CA'>
                        {t('cancelarAlta')}
                    </Menu.Item>
                }
                {(record.allowedStateFlags?.flagAccionPasarAValidacion) &&
                    <Menu.Item key='PV'>
                        {t('validar')}
                    </Menu.Item>
                }
                {(record.allowedStateFlags?.flagAccionValidarAlta) &&
                    <Menu.Item key='VA'>
                        {t('validarAlta')}
                    </Menu.Item>
                }
                {(record.allowedStateFlags?.flagAccionDesactivar) &&
                    <Menu.Item key='DE'>
                        {t('desactivar')}
                    </Menu.Item>
                }
                {(record.allowedStateFlags?.flagAccionActivar) &&
                    <Menu.Item key='AC'>
                        {t('activar')}
                    </Menu.Item>
                }
                {(record.allowedStateFlags?.flagAccionRecuperarAlta) &&
                    <Menu.Item key='RE'>
                        {t('recuperarAlta')}
                    </Menu.Item>
                }
                {(record.estado === PROVIDER_STATE_NAMES.VALIDATED) &&
                    <Menu.Item key='RM'>
                        {t('reenviarEnlaceDeRegistro')}
                    </Menu.Item>
                }
                {(record.allowedStateFlags?.flagDesbloquear) &&
                    <Menu.Item key='DB'>
                        {t('desbloquear')}
                    </Menu.Item>
                }

            </Menu>
        )

        return (
            <Space size='small'>
                <Tooltip title={t('providerDetailButton')} placement='left'>
                    <Button type='default'
                        onClick={e => props.onShowDetails(record.idPrestador)}

                    >{t('buttons:details')}</Button>
                </Tooltip>
                <Tooltip title={t('extraMenu')} placement='left'>
                    <Dropdown overlay={extraDropdownMenu} trigger={['click']}>
                        <Button type='primary' icon={TableIcons.getTableIcon(TableIcons.TableIcon.more)} />
                    </Dropdown>
                </Tooltip>
            </Space>
        )
    }

    const iconStateRenderer = (value: number) => {
        return <div style={stateStyle}>
            <FontAwesomeIcon icon={faCircle}
                style={{ color: stateColors[value], marginRight: '0.5rem' }} /> {t(stateNames[value])} </div>;
    }

    const columns = [
        {
            title: t('prestadorNombreColumnTitle'),
            dataIndex: 'prestadorNombre',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '16rem',
            render: (providerName: string) => {
                return <b>{props.highlightProviderName ?
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={props.highlightProviderName}
                        textToHighlight={providerName}
                    />
                    : providerName}</b>
            }
        },
        { title: t('nifColumnTitle'), dataIndex: 'nif', ellipsis: true, align: 'left' as AlignType, width: '8rem' },
        {
            title: t('colegiadoColumnTitle'),
            dataIndex: 'colegiado',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '10rem'
        },
        { title: t('especialidadColumnTitle'), dataIndex: 'especialidad', ellipsis: true, align: 'left' as AlignType },
        {
            title: t('account'),
            dataIndex: 'nameCuenta',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '14rem'
        },
        {
            title: t('estadoColumnTitle'),
            dataIndex: 'estado',
            ellipsis: true,
            align: 'left' as AlignType,
            render: iconStateRenderer,
            width: '12rem'
        },
        {
            title: t('activadoColumnTitle'),
            dataIndex: 'activado',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '6.5rem',
            render: (dt: Date) => {
                return dt && Functions.dateToString(dt);
            }
        },
        {
            title: t('creacionColumnTitle'),
            dataIndex: 'creacion',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '6.5rem',
            render: (dt: Date) => {
                return dt && Functions.dateToString(dt);
            }
        },

        { dataIndex: 'idPrestador', align: 'center' as AlignType, render: actionRenderer, width: '9rem' }

    ];

    const Details = (rec: ProviderListItem) => {
        return (
            <>
                <Space>
                    <EnterOutlined className='table-expandable-detail__arrow' />
                    {rec.dateNacimiento &&
                        <div className='table-expandable-detail'><FontAwesomeIcon icon={faBirthdayCake} />
                            {Functions.dateToString(rec.dateNacimiento)}</div>}
                    {rec.email &&
                        <div className='table-expandable-detail'><FontAwesomeIcon icon={faEnvelope} /> {rec.email}</div>}
                    {rec.telefono &&
                        <div className='table-expandable-detail'><FontAwesomeIcon icon={faPhone} /> {rec.telefono}</div>}
                    {rec.codeProvincia &&
                        <div className='table-expandable-detail'><FontAwesomeIcon icon={faArchway} /> {rec.nameProvincia}
                        </div>}
                    {rec.codeMunicipio &&
                        <div className='table-expandable-detail'><FontAwesomeIcon icon={faCity} /> {rec.nameMunicipio}</div>}
                </Space>

                {EstadoHistorico(rec)}
            </>
        )
    }

    return (
        <>
            <Drawer className='filters-drawer' visible={!props.filtersCollapsed}
                onClose={props.onCollapseFilters}
                width='300px' placement='left' getContainer={false}>
                {<ProviderFilters
                    {...props}
                    key={props.filterKey}
                />}
            </Drawer>
            <Space size='small' className='table-button-bar'>
                <Badge className='filter-badge' count={props.nFilters}>
                    <Button type={props.filtersCollapsed ? 'primary' : 'default'}
                        icon={TableIcons.getTableIcon(TableIcons.TableIcon.filter)}
                        onClick={props.onCollapseFilters} />
                </Badge>
                <Button type={'primary'} icon={TableIcons.getTableIcon(TableIcons.TableIcon.sync)}
                    onClick={() => props.onClickRRefreshState()} />
                <Button icon={<FontAwesomeIcon icon={faUserPlus} style={TableIcons.PRIMARY_BUTTON_ICON_STYLE} />}
                    onClick={() => props.onClickNewProvider()}>
                    {t('newProvider')}
                </Button>
            </Space>
            <div className='table-container'>
                <Table className='table__custom-expandable-icon'
                    scroll={{ y: props.tableBodyHeight }}
                    columns={columns}
                    size='small'
                    expandable={{
                        expandedRowRender: record => <Details {...record} />,
                        expandIconColumnIndex: 8,
                        expandIcon: ({ expanded, onExpand, record }) =>
                            <Button type={expanded ? 'primary' : 'default'}
                                icon={expanded ? TableIcons.getTableIcon(TableIcons.TableIcon.chevronUp) :
                                    TableIcons.getTableIcon(TableIcons.TableIcon.chevronDown)}
                                onClick={e => onExpand(record, e)} />
                    }}
                    dataSource={props.providerList}
                    pagination={{
                        position: ['bottomRight'],
                        hideOnSinglePage: true,
                        showSizeChanger: false,
                        current: props.page,
                        pageSize: props.limit,
                        total: props.dataCount,
                        onChange: props.onChangePage
                    }}
                />
            </div>

            <StateConfirmDialog
                visible={dialogState.visible}
                key={new Date().getTime()}
                currentState={dialogState.currentState}
                nextState={dialogState.nextState}
                dialogMessage={dialogState.dialogMessage}
                onCancelConfirmationDialog={onCancelConfirmationDialog}
                onSaveSelectedState={onSaveSelectedState}
            />

        </>

    )
}

export default ProviderList;

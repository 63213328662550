import React from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { FormInstance } from 'rc-field-form/lib/interface';
import { FormUtils } from '../utils/utils';
import LOGOERECETA_BACKOFFICE from '../images/logo.png';
import FormErrorField from '../utils/form/formErrorField';
import cx from 'classnames';

interface IProps {
    errorFields: FormErrorField[];
    onSubmit: (form: FormInstance) => void;
}

const WelcomeStepThreeView = (props: IProps) => {

    const { t } = useTranslation(['welcome']);
    const [form] = Form.useForm();

    return (
        <div className='enlace_token_container'>
            <div className='enlace_token_card_container'>
                <div className='enlace_token_card' style={{width: '484px'}}>
                    <div className='enlace_token_logo'><img src={LOGOERECETA_BACKOFFICE} alt={''} /></div>
                    <div className='enlace_token_title'>{t('setCredentialsTitle')}</div>
                    <div className={cx('enlace_token_prestador', {'welcome': true})}>{t('setCredentialsLabel')}</div>

                    <div className='credential_condition'>
                        <div>{'- ' + t('charactersLength')}</div>
                        <div>{'- ' + t('lowercaseConditionLabel')}</div>
                        <div>{'- ' + t('capitalConditionLabel')}</div>
                        <div>{'- ' + t('numberConditionLabel')}</div>
                        <div>{'- ' + t('symbolConditionLabel')}</div>
                    </div>

                    <Form layout='vertical' form={form} size='large' className='form__password'>
                        <Form.Item
                            label={t('passwordLabel')}
                            name='newPassword'
                            rules={[{required: true}]}
                            validateStatus={FormUtils.isError('newPassword', props.errorFields) ? 'error' : undefined}
                            help={FormUtils.getErrorMessage('newPassword', props.errorFields)}
                        >
                            <Input.Password />
                        </Form.Item>
                        
                        <Form.Item
                            label={t('confirmPasswordLabel')}
                            name='confirmNewPassword'
                            rules={[{required: true}]}
                            validateStatus={FormUtils.isError('confirmNewPassword', props.errorFields) ? 'error' : undefined}
                            help={FormUtils.getErrorMessage('confirmNewPassword', props.errorFields)}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Form>

                    <div className='alta-buttons'>
                        <Button type='primary' size='middle' onClick={() => props.onSubmit(form)}>
                            {t('buttons:continue')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeStepThreeView;
import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Store } from 'rc-field-form/lib/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const ForgotPassword = (props: {
    warningPasswordMessage?: string;
    errorPasswordMessage?: string;
    mailSent?: boolean;
    onForgotPassword: (values: Store) => void;
    validationPasswordFailed: () => void;
    closePasswordForm: () => void;
}) => {

    const { t } = useTranslation(['login']);
    
    return (
        <>
            {!props.mailSent ?
            <>
                <div className='regenerate-password'>{t('regeneratepassword')}</div>
                <div style={{textAlign: 'center'}}>{t('forgotPasswordSubtitle')}</div>
                <Form size='large' style={{marginTop: '1rem'}} onFinish={props.onForgotPassword} onFinishFailed={props.validationPasswordFailed}>
                    <Form.Item name='username' rules={[{ required: true }]}>
                        <Input placeholder={t('username')} 
                            prefix={<UserOutlined />} />
                    </Form.Item>
                    {props.warningPasswordMessage && <Alert message={props.warningPasswordMessage} type='warning' showIcon/>}
                    {props.errorPasswordMessage && <Alert message={props.errorPasswordMessage} type='error' showIcon/>}
                    <Button type='primary' htmlType="submit" size='large' block style={(props.warningPasswordMessage || props.errorPasswordMessage) ? {marginTop:'1.5rem'} : undefined}>
                        {t('newPasswordButton')}
                    </Button>
                </Form>
                <Button type='link' block style={{marginTop:'1.0rem'}} onClick={props.closePasswordForm}>{t('buttons:cancel')}</Button>
            </>
            :
            <>
            <div style={{textAlign: 'center'}}><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'yellowGreen', fontSize: '3rem', marginBottom: '1rem' }} /></div>
            <div style={{textAlign: 'center'}}>{t('mailSentSubtitle')}</div>
            <Form size='large' style={{marginTop: '1rem'}} onFinish={props.closePasswordForm}>
                <Button type='primary' htmlType="submit" size='large' block>
                    {t('returnLoginButton')}
                </Button>
            </Form>
            </>
        }
        </>
    )
}

export default ForgotPassword;

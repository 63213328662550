import React, {useEffect, useState} from 'react';
import {
    Col,
    Form,
    FormInstance,
    Input,
    Modal,
    Row,
    Select,
} from 'antd';
import {Option} from 'rc-select';
import {getUnaccentText, Rest} from '../utils/utils';
import {useTranslation} from 'react-i18next';
import FormErrorField from '../utils/form/formErrorField';
import Municipio from './entities/municipio';
import Provincia from './entities/Provincias';
import {ICuentaLocation} from './entities/Cuenta';


interface IProps {
    visible: boolean;
    formTitle?: string;
    onCancel: () => void;
    onSubmit: (form: FormInstance) => void;
    idCuenta: number;
    nameCuenta: string;
    provincias: Provincia[];
    provinciasLoaded: boolean;
    locationData?: ICuentaLocation;
}

const LocationForm = (
    props: IProps,
) => {

    const {t} = useTranslation(['providerAccountListContainer']);
    const [form] = Form.useForm();
    const [municipiosPorProvincia, setMunicipiosPorProvincia] = useState<Municipio[]>([]);
    const [, setErrorFields] = useState<FormErrorField[]>([]);
    const [codigoPostal, setCodigoPostal] = useState(props.locationData?.codeCodigoPostal);



    const loadMunicipios = (codeProvincia: string) => {
        if (!codeProvincia && props.provincias.length > 0) {
            codeProvincia = props.provincias[0].codeProvincia;
        }

        Rest<{ type: string; codeProvincia: string }, Municipio[]>()
            .operation({
                type: 'SelectMunicipiosByProvinciaOrdered',
                codeProvincia: codeProvincia,
            })
            .then(response => {
                    setMunicipiosPorProvincia(response);
                    if (props.locationData && props.locationData.codeProvincia && props.locationData.codeMunicipio) {
                        const municipioSelected = response.find(e =>
                            e.codeProvincia === props.locationData?.codeProvincia &&
                            e.codeMunicipio === props.locationData?.codeMunicipio,
                        );
                        if (municipioSelected) {
                            form.setFieldsValue({codeMunicipio: municipioSelected.codeMunicipio});
                        }
                    } else {
                        form.setFieldsValue({
                            codeProvincia: codeProvincia,
                            codeMunicipio: undefined,
                        });
                    }
                },
            );
    };

    useEffect(() => {
        if (props.visible) {
            setErrorFields([]);
            form.resetFields();
            form.setFieldsValue({idCuenta: props.idCuenta, nameCuenta: props.nameCuenta,
            });

            if (props.locationData) { //form data
                form.setFieldsValue({...props.locationData});
                loadMunicipios(props.locationData.codeProvincia!);
            }

        }
    }, [props.visible]);


    const onSubmit = () => {
        form.validateFields().then(() => {
            props.onSubmit(form);
        }).catch((err)=> {
            if(!props.locationData) {
                props.onSubmit(form);
             }
        });
    };


    const onCodigoPostalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let selectedCodeProvincia = event.target.value;
        if (!selectedCodeProvincia || selectedCodeProvincia.length < 2) {
            setMunicipiosPorProvincia([]);
            form.setFieldsValue({codeMunicipio: undefined});
            return;
        }

        selectedCodeProvincia = event.target.value.substring(0, 2);
        let oldCodigoPostal = codigoPostal ? codigoPostal.substring(0, 2) : '';


        if (oldCodigoPostal.length < 2 || oldCodigoPostal !== selectedCodeProvincia) {
            setCodigoPostal(selectedCodeProvincia);
            form.setFieldsValue({codeProvincia: selectedCodeProvincia});
            loadMunicipios(selectedCodeProvincia);
        }

    };

    const onProvinciaSelect = (value: any) => {
        let codeProvincia = value.toString().substring(0, 2);
        form.setFieldsValue({codeCodigoPostal: codeProvincia});
        loadMunicipios(value.toString());
    };

    return (
        <Modal
            title={ props.locationData ? t('locationFor'): t('newLocation')}
            visible={props.visible || false}
            style={{top: 40}}
            destroyOnClose
            width={800}
            cancelText={t('buttons:cancel')}
            okText={t('buttons:save')}
            onCancel={props.onCancel}
            onOk={onSubmit}
        >

            <Form layout='vertical' form={form} size='large'>
                <div>
                    <Row gutter={8} style={{display: 'flex', alignItems: 'center'}}>
                        <Col span={16}>
                            <Form.Item label={t('nameLocalizacion')} name='nameLocalizacion' rules={[{required: true}]}>
                                <Input maxLength={128}/>
                            </Form.Item>
                        </Col>
                        <Col  span={8}>
                            <Form.Item label={t('identificadorExterno')} name='idExterno' rules={[{required: false}]}>
                                <Input maxLength={128}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={16}>
                            <Form.Item label={t('direccion')} name='valueDireccion' rules={[{required: false}]}>
                                <Input maxLength={128}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('detalleDireccion')} name='valueDireccion2'>
                                <Input maxLength={128}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Form.Item label={t('codeCodigoPostal')} name='codeCodigoPostal' rules={[{required: false}]}>
                                <Input maxLength={5} onChange={onCodigoPostalChange}
                                       placeholder={t('providerList:introduceCodigoPostal')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('provincia')} name='codeProvincia' rules={[{required: false}]}>
                                <Select className='doc-identidad'
                                        showSearch notFoundContent={false}
                                        placeholder={t('providerList:selectProvincia')}
                                        onSelect={onProvinciaSelect}
                                        filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                                >
                                    {props.provincias.map(e => {
                                        return (
                                            <Option key={e.codeProvincia} value={e.codeProvincia}
                                                    label={getUnaccentText(e.nameProvincia)}>
                                                {e.nameProvincia}
                                            </Option>
                                        );
                                    })}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t('poblacion')} name='codeMunicipio' rules={[{required: false}]}>
                                <Select className='doc-identidad'
                                        disabled={municipiosPorProvincia.length === 0}
                                        showSearch notFoundContent={false}
                                        placeholder={t('providerList:selectPoblacion')}
                                        filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                                >
                                    {municipiosPorProvincia!.map(e => {
                                        return (
                                            <Option key={e.codeMunicipio} value={e.codeMunicipio}
                                                    label={getUnaccentText(e.nameMunicipio)}>
                                                {e.nameMunicipio}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item label={t('email')} name='valueEmail' rules={[{required: false}]}>
                                <Input maxLength={128}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={t('telefono')} name='valueTelefono' rules={[{required: false}]}>
                                <Input maxLength={128}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name='idCuenta' hidden/>
                    <Form.Item name='idLocalizacion' hidden/>
                    <Form.Item name='idDatosContacto' hidden/>
                </div>
            </Form>


        </Modal>

    );
};

export default LocationForm;

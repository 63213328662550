import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { Option } from 'rc-select';
import { stateColors, stateNames, stateStyle } from './OperadorSharedData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

const OperadorFilters = (props: {
    operadorNameFilter?: { value: string }[],
    nifFilter?: { value: string }[],
    searchOperadorName: (value: string) => void,
    applyFilters: (form: FormInstance) => void,
    resetFilters: () => void,

}) => {

    const [statusDropDownVisible, setStatusDropDownVisible] = useState<boolean>();

    const { t } = useTranslation(['usuariosOperadoresList']);
    const [form] = Form.useForm();

    const resetFilters = () => {
        form.resetFields();
        props.resetFilters();
    }

    const statusOptions = (value: number) => {
        const strStatus = t(stateNames[value]);

        return (
            <Option key={strStatus} value={value}>
                <div style={stateStyle}>
                    <FontAwesomeIcon icon={faCircle} style={{ color: stateColors[value], marginRight: '0.5rem' }} /> {strStatus}
                </div>
            </Option>
        );
    }

    return (
        <>
            <Form size='large' layout='vertical' className='form__smaller-margin' form={form} style={{ width: '100%' }}>
                <Form.Item name='nameNombre' label={t('operadorNombreColumnTitle')}>
                    <AutoComplete
                        options={props.operadorNameFilter}
                        placeholder={t('placeHolders:enterName')}
                        onSearch={props.searchOperadorName}
                    />
                </Form.Item>
                <Form.Item name='codeIdentificacion' label={t('nifColumnTitle')}>
                    <AutoComplete
                        options={props.nifFilter}
                        placeholder={t('placeHolders:enterNif')}
                    />
                </Form.Item>
                <Form.Item name='typeEstado' label={t('estadoColumnTitle')}>
                    <Select allowClear mode='multiple' optionFilterProp="value" 
                            onDropdownVisibleChange={(visible => _.delay(() => setStatusDropDownVisible(visible), 250))}>
                        {statusOptions(1)}
                        {statusOptions(2)}
                        {statusOptions(3)}
                    </Select>
                </Form.Item>
            </Form>
            <Row gutter={16} style={statusDropDownVisible ? {marginTop: '8rem'} : {}}>
                <Col span={12}>
                    <Button type='primary' block onClick={() => props.applyFilters!(form)}>
                        {t('buttons:apply')}
                    </Button>
                </Col>
                <Col span={12}><Button danger block onClick={resetFilters}
                >{t('buttons:reset')}</Button></Col>
            </Row>
        </>
    );
}

export default OperadorFilters;

import {FormInstance} from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {Functions, Rest, validateDNI} from '../utils/utils';
import Especialidad from "./entities/especialidad";
import Municipio from "./entities/municipio";
import Provincia from "./entities/Provincias";
import _ from 'lodash';
import View from './ProviderForm';
import Persona from "./entities/Persona";
import Prestador from './entities/Prestador';
import PrestadorDetailData from './entities/PrestadorDetailData';
import { URL } from '../utils/rest';
import DownloadDocItem from "./entities/DownloadDocItem";
import RcFileEx from "./entities/RcFileEx";
import EnumPrestadorTypeIdentificacion from "../enum/EnumPrestadorTypeIdentificacion";
import Colegio from "./entities/Colegio";


interface IProps {
    idProvider?: number;
    visible?: boolean;
    isNewPrestador: boolean;
    onClose: (save: boolean) => void;
    updateDownloadDocList: (docList: DownloadDocItem[]) => void;
    removeDocFromList: (fileId: number) => void;
    providerFormVisible: boolean;
    selectedPrestador?: PrestadorDetailData;
    downloadDocList: DownloadDocItem[];
}

export interface IState {
    loaded?: boolean;
    provinciaList?: Provincia[];
    especialidadList?: Especialidad[];
    codigoPostal: string;
    provinciaPorCp?: Provincia[];
    municipioList?: Municipio[];
    disablePoblacion: boolean;
    fileList: RcFileEx[];
    base64File?: string;
    colegiosList?: Colegio[];
}

class ProviderFormContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        codigoPostal: '',
        disablePoblacion: true,
        fileList: [],
    };

    readonly PASAPORTE_KEY = 4;
    private allProvincias: Provincia[] = [];

    private colegios: Colegio[] = [];

    readonly fsLimit = 5 * 1024 * 1024;
    readonly acceptedFileTypes = 'image/jpeg,image/jpg,image/png,image/bmp,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf';
    readonly acceptedFileTypesArray: string[] = this.acceptedFileTypes.split(',');

    public componentDidMount() {
        this.loadData();
    }

    public render() {
        return (
            this.state && this.state.loaded ?
                <View
                    {...this.state}
                    {...this.props}
                    visible={this.props.providerFormVisible || false}
                    onCancel={() => this.props.onClose(false)}
                    onSubmit={this.submit}
                    CPChange={this.CPChange}
                    onFormValuesChanged={this.onFormValuesChanged}
                    onFileUpload={this.onFileUpload}
                    onFileRemove={this.onFileRemove}
                    onDownloadDocFile={this.onDownloadDocFile}
                    acceptedFileTypes={this.acceptedFileTypes}
                />
                : <></>
        )
    }

    private loadData = () => {

        const promises: Array<Promise<any>> = [];
        const rqProvincias = Rest<{ type: string }, Provincia[]>();
        const rqEspecialidad = Rest<{ type: string }, Especialidad[]>();
        const rqMunicipios = Rest<{ type: string; codeProvincia: string }, any>();
        const rqColegios = Rest<{ type: string }, Colegio[]>();
        promises.push(rqProvincias.operation({ type: 'SelectProvincia' }));
        promises.push(rqEspecialidad.operation({ type: 'SelectEspecialidadOrdered' }));
        promises.push(rqColegios.operation({type:'SelectColegio'}));

        if (this.props.selectedPrestador && this.props.selectedPrestador.codeProvincia) {
            promises.push(rqMunicipios.operation({
                type: 'SelectMunicipiosByProvinciaOrdered',
                codeProvincia: this.props.selectedPrestador.codeProvincia
            }));
        }

        Promise.all(promises).then(response => {

            this.allProvincias = response[0];
            const respEspecialidad: Especialidad[] = response[1];
            this.colegios = response[2];

            if (this.props.isNewPrestador) {

                this.setState({
                    provinciaList: this.allProvincias,
                    especialidadList: respEspecialidad,
                    provinciaPorCp: this.allProvincias,
                    colegiosList: this.colegios,
                    loaded: true,
                });

            } else {
                const mcp: Municipio[] = response[3]
                this.setState({
                    provinciaList: this.allProvincias,
                    especialidadList: respEspecialidad,
                    provinciaPorCp: this.allProvincias,
                    municipioList: mcp,
                    colegiosList: this.colegios,
                    loaded: true,
                    disablePoblacion: false
                });

            }
        });
    }

    private CPChange = (e: any, frm: FormInstance) => {
        let s: string = e.target.value.substring(0, 2);

        const provinciasFiltered: Provincia[] = this.allProvincias.filter(e => e.codeProvincia.startsWith(s));

        frm.setFieldsValue({
            ...frm.getFieldsValue, 'valueProvincia':
                (provinciasFiltered.length > 0) ? provinciasFiltered[0].nameProvincia : undefined
        });

        if (s.trim() === '') {

            frm.setFieldsValue({
                ...frm.getFieldsValue, 'valueProvincia': undefined, 'valuePoblacion': undefined
            });

            this.setState({
                codigoPostal: '', provinciaPorCp: provinciasFiltered, municipioList: undefined,
                disablePoblacion: true,
            });

            return;
        }


        Rest<{ type: string; codeProvincia: string }, any>().operation({
            type: 'SelectMunicipiosByProvinciaOrdered',
            codeProvincia: s,
        }).then(response => {
            const mcp: Municipio[] = response;
            frm.setFieldsValue({
                ...frm.getFieldsValue, 'valuePoblacion':
                    mcp.length > 0 ? mcp[0].nameMunicipio : undefined
            });

            this.setState({
                codigoPostal: s, provinciaPorCp: provinciasFiltered, municipioList: mcp,
                disablePoblacion: false
            });
        });
    }


    private submit = (form: FormInstance) => {
        this.formValidationAndSave(form);
    }

    private formValidationAndSave = async (form: FormInstance) => {

        let hasErrors = false;
        form.setFields([]);

        //file/fileList inconsistent state
        const subirFieldNames: string[] = [];
        for (const [key, value] of Object.entries(form.getFieldsValue())) {
            let fieldName = key;
            let fieldValue: any = value;
            if (fieldName.startsWith('subir')) {
                subirFieldNames.push(fieldName);
                if(typeof fieldValue !== 'undefined' && typeof fieldValue['fileList'] !== 'undefined' && fieldValue['fileList'].length === 0) {
                    form.setFieldsValue({[fieldName]: undefined});
                }
            }
        }

        await form.validateFields().then(() => {
            hasErrors = false;
        }).catch(errors => {
            hasErrors = true;
            let errFields: string[] = errors.errorFields.filter((e: any) => e.name[0].startsWith('subir'))
                .map((f: any) => f.name[0]);

            errFields.forEach(e => {

                form.setFields([
                    { name: e, errors: [this.props.t('attachFile')] },
                ]);

            })

            this.checkLocalFieldErrors(form);
        }).finally(() => {
            if (!hasErrors) {
                hasErrors = hasErrors || this.checkLocalFieldErrors(form);
            }
        });

        if (hasErrors) {
            return;
        }

        if (this.props.isNewPrestador) {
            //Local check passed, ok
            //validate data from server (NIE & Colegiado)  for new prestador only
            const promises: Array<Promise<any>> = [];
            const req1 = Rest<{ type: string, typeIdentificacion: number, codeIdentificacion: string }, Persona>();
            const req2 = Rest<{ type: string, typeIdentificacion: number, codeIdentificacion: string, codeEspecialidad: string }, Prestador>();
            promises.push(req1.operation({
                type: 'SelectPrestadorByCodeIdentificacion',
                typeIdentificacion: form.getFieldValue('nifPrefix').value!,
                codeIdentificacion: form.getFieldValue('valueNif')
            }));

            promises.push(req2.operation({
                type: 'SelectPrestadorByEspecialidad',
                typeIdentificacion: form.getFieldValue('colegiadoPrefix').value!,
                codeIdentificacion: form.getFieldValue('valueColegiado'),
                codeEspecialidad: form.getFieldValue('valueEspecialidad').value!
            }));

            await Promise.all(promises).then(response => {
                if (response[0] != null) {
                    form.setFields([
                        { name: 'valueNif', errors: [this.props.t('dniExiste')] },
                    ]);

                    hasErrors = true;
                }

                if (response[1] != null) {
                    form.setFields([
                        { name: 'valueColegiado', errors: [this.props.t('colegiadoExiste')] },
                    ]);

                    hasErrors = true;
                }

                if (hasErrors) {
                    return;
                }
            });
        }

        //Prepare
        let formData = new FormData();
        let prestadorData: { [k: string]: any } = {};

        for (const [key, value] of Object.entries(form.getFieldsValue())) {
            let v: any = value;

            if (v && (typeof v === 'object' && v['file'])) {  // Do not add files, later will be done whith added/deleted lists

            }
            else {
                if (v && (typeof v === 'object' && (typeof v['value'] !== 'undefined'))) {
                    prestadorData[key] = v.value;  // For <Select> where the pair of key/value is used
                }
                else {
                    prestadorData[key] = v;
                }
            }
        }


        prestadorData['idPrestador'] = this.props.isNewPrestador ? undefined : this.props.idProvider;
        formData.append('prestadorData', JSON.stringify(prestadorData));

        //Sending updated file list in case 
        if (!this.props.isNewPrestador) {
            if ((typeof this.props.downloadDocList !== 'undefined') && this.props.downloadDocList.length > 0) {
                formData.append('prestadorStateFileList', JSON.stringify(this.props.downloadDocList));
            }
        }

        this.state.fileList.forEach(e => {
            const attachedFieldErrors: string[] = [];
            const fldIdx = parseInt(e.groupId!, 10) - 11;
            if ((e.fileId! < 0 && e.size) > this.fsLimit) {
                attachedFieldErrors.push(this.props.t('providerList:fileIsTooLarge'));
            }

            if (this.acceptedFileTypesArray.indexOf(e.type) === -1) {
                attachedFieldErrors.push(this.props.t('providerList:incorrectFileType'));
            }

            if (attachedFieldErrors.length > 0) {
                form.setFields([
                    {name: subirFieldNames[fldIdx + 10], errors: attachedFieldErrors},
                ]);

                hasErrors = true;
            }

            formData.append('file', e, e.name + '|' + e.groupId);
        });
        // Save if all is ok
        if (!hasErrors) {
            this.processPrestadorData(formData);
        }
    }

    private processPrestadorData = (formData: FormData) => {
        Rest<void, any>()
            .fetchURL('/fileUpload?typeUpload=uploadPrestadorForm', { method: 'POST', body: formData })
            .then(() => {
                this.props.onClose(true);
            });

    }

    private checkLocalFieldErrors = (form: FormInstance): boolean => {
        const fv: any = form.getFieldsValue();
        let hasErrors: boolean = false;
        if (!fv.valueCodigoPostal || fv.valueCodigoPostal.length !== 5) {
            form.setFields([
                { name: 'valueCodigoPostal', errors: [''], },
            ]);

            hasErrors = true;
        }

        if (!fv.valueNif || fv.valueNif.length < 9) {
            form.setFields([
                { name: 'valueNif', errors: [''], },
            ]);

            hasErrors = true;
        }

        //Pasaporte/DNI/NIE value check
        if(fv.nifPrefix.key === this.PASAPORTE_KEY) {
            if(fv.valueNif.toString().length < 4) {
                form.setFields([
                    {name: 'valueNif', errors: [this.props.t('incorrectNif')],},
                ]);
                hasErrors = true;
            }
        } else if (!validateDNI(fv.valueNif)) {
            form.setFields([
                { name: 'valueNif', errors: [this.props.t('incorrectNif')], },
            ]);

            hasErrors = true;
        }

        //Age
        if(Functions.calculateAge(fv.valueFechaDeNacimiento) < 18) {
            form.setFields([
                { name: 'valueFechaDeNacimiento', errors: [this.props.t('mayorEdadError')], },
            ]);

            hasErrors = true;
        }

        //COLEGIADO check
        if (fv.valueColegiado && fv.colegiadoPrefix.value === EnumPrestadorTypeIdentificacion.NUMERO_COLEGIADO && (fv.valueColegiado.length !== 9 && fv.valueColegiado.length !== 8)) {
            form.setFields([
                { name: 'valueColegiado', errors: [this.props.t('colegiadoError')] },
            ]);

            hasErrors = true;
        }

        return hasErrors;
    }

    private onFormValuesChanged = (frm: FormInstance, field: any) => {

        if (typeof field.valueCodigoPostal !== 'undefined') {

            let s: string = field.valueCodigoPostal.substring(0, 2);
            const isPvCodeTooShort = s.trim().length < 2;

            if (isPvCodeTooShort) {
                frm.setFieldsValue({
                    ...frm.getFieldsValue, 'valueProvincia': undefined,
                    'valuePoblacion': undefined
                });

                this.setState({
                    codigoPostal: s, provinciaPorCp: this.allProvincias, municipioList: undefined,
                    disablePoblacion: true
                });

                return;
            }

            const provinciasFiltered: Provincia[] = this.allProvincias.filter(e => e.codeProvincia.startsWith(s));

            if (s === this.state.codigoPostal) {
                return;
            }

            frm.setFieldsValue({
                ...frm.getFieldsValue, 'valueProvincia':
                    (provinciasFiltered.length > 0) ? provinciasFiltered[0].codeProvincia : undefined
            });

            if (provinciasFiltered.length > 0) {
                this.searchPoblacionByCP(frm, provinciasFiltered[0].codeProvincia, provinciasFiltered);
            }

        } else
            if (typeof field.valueProvincia !== 'undefined') {
                this.searchPoblacionByProvincia(frm, field.valueProvincia);
            }
    }

    private searchPoblacionByCP = _.debounce((frm: FormInstance, s: string, provinciasFiltered: Provincia[]) => this.doSearchPoblacion(frm, s, provinciasFiltered), 500);

    private doSearchPoblacion = (frm: FormInstance, s: string, provinciasFiltered: Provincia[]) => {
        Rest<{ type: string; codeProvincia: string }, any>().operation({
            type: 'SelectMunicipiosByProvinciaOrdered',
            codeProvincia: s,
        }).then(response => {
            const mcp: Municipio[] = response;
            frm.setFieldsValue({
                ...frm.getFieldsValue, 'valuePoblacion': undefined,
            });

            this.setState({
                codigoPostal: s, provinciaPorCp: provinciasFiltered, municipioList: mcp,
                disablePoblacion: false
            });
        });
    }

    private searchPoblacionByProvincia = _.debounce((frm: FormInstance, s: string) => this.doSearchPoblacionByProvincia(frm, s), 500);

    private doSearchPoblacionByProvincia = (frm: FormInstance, s: string) => {
        Rest<{ type: string; codeProvincia: string }, any>().operation({
            type: 'SelectMunicipiosByProvinciaOrdered',
            codeProvincia: s!,
        }).then(response => {
            const mcp: Municipio[] = response;
            frm.setFieldsValue({
                ...frm.getFieldsValue, 'valuePoblacion': undefined,
                'valueCodigoPostal': s
            });

            this.setState({
                codigoPostal: s!, provinciaPorCp: this.allProvincias, municipioList: mcp,
                disablePoblacion: false
            });
        });
    }

    private onFileUpload = (file: RcFileEx, groupId: string) => {
        const fileList = [...this.state.fileList];
        file.groupId = groupId;
        // "-1" for new files to upload
        const fid = (-1) * (new Date().getTime());
        file.fileId = fid;
        fileList.push(file);
        getBase64(file, fileUrl => {
            if (!this.props.isNewPrestador) {
                const dl = this.props.downloadDocList;
                const fd: DownloadDocItem = {
                    uid: fid.toString(), size: file.size, name: file.name, type: 'new',
                    status: 'success', fileId: fid, groupId: file.groupId
                };
                dl.push(fd);
                this.props.updateDownloadDocList(dl);
            }
            this.setState({ base64File: fileUrl, fileList, });
        }
        );
        return false;
    }

    private onFileRemove = (file: any) => {
        this.setState({ base64File: undefined, fileList: this.state.fileList.filter(f => f.fileId !== file.fileId) });

        if (!this.props.isNewPrestador) {
            const dl = this.props.downloadDocList;
            const fd: number = dl.findIndex(e => e.fileId === file.fileId);
            if (typeof fd !== 'undefined') {
                dl.splice(fd, 1);
            }
            this.props.updateDownloadDocList(dl);
        }

    }

    private onDownloadDocFile = (info: any) => {

        const file_path: string = URL + '/file?docFileRequest&idFile=' + info.fileId + '&name=' + info.name;
        const a = document.createElement('a');
        a.href = file_path;
        a.click();
        document.body.removeChild(a);
    }

}

const getBase64 = (img: Blob, callback: (result: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
}

export default withTranslation('providerList')(withRouter(ProviderFormContainer));


import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import AppHeader from './AppHeader';

export interface Props {
    logout: () => void;
    changePassword: () => void;
}

class AppContent extends React.Component<Props & WithTranslation> {

    public render() {
        return (
            <>
                <AppHeader
                    logout={this.props.logout}
                    changePassword={this.props.changePassword}
                />
            </>
        );
    }

    
}

export default withTranslation()(AppContent);
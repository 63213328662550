import React, { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProviderAccountList from './ProviderAccountList';
import { Rest, TableUtils } from '../utils/utils';
import { ICuenta, ICuentaLocation, ILicensiasParaCuenta, IPersonalizedEmailData } from './entities/Cuenta';
import ProviderAccountFormContainer from './ProviderAccountFormContainer';
import { FormInstance, notification } from 'antd';
import Provincia from './entities/Provincias';
import FormErrorField from '../utils/form/formErrorField';
import ProviderAccountLicenseForm from './ProviderAccountLicenseForm';
import LocationForm from './LocationForm';
import PersonalizedEmailForm from './PersonalizedEmailForm';
import { compareDesc } from 'date-fns';
import LocationListContainer from './LocationListContainer';
import OperadorData from '../operadores/enteties/operadorData';
import ProviderAccountStatsContainer from './ProviderAccountStatsContainer';
import { URL } from '../utils/rest';

interface IProps {
    limit?: number;
    page?: number;
    addTab?: (tabCaption: string, component: ReactNode) => void;
}

export interface IState {
    accountList?: ICuenta[];
    selectedAccount?: ICuenta;
    licenseList?: ILicensiasParaCuenta[];
    limit: number;
    offset?: number;
    page?: number;
    dataCount?: number;
    tableBodyHeight?: number;
    accountDetailFormVisible: boolean;
    accountStatsVisible: boolean;
    licenseFormVisible: boolean;
    accountDetailFormTitle?: string;
    provincias: Provincia[];
    provinciasLoaded: boolean;
    errorFields: FormErrorField[];
    locationTableVisible: boolean;
    idCuenta?: number;
    nameCuenta?: string;
    personalizedEmailFormVisible: boolean;
    personalizedEmailData?: IPersonalizedEmailData;
    filtersCollapsed?: boolean;
    lastFilter?: any;
    filterKey?: number;
    highlightNameCuenta?: string[];
    locationsData?: ICuentaLocation[];
    locationTableKey?: number;
    locationFormVisible?: boolean;
    locationFormData?: ICuentaLocation;
    nFilters?: number;
    countPrescriptoresTotales?: number;
    countPrescriptoresUltimos30?: number;
    countPrescripcionesUltimos30?: number;
    countPrescripcionesEsteAno?: number;
}

export interface IAccountFormData {
    idCuenta: number;
    nameCuenta: string;
    typePago: number;
    typeIdentificacion: number;
    codeIdentificacion: string;
    idDatosContacto: number;
    codeCodigoPostal: string;
    valueDireccion: string;
    valueDireccion2: string;
    codeProvincia: string;
    codeMunicipio: string;
    valueEmail: string;
    valueTelefono: string;
}

class ProviderAccountListContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {
        limit: 0,
        offset: 0,
        accountDetailFormVisible: false,
        accountStatsVisible: false,
        licenseFormVisible: false,
        provincias: [],
        provinciasLoaded: false,
        errorFields: [],
        locationTableVisible: false,
        locationFormVisible: false,
        personalizedEmailFormVisible: false,
        filtersCollapsed: true,
        nFilters: 0
    };

    public componentDidMount() {
        this.setState(TableUtils.calculatePageSizeForMainTable(), () => this.loadData());
    }


    private loadData = (page?: number) => {
        const values = this.state.lastFilter;
        const filter = {} as any;
    
        console.log('Last Filter at start of loadData:', this.state.lastFilter);
    
        if (values) {
            filter.name = values.nameNombre ? values.nameNombre.split(" ") : undefined;
            filter.nif = values.codeIdentificacion ? values.codeIdentificacion : undefined;
        }
    
        Rest<{ type: string; limit: number; offset: number; name?: string[], nif?: string }, {
            data: ICuenta[],
            dataCount: number,
            page?: number,
        }>().operation({
            type: 'SelectCuentaListPaged',
            limit: this.state.limit,
            offset: (((page || 1) - 1) * this.state.limit),
            name: filter.name,
            nif: filter.nif
        }).then(response => {
    
            const highlightNameCuenta: string[] = [];
            if (values && values.nameNombre) {
                highlightNameCuenta.push(values.nameNombre);
            }
    
            this.setState({
                accountList: response.data.map(e => {
                    e.key = e.idCuenta;
                    return e;
                }).sort((a, b) => compareDesc(new Date(a.dateCreacion), new Date(b.dateCreacion))) || [],
                dataCount: response.dataCount || 0,
                page,
                lastFilter: values,
                highlightNameCuenta, // Si `highlightNameCuenta` debe estar en el estado
            });
        });
    };

    private onClickRRefreshState = () => {
        this.loadData(this.state.page);
    };

    private onChangePage = (page: number) => {
        this.loadData(page);
    };

    private onNewAccountForm = () => {
        //Clear fields
        this.setState({
            accountDetailFormVisible: true, selectedAccount: undefined,
            accountDetailFormTitle: 'createNewAccount',
        });
    };

    private openAccountStats = () => {
        this.setState({

            accountStatsVisible: true,
        });
    };

    private onCancelAccountForm = () => {
        this.setState({ accountDetailFormVisible: false });
    };

    private loadProvinciasInfo = () => {
        Rest<{ type: string }, Provincia[]>().operation({ type: 'SelectProvincia' })
            .then(response => {
                this.setState({ provincias: response, provinciasLoaded: true });
            });
    };

    private onShowDetails = (id: number) => {
        //Get data +
        Rest<{ type: string }, Provincia[]>().operation({ type: 'SelectProvincia' })
            .then(response => {
                let selectedAccount;
                if (this.state.accountList && this.state.accountList.length > 0) {
                    selectedAccount = this.state.accountList.find(e => e.idCuenta === id);
                }

                this.setState({
                    accountDetailFormVisible: true, selectedAccount: selectedAccount,
                    accountDetailFormTitle: 'accountDetailButton',
                    provincias: response, provinciasLoaded: true,
                });
            });


    };

    private onCancelAccountStats = () => {
        this.setState({ accountStatsVisible: false });
    };


    private onSubmitAccountForm = (form: FormInstance) => {
        const value: IAccountFormData = form.getFieldsValue();
        if (this.state.selectedAccount) {
            value.idCuenta = this.state.selectedAccount.idCuenta;
            value.idDatosContacto = this.state.selectedAccount.idDatosContacto;
        }

        Rest<{ type: string, accountData: IAccountFormData }, number>().operation({
            type: 'SavePrestadorAccount',
            accountData: value,
        }).then(() => {
            this.setState({ accountDetailFormVisible: false });
            this.loadData(this.state.page);
        }).catch(() => {

        });

    };

    private loadLicenceData = (idCuenta: number) => {
        Rest<{ type: string, idCuenta: number }, { licenseList: ILicensiasParaCuenta[] }>().operation({
            type: 'GetLicenciasParaCuenta',
            idCuenta: idCuenta,
        }).then(response => {
            let selectedAccount;
            if (this.state.accountList && this.state.accountList.length > 0) {
                selectedAccount = this.state.accountList.find(e => e.idCuenta === idCuenta);
            }
            this.setState({
                licenseFormVisible: true,
                licenseList: response.licenseList.map(e => {
                    e.typeLicenciaName = e.typeLicencia === 1 ? 'Gratuita' : e.typeLicencia === 2 ? 'Pago' : 'Desconocida';
                    e.key = e.idLicencia || 0;
                    return e;
                }),
                selectedAccount: selectedAccount,
            });
        }).catch(reason => {
            console.log('Reason: ', reason);
        });

    };

    private loadLocationsData = (idCuenta: number) => {
        Rest<{ type: string, idCuenta: number }, ICuentaLocation[]>().operation({
            type: 'GetLocationsByCuenta',
            idCuenta: idCuenta,
        }).then(response => {
            let selectedAccount;
            if (this.state.accountList && this.state.accountList.length > 0) {
                selectedAccount = this.state.accountList.find(e => e.idCuenta === idCuenta);
            }

            this.setState({
                locationTableVisible: true,
                idCuenta: idCuenta,

                locationsData: response.map(e => {
                    e.key = e.idLocalizacion || 0;
                    return e;
                }),
                selectedAccount: selectedAccount,
            });
        }).catch(reason => {
            console.log('Reason: ', reason);
        });

    };

    private onClickLicenseItem = (idCuenta: number) => {
        //Rest
        this.loadLicenceData(idCuenta);
    };

    private onUpdateLicenceData = (idCuenta: number) => {
        this.loadLicenceData(idCuenta);
    };

    private onCancelLicenseForm = () => {
        this.setState({ licenseFormVisible: false });
    };

    private onSubmitLicenseForm = () => {

    };

    onCancelLocationForm = () => {
        this.setState({ locationFormVisible: false });

    };

    private showLocationsList = (idCuenta: number) => {
        this.loadLocationsData(idCuenta);
    }

    private getLocationFormData = (idLocation?: number, nameCuenta?: string) => {

        const promises: Array<Promise<any>> = [];
        const rqCuentaLocation = Rest<{ type: string, idLocalizacion: number }, ICuentaLocation>();
        const rqProvincias = Rest<{ type: string }, Provincia[]>();
        if (idLocation != null) {
            promises.push(rqCuentaLocation.operation({ type: 'GetLocationDataSelected', idLocalizacion: idLocation }));
        }

        if (!this.state.provinciasLoaded) {
            promises.push(rqProvincias.operation({ type: 'SelectProvincia' }));
        }

        Promise.all(promises)
            .then(response => {
                if (response[1]) {
                    this.setState({
                        locationFormData: response[0], nameCuenta,
                        locationFormVisible: true, provincias: response[1],
                    });
                } else {

                    this.setState({
                        locationFormData: response[0] as ICuentaLocation, nameCuenta,
                        locationFormVisible: true,
                    });
                }
            }).catch((err) => {
                console.log('Err:', err);
            });

    };

    onSubmitLocationForm = (form: FormInstance) => {
        const location: ICuentaLocation = form.getFieldsValue();
        form.validateFields().then(() => {

            Rest<{ type: string; location: ICuentaLocation }, number>().operation({
                type: 'SaveNewEditLocationCuenta',
                location,
            })
                .then(() => {
                    notification['success']({
                        message: this.props.t('actualizadoLocations'),
                    });
                    this.setState({ locationFormVisible: false });
                    this.loadData(this.state.page);
                    this.loadLocationsData(location.idCuenta!);
                });
        });
    };

    //Personalized email message
    showPersonalizedEmailForm = (idCuenta: number, nameCuenta: string) => {

        Rest<{ type: string; idCuenta: number }, IPersonalizedEmailData>().operation({
            type: 'SelectPersonalizedEmailData', idCuenta,
        })
            .then((response) => {
                this.setState({
                    personalizedEmailFormVisible: true, idCuenta, nameCuenta,
                    personalizedEmailData: response
                });
            });
    };

    //Descargar informe de cuentas (precripciones)
    private downloadInformeCuentas = async (idCuenta: number) => {
        const file_path: string = URL + `/file?informeRequest&idCuenta=${idCuenta}`;
        
        try {
            const response = await fetch(file_path, {
                method: 'GET',
            });
    
            if (!response.ok) {
                throw new Error(`Error al descargar el archivo: ${response.statusText}`);
            }
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.href = url;
            a.download = `informe_cuenta_${idCuenta}.xlsx`; // Nombre del archivo descargado
            document.body.appendChild(a);
            a.click();
    
            // Limpiar la URL generada y el elemento <a>
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error("Error al descargar el archivo:", error);
            notification.error({
                message: 'Error al descargar el archivo',
                description: 'Hubo un problema al intentar descargar el archivo. Por favor, inténtelo de nuevo.',
            });
        }
    };
    
    
    
    


    onCancelPersonalizedEmailForm = () => {
        this.setState({ personalizedEmailFormVisible: false });

    };

    onSubmitPersonalizedEmailForm = (form: FormInstance) => {
        form.validateFields().then(() => {
            const value: IPersonalizedEmailData = form.getFieldsValue();
            if (!value.idPlantilla && !value.usePersonalizedEmail) {
                this.setState({ personalizedEmailFormVisible: false });
                return;
            }

            Rest<{ type: string; value: IPersonalizedEmailData }, number>().operation({
                type: 'SetPersonalizedEmailData',
                value,
            })
                .then(() => {
                    notification['success']({
                        message: this.props.t('providerList:actionPerformed'),
                    });
                    this.setState({ personalizedEmailFormVisible: false });
                    this.loadData(this.state.page);
                });
        });

    };

    private applyFilters = (form: FormInstance) => {
        var nFilters = 0;
        const values = form.getFieldsValue();
        var filters = values as OperadorData;
        if (filters.nameNombre != undefined && filters.nameNombre != "") {
            nFilters = nFilters + 1;
        } if (filters.codeIdentificacion != undefined && filters.codeIdentificacion != "") {
            nFilters = nFilters + 1;
        }
        this.setState({ nFilters: nFilters, lastFilter: values, filtersCollapsed: true }, () => this.loadData(1));
    };

    private resetFilters = () => {
        this.setState({
            page: 1,
            nFilters: 0,
            lastFilter: undefined,
            filterKey: new Date().getTime(),
        }, () => this.loadData(this.state.page));
    };

    private onCollapseFilters = () => {
        this.setState({ filtersCollapsed: !this.state.filtersCollapsed });
    };

    private onClickNewEditLocation = (record?: ICuentaLocation) => {

        this.getLocationFormData(record?.idLocalizacion, record?.nameCuenta);
    }

    private onClickDeleteLocation = (record: ICuentaLocation) => {

        Rest<{ type: string; idLocalizacion: number }, void>().operation({
            type: 'DeleteLocalizacion',
            idLocalizacion: record.idLocalizacion!
        }).then(() => {
            notification['success']({
                message: this.props.t('locationDeleted'),
            });
            this.loadLocationsData(record.idCuenta!);

        });
    }

    private loadExtraInfoCuentas = (idCuenta: number) => {
        this.clearCounters();
        Rest<{ type: string, idcuenta: number }, Array<{ countPrescriptoresTotales: number, countPrescriptoresUltimos30: number, countPrescripcionesUltimos30: number, countPrescripcionesEsteAno: number }>>().operation({
            type: 'SelectExtraInfoCuenta',
            idcuenta: idCuenta,
        }).then(response => {
            // Verifica que la lista tenga al menos un elemento
            if (response.length > 0) {
                const data = response[0]; // Obtén el primer elemento
                this.setState({
                    countPrescripcionesEsteAno: data.countPrescripcionesEsteAno,
                    countPrescripcionesUltimos30: data.countPrescripcionesUltimos30,
                    countPrescriptoresTotales: data.countPrescriptoresTotales,
                    countPrescriptoresUltimos30: data.countPrescriptoresUltimos30,
                });
            } else {
                console.error('La lista está vacía, no se puede procesar la respuesta.');
            }
        }).catch(reason => {
            console.log('Reason: ', reason);
        });
    };
    

    private clearCounters = () => {
        this.setState({
            countPrescripcionesEsteAno: undefined,
            countPrescripcionesUltimos30: undefined,
            countPrescriptoresTotales: undefined,
            countPrescriptoresUltimos30: undefined
        })
    }

    public render() {
        return (
            <>
                <ProviderAccountList
                    {...this.state}
                    onClickRRefreshState={this.onClickRRefreshState}
                    onNewAccountForm={this.onNewAccountForm}
                    openAccountStats={this.openAccountStats}
                    onChangePage={this.onChangePage}
                    onShowDetails={this.onShowDetails}
                    onClickLicenseItem={this.onClickLicenseItem}
                    showLocationsForm={this.showLocationsList}
                    showPersonalizedEmailForm={this.showPersonalizedEmailForm}
                    downloadInformeCuentas={this.downloadInformeCuentas}
                    applyFilters={this.applyFilters}
                    resetFilters={this.resetFilters}
                    onCollapseFilters={this.onCollapseFilters}
                    loadExtraInfoCuentas={this.loadExtraInfoCuentas}
                />
                <ProviderAccountFormContainer
                    {...this.state}
                    onCancel={this.onCancelAccountForm}
                    onSubmit={this.onSubmitAccountForm}
                    loadProvinciasInfo={this.loadProvinciasInfo}
                />
                <ProviderAccountStatsContainer
                    accountStatsVisible={this.state.accountStatsVisible}
                    onCancel={this.onCancelAccountStats}
                />
                <ProviderAccountLicenseForm
                    visible={this.state.licenseFormVisible}
                    licenseList={this.state.licenseList}
                    selectedAccount={this.state.selectedAccount!}
                    onCancel={this.onCancelLicenseForm}
                    onSubmit={this.onSubmitLicenseForm}
                    onUpdateLicenceData={this.onUpdateLicenceData}
                />
                <LocationListContainer
                    visible={this.state.locationTableVisible}
                    key={this.state.locationTableKey}
                    locationList={this.state.locationsData}
                    selectedAccount={this.state.selectedAccount!}
                    onClose={() => { this.setState({ locationTableVisible: false }); }}
                    onClickDeleteLocation={this.onClickDeleteLocation}
                    onClickNewEditLocation={this.onClickNewEditLocation}
                />

                <LocationForm
                    visible={this.state.locationFormVisible!}
                    provinciasLoaded={this.state.provinciasLoaded}
                    locationData={this.state.locationFormData}
                    provincias={this.state.provincias}
                    idCuenta={this.state.idCuenta!}
                    nameCuenta={this.state.nameCuenta!}
                    onCancel={this.onCancelLocationForm}
                    onSubmit={this.onSubmitLocationForm} />

                <PersonalizedEmailForm
                    visible={this.state.personalizedEmailFormVisible}
                    personalizedEmailData={this.state.personalizedEmailData}
                    idCuenta={this.state.idCuenta!}
                    nameCuenta={this.state.nameCuenta!}
                    onCancel={this.onCancelPersonalizedEmailForm}
                    onSubmit={this.onSubmitPersonalizedEmailForm}

                />

            </>
        );

    }


}

export default withTranslation('providerAccountListContainer')(withRouter(ProviderAccountListContainer));

import {Modal} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";
import {stateItem} from "./dialogSharedData";


interface IProps {
    visible: boolean,
    currentState: stateItem,
    nextState: stateItem,
    dialogMessage: string;
    title?: string;
    onCancelConfirmationDialog: () => void,
    onSaveSelectedState: (nextState: stateItem) => void,
}

const StateConfirmDialog = (props: IProps) => {
    const {t} = useTranslation(['providerList']);

    return (
        <Modal
            visible={props.visible}
            destroyOnClose
            title={typeof props.title === 'undefined'? t('confirmarEstadoDialogTitulo') : props.title}
            onCancel={props.onCancelConfirmationDialog}
            onOk={() => props.onSaveSelectedState(props.nextState)}
        >
            <div className='div-centered'>
                <div style={{textAlign: 'left', marginBottom: '1rem'}}
                     dangerouslySetInnerHTML={{__html: props.dialogMessage}}/>
                <FontAwesomeIcon icon={faCircle} style={{
                    color: props.currentState.color,
                    marginRight: '0.5rem'
                }}/> {t(props.currentState.stateName!)}
                <FontAwesomeIcon icon={faLongArrowAltRight} style={{marginRight: '1rem', marginLeft: '1rem'}}/>
                <FontAwesomeIcon icon={faCircle} style={{
                    color: props.nextState.color,
                    marginRight: '0.5rem'
                }}/> {t(props.nextState.stateName!)}
            </div>
        </Modal>
    )
}

export {StateConfirmDialog};

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IState as IStateContainer} from './ProviderAccountFormContainer';
import {Col, Form, FormInstance, Input, Modal, Row, Select} from 'antd';
import {Option} from 'rc-select';
import {ICuenta} from './entities/Cuenta';
import EnumCodeIdentificationType from '../enum/EnumCodeIdentificationType';
import EnumTipoDePago from '../enum/EnumTipoDePago';
import Provincia from './entities/Provincias';
import {FormUtils, getUnaccentText, Rest, validateCif, validateDNI} from '../utils/utils';
import {IAccountFormData} from './ProviderAccountListContainer';
import FormErrorField from '../utils/form/formErrorField';
import Municipio from './entities/municipio';

interface IProps {
    visible: boolean;
    provinciasLoaded?: boolean;
    provincias?: Provincia[];
    formTitle?: string;
    selectedAccount?: ICuenta;
    onCancel: () => void;
    onSubmit: (form: FormInstance) => void;
}


const ProviderAccountForm = (props: IProps & IStateContainer) => {
    const {t} = useTranslation(['providerAccountListContainer']);
    const [form] = Form.useForm();
    const [municipiosPorProvincia, setMunicipiosPorProvincia] = useState<Municipio[]>([]);
    const [errorFields, setErrorFields] = useState<FormErrorField[]>([]);

    const loadMunicipios = (codeProvincia: string) => {
        if (!codeProvincia && props.provincias && props.provincias.length > 0) {
            codeProvincia = props.provincias[0].codeProvincia;
        }

        Rest<{ type: string; codeProvincia: string }, Municipio[]>()
            .operation({
                type: 'SelectMunicipiosByProvinciaOrdered',
                codeProvincia: codeProvincia,
            })
            .then(response => {
                    setMunicipiosPorProvincia(response);
                    if (props.selectedAccount?.codeProvincia && props.selectedAccount.codeMunicipio) {
                        const municipioSelected = response.find(e =>
                            e.codeProvincia === props.selectedAccount?.codeProvincia &&
                            e.codeMunicipio === props.selectedAccount?.codeMunicipio,
                        );
                        if (municipioSelected) {
                            form.setFieldsValue({codeMunicipio: municipioSelected.codeMunicipio});
                        }
                    } else {
                        form.setFieldsValue({
                            codeProvincia: codeProvincia,
                            codeMunicipio: undefined,
                        });
                    }
                },
            );
    };


    const typeDocListItems = [
        {value: EnumCodeIdentificationType.CIF, label: t('codeIdentificationType:' + EnumCodeIdentificationType.CIF)},
        {value: EnumCodeIdentificationType.DNI, label: t('codeIdentificationType:' + EnumCodeIdentificationType.DNI)},
        {value: EnumCodeIdentificationType.NIE, label: t('codeIdentificationType:' + EnumCodeIdentificationType.NIE)},
        {
            value: EnumCodeIdentificationType.PASAPORTE,
            label: t('codeIdentificationType:' + EnumCodeIdentificationType.PASAPORTE),
        },
    ];

    const paymentType = [
        {value: EnumTipoDePago.CONJUNTO, label: t('tipoDePago:' + EnumTipoDePago.CONJUNTO)},
        {value: EnumTipoDePago.INDIVIDUAL, label: t('tipoDePago:' + EnumTipoDePago.INDIVIDUAL)},
    ];


    useEffect(() => {
        if (props.visible) {

            console.log('selectedAccount', props.selectedAccount);

            setErrorFields([]);
            let codeProvincia;
            if (props.selectedAccount) {
                form.setFieldsValue(props.selectedAccount);
                if (props.selectedAccount) {
                    codeProvincia = props.selectedAccount.codeProvincia;
                    if (!codeProvincia) {
                        codeProvincia = props.provincias![0].codeProvincia;
                    }
                } else {
                    codeProvincia = props.provincias![0].codeProvincia;
                }
                loadMunicipios(codeProvincia);
            } else {
                form.resetFields();
                //codeProvincia = props.provincias![0].codeProvincia;
            }


        }


    }, [props.visible]);

    const onSubmit = () => {
        form.validateFields().then(() => {
            if (!hasInvalidFields(form)) {
                props.onSubmit(form);
            }
        });
    };


    const hasInvalidFields = (form: FormInstance): boolean => {
        const fv: IAccountFormData = form.getFieldsValue();
        let hasErrors: boolean = false;
        let errorItems: FormErrorField[] = [];

        if (!fv.codeIdentificacion || fv.codeIdentificacion.length < 9) {
            errorItems.push({errorMessage: '', fieldName: 'codeIdentificacion'});
            hasErrors = true;
        }

        if (fv.typeIdentificacion === EnumCodeIdentificationType.CIF) {
            if (!validateCif(fv.codeIdentificacion)) {
                errorItems.push({errorMessage: t('incorrectCif'), fieldName: 'codeIdentificacion'});
                hasErrors = true;
            }
        } else //Pasaporte/DNI/NIE value check
        if (fv.typeIdentificacion === EnumCodeIdentificationType.PASAPORTE) {
            if (fv.codeIdentificacion.toString().length < 4) {
                errorItems.push({errorMessage: t('invalidPasaporteNumero'), fieldName: 'codeIdentificacion'});
                hasErrors = true;
            }
        } else if (!validateDNI(fv.codeIdentificacion,
            fv.typeIdentificacion.toString() === EnumCodeIdentificationType.NIE.toString())) { //DNI/NIE value check
            errorItems.push({errorMessage: t('incorrectNif'), fieldName: 'codeIdentificacion'});
            hasErrors = true;
        }

        setErrorFields(errorItems);

        return hasErrors;
    };

    const onCodigoPostalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedCodeProvincia = event.target.value;

        if (!selectedCodeProvincia || selectedCodeProvincia.length < 2) {
            form.setFieldsValue({codeProvincia: undefined, codeMunicipio: undefined});
            setMunicipiosPorProvincia([]);
            return;
        }

        const codeProvincia = selectedCodeProvincia.substring(0, 2);
        form.setFieldsValue({codeProvincia: codeProvincia});
        if(selectedCodeProvincia.length === 2) {
            loadMunicipios(codeProvincia);
        }
    };

    const onProvinciaSelect = (value: any) => {
        let codeProvincia = value.toString().substring(0, 2);
        form.setFieldsValue({codeCodigoPostal: codeProvincia});
        loadMunicipios(value.toString());
    };

    return (

        <Modal
            title={props.formTitle ? t(props.formTitle) : ''}
            visible={props.visible || false}
            style={{top: 40}}
            destroyOnClose
            width={800}
            // footer={null}
            // onCancel={props.onCancel}
            cancelText={t('buttons:cancel')}
            okText={t('buttons:save')}
            onCancel={props.onCancel}
            onOk={onSubmit}

        >
            <Form layout='vertical' form={form} size='large'>
                <Row gutter={8} style={{display: 'flex', alignItems: 'center'}}>
                    <Col span={12}>
                        <Form.Item label={t('nameCuenta')} name='nameCuenta' rules={[{required: true}]}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={8}>
                        <Form.Item label={t('paymentType')} name='typePago' rules={[{required: true}]}>
                            <Select>
                                {paymentType.map(e => {
                                    return (
                                        <Option key={e.value} value={e.value} label={e.label}>
                                            {e.label}
                                        </Option>
                                    );
                                })}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('welcome:codeIdentificationLabel')} name='typeIdentificacion'
                                   rules={[{required: true},


                                   ]}>
                            <Select className='doc-identidad'>
                                {typeDocListItems.map(e => {
                                    return (
                                        <Option key={e.value} value={e.value} label={e.label}>
                                            {e.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='№ de documento' name='codeIdentificacion' rules={[{required: true}]}
                                   validateStatus={FormUtils.isError('codeIdentificacion', errorFields) ? 'error' : undefined}
                                   help={FormUtils.getErrorMessage('codeIdentificacion', errorFields)}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item label={t('direccion')} name='valueDireccion' rules={[{required: true}]}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('detalleDireccion')} name='valueDireccion2'>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={8}>
                        <Form.Item label={t('codeCodigoPostal')} name='codeCodigoPostal' rules={[{required: true}]}>
                            <Input maxLength={5} onChange={onCodigoPostalChange}
                                   placeholder={t('providerList:introduceCodigoPostal')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('provincia')} name='codeProvincia' rules={[{required: true}]}>
                            <Select className='doc-identidad'
                                    showSearch notFoundContent={false}
                                    placeholder={t('providerList:selectProvincia')}
                                    onSelect={onProvinciaSelect}
                                    filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                            >
                                {props.provincias!.map(e => {
                                    return (
                                        <Option key={e.codeProvincia} value={e.codeProvincia}
                                                label={getUnaccentText(e.nameProvincia)}>
                                            {e.nameProvincia}
                                        </Option>
                                    );
                                })}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t('poblacion')} name='codeMunicipio' rules={[{required: true}]}>
                            <Select className='doc-identidad'
                                    disabled={municipiosPorProvincia.length === 0}
                                    showSearch notFoundContent={false}
                                    placeholder={t('providerList:selectPoblacion')}
                                    filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                            >
                                {municipiosPorProvincia!.map(e => {
                                    return (
                                        <Option key={e.codeMunicipio} value={e.codeMunicipio} label={getUnaccentText(e.nameMunicipio)}>
                                            {e.nameMunicipio}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item label={t('email')} name='valueEmail' rules={[{required: true}]}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('telefono')} name='valueTelefono' rules={[{required: true}]}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ProviderAccountForm;

import React, { useState } from 'react';
import { IState as IStateContainer } from './OperadoresListContenedor';
import { Badge, Button, Drawer, Dropdown, FormInstance, Menu, Space, Table, Tooltip } from 'antd';
import { Functions, TableIcons } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBirthdayCake,
    faCircle,
    faEnvelope,
    faPhone,
    faUserPlus, faVenusMars
} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css';
import { useTranslation } from 'react-i18next';
import { AlignType } from 'rc-table/lib/interface';
import Highlighter from 'react-highlight-words';
import { OPERADOR_STATE_NAMES, stateColors, stateNames, stateStyle } from './OperadorSharedData';
import OperadorData from './enteties/operadorData';
import { EnterOutlined } from '@ant-design/icons';
import OperadorFilters from './OperadorFilters';
import { stateItem } from '../dialogs/dialogSharedData';
import { StateConfirmDialog } from '../dialogs/StateConfirmDialog';

interface IProps {
    filtersCollapsed?: boolean;
    onCollapseFilters: () => void;
    onClickRRefreshState: () => void;
    onClickNewOperador: () => void;
    onChangePage: (page: number) => void;
    onShowDetails: (evt: any) => void;
    onConfirmChangeOperadorState: (changeOperadorState: { id: number; nextState: number }) => void;
    searchOperadorName: (value: string) => void;
    applyFilters: (form: FormInstance) => void;
    resetFilters: () => void;
    onReenviarEnlaceDeRegistro: (record: OperadorData) => void;
    onDesbloquearOperador: (record: OperadorData) => void;
}

const OperadoresList = (props: IProps & IStateContainer) => {

    const { t } = useTranslation(['usuariosOperadoresList']);

    const iconStateRenderer = (value: number) => {
        return <div style={stateStyle}>
            <FontAwesomeIcon icon={faCircle}
                style={{ color: stateColors[value], marginRight: '0.5rem' }} /> {t(stateNames[value])} </div>;
    }

    const onCancelConfirmationDialog = () => {
        setDialogState(prevState => ({ ...prevState, visible: false }));
    }

    const onSaveSelectedState = () => {
        setDialogState(prevState => ({ ...prevState, visible: false }));
        props.onConfirmChangeOperadorState({ id: menuState.idOperador, nextState: dialogState.nextState.stateId! });
    }

    const [menuState, setMenuState] = useState(
        { stateItemVisible: false, menuStateKey: 0, idOperador: 0 }
    );

    const [dialogState, setDialogState] = useState<{
        visible: boolean; currentState: stateItem; nextState: stateItem; dialogMessage: string;
    }>({ visible: false, currentState: {}, nextState: {}, dialogMessage: '' });

    const actionRenderer = (value: number, record: OperadorData) => {
        const onClick = (e: any) => {
            setMenuState({ stateItemVisible: true, menuStateKey: new Date().getTime(), idOperador: record.idOperador! });
            switch (e.key) {
                case 'AC':
                    setDialogState({
                        visible: true, currentState: { stateName: stateNames[OPERADOR_STATE_NAMES.ACTIVE], stateId: OPERADOR_STATE_NAMES.ACTIVE, color: stateColors[OPERADOR_STATE_NAMES.ACTIVE] },
                        nextState: { stateName: stateNames[OPERADOR_STATE_NAMES.INACTIVE], stateId: OPERADOR_STATE_NAMES.INACTIVE, color: stateColors[OPERADOR_STATE_NAMES.INACTIVE] },
                        dialogMessage: t('desactivarMessage', { operadorNombre: record.nameCompleto })
                    });
                    break;
                case 'DA':
                    setDialogState({
                        visible: true, currentState: { stateName: stateNames[OPERADOR_STATE_NAMES.INACTIVE], stateId: OPERADOR_STATE_NAMES.INACTIVE, color: stateColors[OPERADOR_STATE_NAMES.INACTIVE] },
                        nextState: { stateName: stateNames[OPERADOR_STATE_NAMES.ACTIVE], stateId: OPERADOR_STATE_NAMES.ACTIVE, color: stateColors[OPERADOR_STATE_NAMES.ACTIVE] },
                        dialogMessage: t('activarMessage', { operadorNombre: record.nameCompleto })
                    });
                    break;
                case 'AV':
                    props.onReenviarEnlaceDeRegistro(record);
                    break;
                case 'DB':
                    props.onDesbloquearOperador(record);
                    break;
            }
        }

        const extraDropdownMenu = (
            <Menu
                onClick={onClick}
            >
                {(record.allowedStateFlags?.flagAccionResendActivation) &&
                    <Menu.Item key='AV'>
                        {t('providerList:reenviarEnlaceDeRegistro')}
                    </Menu.Item>
                }

                {(record.allowedStateFlags?.flagAccionDesactivar) &&
                    <Menu.Item key='AC'>
                        {t('providerList:desactivar')}
                    </Menu.Item>
                }

                {(record.allowedStateFlags?.flagAccionActivar) &&
                    <Menu.Item key='DA'>
                        {t('providerList:activar')}
                    </Menu.Item>
                }
                {(record.allowedStateFlags?.flagDesbloquear) &&
                    <Menu.Item key='DB'>
                        {t('providerList:desbloquear')}
                    </Menu.Item>
                }

            </Menu>
        )

        return (
            <Space size='small'>
                <Tooltip title={t('operadorDetailButton')} placement='left'>
                    <Button type='default' onClick={() => props.onShowDetails(record.idOperador)}>
                        {t('buttons:details')}
                    </Button>
                </Tooltip>
                <Tooltip title={t('extraMenu')} placement='left'>
                    <Dropdown overlay={extraDropdownMenu} trigger={['click']}>
                        <Button type='primary' icon={TableIcons.getTableIcon(TableIcons.TableIcon.more)} />
                    </Dropdown>
                </Tooltip>
            </Space>
        )
    }

    const sexoOficial = (id?: number): string => {
        switch (id) {
            case 1:
                return 'Hombre';
            case 2:
                return 'Mujer';
            default:
                return 'Otro';
        }
    }

    const Details = (rec: OperadorData) => {
        return (
            <>
                <Space>
                    <EnterOutlined className='table-expandable-detail__arrow' />
                    {rec.dateNacimiento &&
                        <div className='table-expandable-detail'><FontAwesomeIcon icon={faBirthdayCake} />
                            {Functions.dateToString(rec.dateNacimiento)}</div>}
                    {rec.typeSexo && <div className='table-expandable-detail'><FontAwesomeIcon
                        icon={faVenusMars} /> {sexoOficial(rec.typeSexo)}</div>}
                    {rec.valueEmail &&
                        <div className='table-expandable-detail'><FontAwesomeIcon icon={faEnvelope} /> {rec.valueEmail}
                        </div>}
                    {rec.valueTelefono &&
                        <div className='table-expandable-detail'><FontAwesomeIcon icon={faPhone} /> {rec.valueTelefono}
                        </div>}
                </Space>
            </>
        )
    }

    const columns = [
        {
            title: t('operadorNombreColumnTitle'),
            dataIndex: 'nameCompleto',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '18rem',
            render: (operadorNameCompleto: string) => {
                return <b>{props.highlightOperadorName ?
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={props.highlightOperadorName}
                        autoEscape
                        textToHighlight={operadorNameCompleto}
                    />
                    : operadorNameCompleto}</b>
            }
        },
        {
            title: t('nifColumnTitle'),
            dataIndex: 'codeIdentificacion',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '8rem'
        },
        {
            title: t('estadoColumnTitle'),
            dataIndex: 'typeEstado',
            ellipsis: true,
            align: 'left' as AlignType,
            render: iconStateRenderer
        },
        { title: t('altaPorColumnTitle'), dataIndex: 'altaPor', ellipsis: true, align: 'left' as AlignType },

        {
            title: t('activadoColumnTitle'),
            dataIndex: 'dateActivacion',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '8rem',
            render: (dt: Date) => {
                return dt && Functions.dateToString(dt);
            }
        },
        {
            title: t('creacionColumnTitle'),
            dataIndex: 'dateCreacion',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '8rem',
            render: (dt: Date) => {
                return dt && Functions.dateToString(dt);
            }
        },

        { dataIndex: 'idOperador', align: 'center' as AlignType, render: actionRenderer, width: '9rem' }

    ];


    return (
        <>
            <Drawer className='filters-drawer' visible={!props.filtersCollapsed}
                onClose={props.onCollapseFilters}
                width='300px' placement='left' getContainer={false}>
                {<OperadorFilters
                    {...props}
                    key={props.filterKey}
                />}
            </Drawer>

            <Space size='small' className='table-button-bar'>

                <Badge className='filter-badge' count={props.nFilters}>
                    <Button type={props.filtersCollapsed ? 'primary' : 'default'}
                        icon={TableIcons.getTableIcon(TableIcons.TableIcon.filter)}
                        onClick={props.onCollapseFilters} />
                </Badge>
                <Button type={'primary'} icon={TableIcons.getTableIcon(TableIcons.TableIcon.sync)}
                    onClick={() => props.onClickRRefreshState()} />
                <Button icon={<FontAwesomeIcon icon={faUserPlus} style={TableIcons.PRIMARY_BUTTON_ICON_STYLE} />}
                    onClick={() => props.onClickNewOperador()}>
                    {t('nuevoOperador')}
                </Button>
            </Space>
            <div className='table-container'>
                <Table className='table__custom-expandable-icon'
                    scroll={{ y: props.tableBodyHeight }}
                    columns={columns}
                    size='small'
                    expandable={{
                        expandedRowRender: record => <Details {...record} />,
                        expandIconColumnIndex: 6,
                        expandIcon: ({ expanded, onExpand, record }) =>
                            <Button type={expanded ? 'primary' : 'default'}
                                icon={expanded ? TableIcons.getTableIcon(TableIcons.TableIcon.chevronUp) :
                                    TableIcons.getTableIcon(TableIcons.TableIcon.chevronDown)}
                                onClick={e => onExpand(record, e)} />
                    }}
                    dataSource={props.operadorData}
                    pagination={{
                        position: ['bottomRight'],
                        hideOnSinglePage: true,
                        showSizeChanger: false,
                        current: props.page,
                        pageSize: props.limit,
                        total: props.dataCount,
                        onChange: props.onChangePage
                    }}
                />
            </div>
            <StateConfirmDialog
                visible={dialogState.visible}
                key={new Date().getTime()}
                currentState={dialogState.currentState}
                nextState={dialogState.nextState}
                dialogMessage={dialogState.dialogMessage}
                onCancelConfirmationDialog={onCancelConfirmationDialog}
                onSaveSelectedState={onSaveSelectedState}
            />
        </>
    );
}

export default OperadoresList;

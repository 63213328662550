import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Modal, notification, Popover, Space, Table} from 'antd';
import {ICuenta, ILicensiasParaCuenta} from './entities/Cuenta';
import {useTranslation} from 'react-i18next';
import {AlignType} from 'rc-table/lib/interface';
import {Rest, TableIcons} from '../utils/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faMoneyCheck} from '@fortawesome/free-solid-svg-icons';
import moment, {Moment} from 'moment';
import {RangePickerProps} from 'antd/es/date-picker';

const LICENCIA_GRATUITA = 1;
const LICENCIA_PAGADO = 2;

export interface ILicensiasParaCuentaEx extends ILicensiasParaCuenta {
    dateHastaStr: string;
    isValid: boolean;
}

interface IProps {
    visible: boolean;
    licenseList?: ILicensiasParaCuenta[];
    selectedAccount: ICuenta;
    onCancel: () => void;
    onSubmit: () => void;
    onUpdateLicenceData: (idCuenta: number) => void;
}

const ProviderAccountLicenseForm = (props: IProps) => {

    const {t} = useTranslation(['providerAccountListContainer']);
    const [formTitle, setFormTitle] = useState('');
    const [licenseList, setLicenseList] = useState<ILicensiasParaCuentaEx[]>();
    const [popopverVisible, setPopoverVisible] = useState(false);
    const [dataPickerValue, setDataPickerValue] = useState(moment().add(1, 'day'));


    useEffect(() => {
        if (props.visible) {
            setLicenseList(props.licenseList?.map(e => {
                    let isValid = e.dateHasta > new Date();
                    return {...e, dateHastaStr: moment(e.dateHasta).format('DD/MM/yyyy'), isValid};
                }),
            );
            setFormTitle(t('accountLicense', {nameCuenta: props.selectedAccount ? props.selectedAccount.nameCuenta : ''}));
            setPopoverVisible(false);
        }

    }, [props.visible, props.licenseList]);

    const actionRenderer = (value: number, record: ILicensiasParaCuenta) => {
        return (
            <Space size='small' style={{display: 'flex'}} key={record.idLicencia} >
                <FontAwesomeIcon icon={faCircle} style={{
                    marginRight: '0.5rem',
                    color: value ? '#8EAD38' : '#FF4D4F',
                }}/>
                {value ? t('activa') : t('caducada')}
            </Space>
        );
    };


    const columns = [
        {
            title: t('idCuenta'),
            dataIndex: 'idLicencia',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '6rem',
        },
        {
            title: t('typeLicencia'),
            dataIndex: 'typeLicenciaName',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '8rem',
        },
        {
            title: t('Fecha hasta'),
            dataIndex: 'dateHastaStr',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '10rem',
        },
        {
            title: t('idPago'),
            dataIndex: 'idPago',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '10rem',
        },
        {
            title: t('isValid'),
            ellipsis: true,
            dataIndex: 'isValid',
            align: 'left' as AlignType,
            render: actionRenderer,
            width: '9rem',
        },
    ];

    const onNewLicenciaGratuida = () => {
        setPopoverVisible(true);
    };

    const saveNewLicenciaGratuida = () => {
        Rest<{
            type: string, value: {
                idCuenta: number, dateHasta: Date,
                typeLicencia: number, idPago: string,
            }
        }, number>().operation({
            type: 'InsertCuentaLicencia', value: {
                idCuenta: props.selectedAccount.idCuenta, dateHasta: dataPickerValue.toDate(),
                typeLicencia: LICENCIA_GRATUITA, idPago: 'Gratis',
            },
        }).then(() => {
            props.onUpdateLicenceData(props.selectedAccount.idCuenta);
            setPopoverVisible(false);
        }).catch(reason => {
            console.log('Reason:', reason);
            notification['error']({
                message: t('insertLicenseFailed')
            });
        });
    };

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };

    const onSelectDPValue = (value: Moment) => {
        setDataPickerValue(value);
    };

    return (
        <>
            <Modal
                title={formTitle}
                visible={props.visible || false}
                style={{top: 40}}
                destroyOnClose
                width={800}
                footer={null}
                onCancel={props.onCancel}
            >
                <Space size='small' className='table-button-bar'>
                    <Popover
                        placement='bottom'
                        content={
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{display: 'flex', marginBottom: '0.5rem'}}>
                                    <DatePicker
                                        disabledDate={disabledDate}
                                        defaultValue={dataPickerValue}
                                        onSelect={onSelectDPValue}
                                        format={'DD/MM/yyyy'}/>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <Button onClick={() => setPopoverVisible(false)} style={{marginRight: '1rem'}}>
                                        {t('buttons:cancel')}
                                    </Button>

                                    <Button onClick={saveNewLicenciaGratuida} type='primary'>
                                        {t('buttons:save')}
                                    </Button>
                                </div>

                            </div>
                        } trigger='click'
                        visible={popopverVisible}
                    >

                        <Button
                            icon={<FontAwesomeIcon icon={faMoneyCheck} style={TableIcons.PRIMARY_BUTTON_ICON_STYLE}/>}
                            onClick={() => onNewLicenciaGratuida()}
                        >
                            {t('newFreeLicense')}
                        </Button>
                    </Popover>
                </Space>


                <div className='table-container'>
                    <Table className='table__custom-expandable-icon'
                           columns={columns}
                           size='small'
                           dataSource={licenseList}
                           pagination={{
                               position: ['bottomRight'],
                               hideOnSinglePage: true,
                               showSizeChanger: false,
                           }}
                    />
                </div>

            </Modal>

        </>
    );

};

export default ProviderAccountLicenseForm;
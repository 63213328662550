import React from 'react';
import { ICuenta } from './entities/Cuenta';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ProviderAccountStats from './ProviderAccountStats';
import { FormInstance } from 'antd';
import Provincia from './entities/Provincias';
import { Rest } from '../utils/utils';

interface IProps {
    accountStatsVisible: boolean;
    tableBodyHeight?: number;
    onCancel: () => void;
}

export interface IState {
    listStats: Array<{
        nameCuenta: string,
        countPrescriptoresTotales: number;
        countPrescriptoresUltimos30: number;
        countPrescripcionesUltimos30: number;
        countPrescripcionesEsteAno: number;
    }>;
}


class ProviderAccountStatsContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState & { filterEnabled: boolean }> {

    public state = {
        listStats: [],
        filterEnabled: false, // Estado para manejar el filtro
    };

    onCancel = () => {
        this.props.onCancel();
    };

    private toggleFilter = () => {
        this.setState(
            (prevState) => ({ filterEnabled: !prevState.filterEnabled }),
            () => {
                if (this.state.filterEnabled) {
                    this.filtrarListaPagoExterno();
                } else {
                    this.loadListStats();
                }
            }
        );
    };

    private filtrarListaPagoExterno = () => {
        Rest<{ type: string, typepago: number }, Array<{
            nameCuenta: string,
            countPrescriptoresTotales: number,
            countPrescriptoresUltimos30: number,
            countPrescripcionesUltimos30: number,
            countPrescripcionesEsteAno: number
        }>>().operation({
            type: 'SelectExtraInfoCuenta',
            typepago: 1,
        }).then(response => {
            if (response.length > 0) {
                this.setState({ listStats: response });
                console.log('Filtrado:', response);
            } else {
                console.error('La lista filtrada está vacía.');
            }
        }).catch(reason => {
            console.log('Error en el filtrado:', reason);
        });
    };

    private loadListStats = () => {
        Rest<{ type: string }, Array<{
            nameCuenta: string,
            countPrescriptoresTotales: number,
            countPrescriptoresUltimos30: number,
            countPrescripcionesUltimos30: number,
            countPrescripcionesEsteAno: number
        }>>().operation({
            type: 'SelectExtraInfoCuenta'
        }).then(response => {
            if (response.length > 0) {
                this.setState({ listStats: response });
                console.log('Lista completa:', response);
            } else {
                console.error('La lista completa está vacía.');
            }
        }).catch(reason => {
            console.log('Error en la carga de lista:', reason);
        });
    };

    componentDidMount() {
        this.loadListStats();
    }

    render() {
        return (
            <>
                <ProviderAccountStats
                    onCancel={this.onCancel}
                    visible={this.props.accountStatsVisible}
                    data={this.state.listStats}
                    filtrarListaPagoExterno={this.toggleFilter} // Enlazar con toggleFilter
                />
            </>
        );
    }
}
export default withTranslation('providerAccountListContainer')(withRouter(ProviderAccountStatsContainer));
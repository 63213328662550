import React, {ReactNode} from 'react';

import {Layout} from 'antd';
import {Rest} from './utils/utils';
import {MenuInfo} from 'rc-menu/lib/interface';
import {withTranslation, WithTranslation} from 'react-i18next';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import AppMenu from './layout/AppMenu';
import AppHeader from './layout/AppHeaderContainer';
import AppContent from './layout/AppContent';
import MenuConfig from './layout/menuConfig';
import ChangePasswordFormContainer from './changePassword/ChangePasswordFormContainer';
import PasswordAbouExpireModal from './changePassword/PasswordAbouExpireModal';
import EnumModuloType from './enum/EnumModuloType';
import MailsPrueba from './developers/mailsPrueba';
import MailsPruebaContainer from './developers/mailsPruebaContainer';
import CreadorPlantillasMail from './developers/creadorPlantillasMail';
import CreadorPlantillasMailContainer from './developers/creadorPlantillasMailContainer';

export interface TabInfo {
    title: string;
    closable?: boolean;
    component: ReactNode;
}

interface State {
    tabs: TabInfo[];
    activeTabIndex: number;
    configDrawerVisible?: boolean;
    menuCollapsed: boolean;
    changePasswordVisible?: boolean;
    changePasswordFormKey?: number;
    passwordAbouExpireVisible?: boolean;
    passwordAbouExpireVisibleFormKey?: number;
    requiredChangePass?: boolean;
    isOperadorMenuVisible?: boolean;
    visibleMailsPrueba?: boolean;
    visibleMailsPruebaKey?: number;
    visibleCreadorPlantillas?: boolean;
    visibleCreadorPlantillasKey?: number;
}

class App extends React.Component<WithTranslation & RouteComponentProps, State> {

    public state: State = {
        tabs: [],
        activeTabIndex: -1,
        menuCollapsed: true,
    };

    private t = this.props.t;

    public componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {

    }

    public render() {

        return (
            <>
                <Layout style={{minHeight: '100vh'}}>
                    <Layout.Header className='header-app'>
                        <AppHeader
                            logout={this.logout}
                            changePassword={this.changePassword}
                        />
                    </Layout.Header>
                    <Layout className='content-app'>
                        <Layout.Sider
                            collapsible
                            collapsed={this.state.menuCollapsed}
                            onCollapse={this.toogleMenuCollapse}
                            trigger={null}>
                            <AppMenu
                                onMenuOptionSelect={this.onMenuOptionSelect}
                                isOperadorMenuVisible={this.state.isOperadorMenuVisible}
                                openMailsPrueba={this.openMailsPrueba}
                                openCreadorPlantillasMail={this.openCreadorPlantillasMail}

                            />
                        </Layout.Sider>
                        <Layout.Content className='main-layout'>
                            <AppContent tabs={this.state.tabs}
                                        activeTabIndex={this.state.activeTabIndex}
                                        onTabsChange={this.onTabsChange}
                                        onActiveTabChange={this.onActiveTabChange}
                                        onCloseTab={this.closeTab}/>
                        </Layout.Content>
                    </Layout>
                </Layout>

                {this.changePasswordFormModal()}
                {this.passwordAbouExpireFormModal()}
                {<MailsPruebaContainer 
                    visible={this.state.visibleMailsPrueba}
                    key={this.state.visibleMailsPruebaKey}
                    onClose={() => this.setState({visibleMailsPrueba:false})}
                />}
                {<CreadorPlantillasMailContainer
                    visible={this.state.visibleCreadorPlantillas}
                    key={this.state.visibleCreadorPlantillasKey}
                    onClose={() => this.setState({visibleCreadorPlantillas:false})}
                />}
            </>
        );
    }

    private loadData = () => {
        const promises: Array<Promise<any>> = [];
        const req1 = Rest<{ type: string }, any>();
        const req2 = Rest<{ type: string }, boolean>();
        promises.push(req1.operation({type: 'GetchangePasswordLastUpdate'}));
        promises.push(req2.operation({type: 'IsSuperAdmin'})); // Show/Hide the operador button

        Promise.all(promises).then(response => {
                if (response[0].passwordChangeAdvised) {
                    this.setState({
                        passwordAbouExpireVisible: true,
                        passwordAbouExpireVisibleFormKey: new Date().getTime()
                    });
                } else if (response[0].passwordChangeRequired) {
                    this.setState({
                        changePasswordVisible: true,
                        requiredChangePass: true,
                        changePasswordFormKey: new Date().getTime()
                    });
                }
                this.setState({isOperadorMenuVisible: response[1]});
            }
        ).catch(() => {
            this.setState({isOperadorMenuVisible: false});
        });
    }

    private changePasswordFormModal = () => {
        return (
            <ChangePasswordFormContainer
                key={this.state.changePasswordFormKey} // Force form regeneration
                requiredChangePass={this.state.requiredChangePass}
                visible={this.state.changePasswordVisible}
                onClose={this.onCloseFormChangePassword}
            />
        );
    }

    private passwordAbouExpireFormModal = () => {
        return (
            <PasswordAbouExpireModal
                key={this.state.passwordAbouExpireVisibleFormKey} // Force form regeneration
                visible={this.state.passwordAbouExpireVisible}
                onClose={this.onCloseFormPasswordAbouExpire}
                onChange={this.onChange}
            />
        );
    }

    private onCloseFormPasswordAbouExpire = () => {
        this.setState({passwordAbouExpireVisible: false});
    }

    private onChange = () => {
        this.onCloseFormPasswordAbouExpire();
        this.changePassword();
    }

    private onMenuOptionSelect = (info: MenuInfo) => {

        let tabCaption = MenuConfig.getConfigById(info.key.toString()).tabCaption;
        tabCaption = this.t(tabCaption);

        const component = MenuConfig.getComponentById(info.key.toString(), this.addTab);
        this.addTab(tabCaption, component)
    }

    private closeTab = (tabToCloseIndex: number) => {
        const newActive =
            this.state.activeTabIndex >= this.state.tabs.length - 1
                ? this.state.activeTabIndex - 1
                : this.state.activeTabIndex;
        const newTabs = this.state.tabs.filter(
            (tab, index) => index !== tabToCloseIndex,
        );

        this.setState({activeTabIndex: newActive, tabs: newTabs});
    };

    private onTabsChange = (tabs: TabInfo[]) => {
        this.setState({tabs});
    }

    private onActiveTabChange = (activeTabIndex: number) => {
        this.setState({activeTabIndex});
    }

    private addTab = (tabCaption: string, component: ReactNode) => {
        const existingTabIndex = this.state.tabs.findIndex(t => t.title === tabCaption);
        if (existingTabIndex !== -1) {
            this.setState({activeTabIndex: existingTabIndex});
        } else {
            let tabs = this.state.tabs.slice(0);
            tabs.push({title: tabCaption, component: component, closable: true});
            const activeTabIndex = tabs.length - 1;
            this.setState({tabs: tabs, activeTabIndex});
        }
    }

    private logout = () => {
        Rest<{ type: string, typeModulo: number }, {}>().operation({
            type: 'Logout',
            typeModulo: EnumModuloType.MODULO_ADMINISTRACION
        })
            .then(() => {
                this.props.history.push('/');
            });
    }

    private toogleMenuCollapse = () => {
        this.setState({menuCollapsed: !this.state.menuCollapsed});
    }

    private changePassword = () => {
        this.setState({changePasswordVisible: true, changePasswordFormKey: new Date().getTime()});
    }

    private onCloseFormChangePassword = () => {
        if (this.state.requiredChangePass) {
            this.logout();
        } else {
            this.setState({changePasswordVisible: false, requiredChangePass: false});
        }
    }

    private openMailsPrueba = () => {
        this.setState({visibleMailsPrueba:true, visibleMailsPruebaKey:new Date().getTime()})
    }

    private openCreadorPlantillasMail = () => {
        this.setState({visibleCreadorPlantillas:true, visibleCreadorPlantillasKey:new Date().getTime()})
    }
}

export default withTranslation(['menu', 'config'])(withRouter(App));

import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import LOGOERECETA from '../images/logo.png';
import cx from 'classnames';
import { Button } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class RecoverPasswordCorrect extends React.Component<WithTranslation & RouteComponentProps> {

    public render() {
        return (
            <div className='enlace_token_container'>
                <div className='enlace_token_card_container'>
                    <div className='enlace_token_card' style={{width: '484px'}}>
                        <div className='enlace_token_logo'><img src={LOGOERECETA}  alt=''/></div>
                        <div className='enlace_token_title'>{this.props.t('recoverPasswordCompleted')}</div>
                        <div className={cx('enlace_token_prestador', {'welcome': true})}>{this.props.t('readyText')}</div>
    
                        <div className='alta-buttons'>
                            <Button type='primary' size='middle' onClick={this.goLogin}>
                                {this.props.t('goLoginButton')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    private goLogin = () => {
        this.props.history.push('/login');
    }
}

export default withTranslation('recoverPassword')(withRouter(RecoverPasswordCorrect));
import { FormInstance } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import FormErrorField from '../utils/form/formErrorField';
import { FormUtils, Rest } from '../utils/utils';
import View from './RecoverPassword';

export interface IState {
    errorFields: FormErrorField[];
}

class RecoverPasswordContainer extends React.Component<WithTranslation & RouteComponentProps<{ token: string }>, IState> {

    public token: string = this.props.match.params.token;

    public state: IState = {
        errorFields: []
    };

    public render() {
        return (
            <View
                errorFields={this.state.errorFields}
                onSubmit={this.submit} />
        )
    }

    private submit = (form: FormInstance) => {
        this.setState({errorFields: []}, () => {
            this.formValidation(form);
        });
    }

    private formValidation = (form: FormInstance) => {
        form.validateFields().then(values => {
            this.internalValidation(values, true);
        }).catch(info => {
            this.internalValidation(info.values, false);
        });
    }
    
    private internalValidation = (values: Store, formValidationSucceded: boolean) => {
        this.validatePassword(values)?.then(errors => {

            if (formValidationSucceded && (!errors || errors.length === 0)) {
                this.setNewPassword(values);
            } else {
                this.setState({errorFields: errors});
            }
        });
    }

    private validatePassword = (values: Store) => {

        return new Promise((resolve: (f: FormErrorField[]) => void) => {
            Rest<{type: string, token: string,newPassword: string, confirmNewPassword: string}, any>().operation({
                type: 'ValidateChangePasswordByToken',
                token: this.token,
                newPassword: values.newPassword,
                confirmNewPassword: values.confirmNewPassword})
            .then(result => {
                let errors: FormErrorField[] = [];

                if (result && result.incorrectPasswordSecurity) {
                    const passError = {fieldName: 'newPassword', 
                        errorMessage: this.props.t('changePasswordForm:incorrectPasswordSecurity')};

                    errors = FormUtils.addError(errors, passError);
                }

                if (result && result.incorrectPasswordConfirmation) {
                    const passError = {fieldName: 'confirmNewPassword', 
                        errorMessage: this.props.t('changePasswordForm:incorrectPasswordConfirmation')};

                    errors = FormUtils.addError(errors, passError);
                }

                if (result && result.incorrectPasswordUsedOne) {
                    const passError = {fieldName: 'newPassword', 
                    errorMessage: this.props.t('changePasswordForm:incorrectPasswordUsedOne')};

                errors = FormUtils.addError(errors, passError);
                }

                resolve(errors);
            });
        });
    }

    private setNewPassword = (values: Store) => {
        Rest<{type: string, token: string, newPassword: string, confirmNewPassword: string}, string>()
        .operation({
            type: 'SetNewPassword',
            token: this.token,
            newPassword: values.newPassword,
            confirmNewPassword: values.confirmNewPassword
        }).then(response => {
            this.props.history.push(response);
        });
    }
}

export default withTranslation('recoverPassword')(withRouter(RecoverPasswordContainer));
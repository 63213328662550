import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { Option } from 'rc-select';
import { stateColors, stateNames, stateStyle } from './ProviderSharedData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const ProviderAccountFilters = (props: {
    applyFilters: (form: FormInstance) => void,
    resetFilters: () => void,

}) => {

    const { t } = useTranslation(['providerList']);
    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;

    const resetFilters = () => {
        form.resetFields();
        props.resetFilters();
    }

    const statusOptions = (value: number) => {
        const strStatus = t(stateNames[value]);

        return (
            <Option key={strStatus} value={value}>
                <div style={stateStyle}>
                    <FontAwesomeIcon icon={faCircle} style={{ color: stateColors[value], marginRight: '0.5rem' }} /> {strStatus}
                </div>
            </Option>

        );
    }

    return (
        <>
            <Form size='large' layout='vertical' className='form__smaller-margin' form={form} style={{ width: '100%' }}>
                <Form.Item name='nameNombre' label={t('providerName')}>
                    <Input placeholder={t('placeHolders:enterName')} />
                </Form.Item>
                <Form.Item name='codeIdentificacion' label={t('nif')}>
                    <Input placeholder={t('placeHolders:enterNif')} />
                </Form.Item>
            </Form>


            <Row gutter={16}>
                <Col span={12}>
                    <Button type='primary' block onClick={() => props.applyFilters!(form)}>
                        {t('buttons:apply')}
                    </Button>
                </Col>
                <Col span={12}><Button danger block onClick={resetFilters}


 
                >{t('buttons:reset')}</Button></Col>
            </Row>
        </>
    );
}

export default ProviderAccountFilters;

import React, { useState } from 'react';
import { Badge, Button, Drawer, Dropdown, FormInstance, Menu, Space, Table, Tooltip } from 'antd';
import { Functions, TableIcons } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchway, faBirthdayCake, faChartArea, faChartBar, faCity, faEnvelope, faMoneyCheck, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { IState as IStateContainer } from './ProviderAccountListContainer';
import { AlignType } from 'rc-table/lib/interface';
import { ICuenta } from './entities/Cuenta';
import ProviderAccountFilters from './ProviderAccountFilters';
import Highlighter from 'react-highlight-words';
import { EnterOutlined } from '@ant-design/icons';
import ProviderListItem from './entities/ProviderListData';

interface IProps {
    // filtersCollapsed?: boolean;
    onClickRRefreshState: () => void;
    onChangePage: (page: number) => void;
    onNewAccountForm: () => void;
    openAccountStats: () => void;
    onShowDetails: (evt: any) => void;
    onClickLicenseItem: (idCuenta: number) => void;
    showLocationsForm: (idCuenta: number, nameCuenta: string) => void;
    showPersonalizedEmailForm: (idCuenta: number, nameCuenta: string) => void;
    downloadInformeCuentas: (idCuenta:number) => void;
    onCollapseFilters: () => void;
    applyFilters: (form: FormInstance) => void;
    resetFilters: () => void;
    loadExtraInfoCuentas: (idCuenta: number) => void;
}

const ProviderAccountList = (props: IProps & IStateContainer) => {

    const { t } = useTranslation(['providerAccountListContainer']);
    const listaVacia: number[] = [];
    const [expandedRowKey, setExpandedRowKey] = useState(listaVacia);


    const actionRenderer = (value: number, record: ICuenta) => {

        const onClick = (e: any) => {
            switch (e.key) {
                case 'LIC':
                    props.onClickLicenseItem(record.idCuenta);
                    break;
                case 'DL':
                    props.showLocationsForm(record.idCuenta, record.nameCuenta);
                    break;
                case 'PE':
                    props.showPersonalizedEmailForm(record.idCuenta, record.nameCuenta);
                    break;
                case 'IC':
                    props.downloadInformeCuentas(record.idCuenta);
                    break;

            }
        };

        const extraDropdownMenu = (
            <Menu onClick={onClick}>
                <Menu.Item key='LIC'>
                    {t('licencias')}
                </Menu.Item>
                <Menu.Item key='DL'>
                    {t('localizaciones')}
                </Menu.Item>
                <Menu.Item key='PE'>
                    {t('personalizedEmail')}
                </Menu.Item>
                <Menu.Item key='IC'>
                    {t('informePrescripcionesCuenta')}
                </Menu.Item>
            </Menu>
        );


        return (
            <Space size='small'>
                <Tooltip title={t('accountDetailButton')} placement='left'>
                    <Button type='default' onClick={() => props.onShowDetails(record.idCuenta)}>
                        {t('buttons:details')}
                    </Button>
                </Tooltip>
                <Tooltip title={t('extraMenu')} placement='left'>
                    <Dropdown overlay={extraDropdownMenu} trigger={['click']}>
                        <Button type='primary' icon={TableIcons.getTableIcon(TableIcons.TableIcon.more)} />
                    </Dropdown>
                </Tooltip>

            </Space>
        );

    };

    const columns = [
        {
            title: t('nameCuenta'),
            dataIndex: 'nameCuenta',
            ellipsis: true,
            align: 'left' as AlignType,
            width: '30%',
            render: (nameCuenta: string) => {
        
                // Normalizar texto eliminando tildes y poniendo en minúsculas
                const normalizeText = (text: string) => {
                    return text
                        .normalize('NFD') // Descomposición de caracteres con tildes
                        .replace(/[\u0300-\u036f]/g, '') // Elimina marcas diacríticas
                        .toLowerCase(); // Convierte a minúsculas
                };
        
                // Normalizar palabras a resaltar y el texto de la celda
                const normalizedSearchWords = props.highlightNameCuenta?.map(normalizeText) || [];
                const normalizedTextToHighlight = normalizeText(nameCuenta);
        
                return <b>{props.highlightNameCuenta ?
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={normalizedSearchWords} // Palabras normalizadas
                        textToHighlight={normalizedTextToHighlight} // Texto normalizado
                        autoEscape
                    />
                    : nameCuenta}</b>;
            }
        },

        {
            title: t('nifColumnTitle'),
            dataIndex: 'codeIdentificacion',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '20%',
        },
        {
            title: t('prestadorCount'),
            dataIndex: 'cnt',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '20%',
        },
        {
            title: t('creacionColumnTitle'),
            dataIndex: 'dateCreacion',
            ellipsis: true,
            align: 'center' as AlignType,
            width: '20%',
            render: (dt: Date) => {
                return dt && Functions.dateToString(dt);
            }
        },
        {
            dataIndex: 'idCuenta',
            align: 'right' as AlignType,
            render: actionRenderer,
            width: '10%',
        }
    ];

    const Details = () => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <EnterOutlined className="table-expandable-detail__arrow" />
                        <p style={{ margin: 0, marginLeft: 8, fontWeight: 'bold' }}>{t('informacionAdicionaTitle')}Información adiccional de cuenta: </p>
                    </div>

                    <div style={{ marginTop: 8, paddingLeft: 30 }}>
                        <p style={{ margin: 0 }}>Número de prescriptores totales: {props.countPrescriptoresTotales}</p>
                        <p style={{ margin: 0 }}>Número de prescriptores activos (han prescrito en los últimos 30 días): {props.countPrescriptoresUltimos30}</p>
                        <p style={{ margin: 0 }}>Número de prescripciones en los últimos 30 días: {props.countPrescripcionesUltimos30}</p>
                        <p style={{ margin: 0 }}>Número de prescripciones en el año en curso: {props.countPrescripcionesEsteAno}</p>
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            <Drawer className='filters-drawer' visible={!props.filtersCollapsed}
                onClose={props.onCollapseFilters}
                width='300px' placement='left' getContainer={false}>
                {<ProviderAccountFilters
                    {...props}
                    key={props.filterKey}
                />}
            </Drawer>
            <Space size='small' className='table-button-bar'>
                <Badge className='filter-badge' count={props.nFilters}>
                    <Button type={props.filtersCollapsed ? 'primary' : 'default'}
                        icon={TableIcons.getTableIcon(TableIcons.TableIcon.filter)}
                        onClick={props.onCollapseFilters} />
                </Badge>
                <Button type={'primary'} icon={TableIcons.getTableIcon(TableIcons.TableIcon.sync)}
                    onClick={() => props.onClickRRefreshState()} />
                <Button icon={<FontAwesomeIcon icon={faMoneyCheck} style={TableIcons.PRIMARY_BUTTON_ICON_STYLE} />}
                    onClick={() => props.onNewAccountForm()}>
                    {t('newAccount')}
                </Button>
                <Button icon={<FontAwesomeIcon icon={faChartBar} style={TableIcons.PRIMARY_BUTTON_ICON_STYLE} />}
                    onClick={() => props.openAccountStats()}>
                    Estadísticas
                </Button>
            </Space>
            <div className='table-container'>
                <Table className='table__custom-expandable-icon'
                    scroll={{ y: props.tableBodyHeight }}
                    columns={columns}
                    size='small'
                    expandable={{
                        expandedRowRender: record => <Details {...record} />,
                        expandIconColumnIndex: 4,
                        expandedRowKeys: expandedRowKey,
                        expandIcon: ({ expanded, onExpand, record }) => (
                            <Button
                                type={expanded ? 'primary' : 'default'}
                                icon={expanded
                                    ? TableIcons.getTableIcon(TableIcons.TableIcon.chevronUp)
                                    : TableIcons.getTableIcon(TableIcons.TableIcon.chevronDown)
                                }
                                onClick={e => {
                                    props.loadExtraInfoCuentas(record.idCuenta);
                                    onExpand(record, e);
                                    setExpandedRowKey(expanded ? [] : [record.key]);


                                }}
                            />
                        )
                    }}

                    dataSource={props.accountList}
                    pagination={{
                        position: ['bottomRight'],
                        hideOnSinglePage: true,
                        showSizeChanger: false,
                        current: props.page,
                        pageSize: props.limit,
                        total: props.dataCount,
                        onChange: props.onChangePage,
                    }}
                />
            </div>

        </>
    );

};

export default ProviderAccountList;

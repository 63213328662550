import React, {ReactNode} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Rest, TableUtils, concatName} from '../utils/utils';
import View from './ProviderList';
import {MainTableBodyProps} from '../utils/tableUtils';
import {FormInstance, notification} from 'antd';
import Provincia from './entities/Provincias';
import ProviderFormContainer from './ProviderFormContainer';
import ProviderListItem from './entities/ProviderListData';
import PrestadorDetailData from './entities/PrestadorDetailData';
import PrestadorDocument from './entities/PrestadorDocument';
import DownloadDocItem from './entities/DownloadDocItem';
import EnumModuloType from '../enum/EnumModuloType';
import OperadorData from '../operadores/enteties/operadorData';



interface IProps {
    limit?: number;
    page?: number;
    addTab?: (tabCaption: string, component: ReactNode) => void;
}

export interface IState extends MainTableBodyProps {
    providerList?: ProviderListItem[];
    limit: number;
    page?: number;
    offset?: number;
    dataCount?: number;
    filtersCollapsed?: boolean;
    lastFilter?: any;
    filterKey?: number;
    nFilters?: number;
    highlightProviderName?: string[];
    providerNameFilter?: { value: string }[];
    nameProviderSelected?: string;
    providerFormKey?: number;
    provincias: Provincia[];
    provinciasLoaded: boolean;
    providerFormVisible: boolean;
    isNewPrestador: boolean;
    selectedPrestador?: PrestadorDetailData;
    selectedPrestadorDataLoaded: boolean;
    downloadDocList: DownloadDocItem[];
    idProvider?: number;
    cuentaList?: any[];
}

class ProviderListContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {
        providerList: [],
        limit: 0,
        offset: 0,
        filtersCollapsed: true,
        isNewPrestador: false,
        providerFormVisible: false,
        selectedPrestadorDataLoaded: false,
        downloadDocList: [],
        provinciasLoaded: false,
        provincias: [],
    };

    public componentDidMount() {
        this.setState(TableUtils.calculatePageSizeForMainTable(), () => this.loadData());
    }

    public componentWillUnmount() {

    }

    public render() {
        return (
            <>
                <View
                    {...this.state}
                    onCollapseFilters={this.onCollapseFilters}
                    onClickRRefreshState={this.onClickRRefreshState}
                    onChangePage={this.onChangePage}
                    applyFilters={this.applyFilters}
                    resetFilters={this.resetFilters}
                    onClickNewProvider={this.onNewProviderForm}
                    onShowDetails={this.onShowDetails}
                    onConfirmChangeProviderState={this.onChangeProviderState}
                    onReenviarEnlaceDeRegistro={this.onReenviarEnlaceDeRegistro}
                    onDesbloquearOperador={this.onDesbloquearOperador}
                />
                <ProviderFormContainer
                    {...this.state}
                    key={this.state.providerFormKey}
                    onClose={this.closeProviderForm}
                    removeDocFromList={this.removeDocFromList}
                    updateDownloadDocList={this.updateDownloadDocList}
                />
            </>
        );
    }


    private loadData = (page?: number) => {

        const values = this.state.lastFilter;
        const filter = {} as any;

        if (values) {
            filter.nameNombre = values.nameNombre ? values.nameNombre : undefined;
            filter.nameApellido1 = values.nameApellido1 ? values.nameApellido1 : undefined;
            filter.nameApellido2 = values.nameApellido2 ? values.nameApellido2 : undefined;
            filter.nif = values.codeIdentificacion ? values.codeIdentificacion : undefined;
            filter.estado = (values.typeEstado && values.typeEstado.length > 0) ? values.typeEstado : undefined;
            filter.idCuenta = values.cuentaPrestador;
            filter.colegiado = values.numColegiado;
            filter.dateFrom = values.dateCreacion && values.dateCreacion[0] ? new Date(values.dateCreacion[0]) : undefined;
            filter.dateTo = values.dateCreacion && values.dateCreacion[1] ? new Date(values.dateCreacion[1]) : undefined;
        }

        Rest<{ type: string }, any>().operation({type: 'GetAllCuentaList'})
        .then(response => {
            this.setState({cuentaList: response});
        });

        Rest<{ type: string; limit: number; offset: number }, any>().operation({
            type: 'SelectPrestadorListPagedEx',
            limit: this.state.limit,
            offset: (((page || 1) - 1) * this.state.limit),
            ...filter,
        }).then(response => {
            const providers = (response.data || []).map((provider: ProviderListItem) => {
                provider.key = provider.idPrestador;
                provider.prestadorNombre = concatName(provider.nameNombre, provider.nameApellido1, provider.nameApellido2);
                provider.prestadorEstadoHistoricoList?.forEach(e => {
                    e.prestadorNombreCompleto = concatName(e.nameNombre, e.nameApellido1, e.nameApellido2);
                });
                return provider;
            });

            const highlightProviderName = [];
            if (values) {
                values.nameNombre && highlightProviderName.push(values.nameNombre);
                values.nameApellido1 && highlightProviderName.push(values.nameApellido1);
                values.nameApellido2 && highlightProviderName.push(values.nameApellido2);
            }

            this.setState({
                    providerList: providers,
                    dataCount: response.dataCount || 0,
                    highlightProviderName,
                    lastFilter: values,
                    page,
                },
                () => {
                    if (!this.state.lastFilter) {
                        this.loadDataFilters();
                    }
                },
            );
        });
    };

    private loadDataFilters = () => {
    };

    private resetFilters = () => {
        this.setState({
            page: 1,
            nFilters : 0,
            lastFilter: undefined,
            filterKey: new Date().getTime(),
        }, () => this.loadData(this.state.page));
    };


    private onCollapseFilters = () => {
        this.setState({filtersCollapsed: !this.state.filtersCollapsed});
    };

    private onClickRRefreshState = () => {
        this.loadData(this.state.page);
    };

    private onChangePage = (page: number) => {
        this.loadData(page);
    };

    private applyFilters = (form: FormInstance) => {
        var nFilters = 0;
        const values = form.getFieldsValue();
        var filters = values as OperadorData; //nombre, apellido1, apellido2, dni, cuenta, estado, colegiado, fechacreacion 
        if(filters.nameNombre != undefined && filters.nameNombre != ""){
            nFilters = nFilters+1;
        }if(filters.nameApellido1 != undefined && filters.nameApellido1 != ""){
            nFilters = nFilters+1;
        }if(filters.nameApellido2 != undefined && filters.nameApellido2 != ""){
            nFilters = nFilters+1;
        }if (filters.codeIdentificacion != undefined && filters.codeIdentificacion != ""){
            nFilters = nFilters+1;
        }if (filters.cuentaPrestador != undefined && filters.cuentaPrestador != ""){
            nFilters = nFilters+1;
        }if (filters.typeEstado != undefined && filters.typeEstado != 0){
            nFilters = nFilters+1;
        }if (filters.numColegiado != undefined && filters.numColegiado != 0){
            nFilters = nFilters+1;
        }if (filters.dateCreacion != undefined) {
            nFilters = nFilters+1;
        }
        
        console.log(nFilters);
        this.setState({nFilters: nFilters,lastFilter: values, filtersCollapsed: true}, () => this.loadData(1));
    };

    private onNewProviderForm = () => {

        this.setState({
            providerFormVisible: true,
            isNewPrestador: true,
            providerFormKey: new Date().getTime(),
        });
    };


    private closeProviderForm = (save: boolean) => {

        this.setState({
            providerFormVisible: false,
        }, () => {
            if (!save) {
                return;
            }

            notification['success']({
                message: this.props.t('providerList:actionPerformed'),
            });
            this.loadData(this.state.page);
        });
    };

    private onShowDetails = (id: number) => {
        // Load data for the prestador selected
        const promises: Array<Promise<any>> = [];
        const rqPrestadorDetailsData = Rest<{ type: string, idPrestador: number }, PrestadorDetailData>();
        const rqPrestadorDocuments = Rest<{ type: string, idPrestador: number }, PrestadorDocument[]>();
        promises.push(rqPrestadorDetailsData.operation({type: 'SelectPrestadorById', idPrestador: id}));
        promises.push(rqPrestadorDocuments.operation({type: 'SelectPrestadorDocumentosUploaded', idPrestador: id}));

        Promise.all(promises).then(response => {
            const spd = response[0];
            const doc: PrestadorDocument[] = response[1];
            const downloadDoc: DownloadDocItem[] = doc.map(e => {
                let di: DownloadDocItem = {
                    uid: e.idArchivo.toString(), name: e.nameArchivo, status: 'done',
                    size: e.nbrtamano, type: e.typeMime, fileId: e.idArchivo, groupId: e.typeDocumento.toString(),
                };
                return di;
            });

            this.setState({
                selectedPrestador: spd,
                providerFormVisible: true,
                isNewPrestador: false,
                idProvider: id,
                providerFormKey: new Date().getTime(),
                selectedPrestadorDataLoaded: true,
                downloadDocList: downloadDoc ? downloadDoc : [],
            });
        });
    };

    private onChangeProviderState = (changeProviderState: { id: number; nextState: number }) => {
        Rest<{ type: string; idPrestador: number; typeEstado: number }, any>().operation({
            type: 'UpdatePrestadorEstado',
            idPrestador: changeProviderState.id,
            typeEstado: changeProviderState.nextState,
        })
            .then((response) => {
                if(response==true){
                    notification['success']({
                        message: this.props.t('providerList:actionPerformed'),
                    });
                    this.loadData(this.state.page);
                }else{
                    notification['error']({
                        message: this.props.t('providerList:errorPaseValidacion'),
                    });
                }
            });
    };

    private updateDownloadDocList = (doclist: DownloadDocItem[]) => {
        this.setState({downloadDocList: doclist});
    };

    private removeDocFromList = (fileId: number) => {
        const dl: DownloadDocItem[] = this.state.downloadDocList;
        const idx = dl.findIndex(e => e.fileId === fileId);
        if (typeof idx !== 'undefined') {
            dl.splice(idx, 1);
            this.setState({downloadDocList: dl});
        }

    };

    private onReenviarEnlaceDeRegistro = (record: ProviderListItem) => {
        Rest<{ type: string; idPrestador: number }, any>().operation({
            type: 'ReSendPrestadorAltaMessage',
            idPrestador: record.idPrestador,
        })
            .then(() => {
                notification['success']({
                    message: this.props.t('providerList:enlaceDeRegistroReenviado'),
                });
                this.loadData(this.state.page);
            });
    };

    private onDesbloquearOperador = (record: ProviderListItem) => {
        Rest<{ type: string; idPersona: number, typeModulo: number }, any>().operation({
            type: 'UnblockInactiveUser',
            idPersona: record.idPrestador,
            typeModulo: EnumModuloType.MODULO_PRESCRIPTOR,
        })
            .then(() => {
                notification['success']({
                    message: this.props.t('providerList:userUnblocked'),
                });
                this.loadData(this.state.page);
            });

    };


}

export default withTranslation('appointmentSchedule')(withRouter(ProviderListContainer));

import React, {ClipboardEvent} from 'react';

import { Store } from 'antd/lib/form/interface';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Rest, UserInfo } from '../utils/utils';
import LoginOperation from './operations/loginOperation';
import LoginOperationResponse from './operations/loginOperationResponse';
import View from './Login';
import EnumCodeResultLogin from '../enum/EnumCodeResultLogin';
import {FormInstance, notification} from 'antd';

export interface IState {
    errorMessage?: string;
    warningMessage?: string;
    errorFields?: string[];
    valueUuId?: string;
    email?: string;
    codeResult?: number;
    forgotPasswordVisible?: boolean;
    warningPasswordMessage?: string;
    errorPasswordMessage?: string;
    mailSent?: boolean;
    otpCancelled?: boolean;
}

class LoginContainer extends React.Component<WithTranslation & RouteComponentProps, IState> {

    public componentDidMount() {
        if (UserInfo.isPresent()) {
            this.props.history.push('/app');
        }
    }

    public state: IState = {};

    public render() {
        return (
            <View
                {...this.state}
                onSubmit={this.login}
                onContinue={this.otpValidation}
                onValidationFailed={this.validationFailed}
                tryAgain={this.tryAgain}
                onValidationOtp={this.onValidationOtp}
                onOTPPaste={this.onOTPPaste}
                onClickForgotPassword={this.onClickForgotPassword}
                onForgotPassword={this.onForgotPassword}
                validationPasswordFailed={this.validationPasswordFailed}
                closePasswordForm={this.closePasswordForm}
                onOtpCancel={this.onOtpCancel}
                onNewOtpRequest={this.onNewOtpRequest}
            />
        );
    }

    private validationFailed = () => {
        this.setState({warningMessage: this.props.t('emptyFieldsWarning'), errorMessage: undefined});
    }

    private onValidationOtp = () => {
        this.setState({warningMessage: this.props.t('emptyFieldOtpsWarning'), errorMessage: undefined});
    }

    private login = (values: Store) => {
        Rest<LoginOperation, LoginOperationResponse>().operation({type: 'Login', login: values.username.replace(/\s/g, ""), password: values.password.replace(/\s/g, "")})
        .then(response => {
            if (response && response.valid && response.isLocal) {
                this.props.history.push('/app');
            } else if (response && response.valid && response.valueUuId && response.email) {
                this.setState({valueUuId: response.valueUuId, email: response.email, errorMessage: undefined, warningMessage: undefined, otpCancelled: undefined});
            } else {
                if (response && response.numAttempts && response.codeResult) {
                    this.setState({errorMessage: this.props.t('codeResultLogin:'+response.codeResult, {nIntentos: response.numAttempts}), warningMessage: undefined, otpCancelled: undefined});
                } else {
                    this.setState({errorMessage: this.props.t('codeResultLogin:' + (response && response.codeResult ? response.codeResult : EnumCodeResultLogin.USUARIO_CONTRASENA_ERROR_TYPE_MODULO_EXISTENTE_PRIMER_INTENTO)), warningMessage: undefined, otpCancelled: undefined})
                }
            }
        });
    }

    private otpValidation  = (values: Store) => {
        const codeOtp = values.txt_Key_1 + values.txt_Key_2 + values.txt_Key_3 + values.txt_Key_4 + values.txt_Key_5 + values.txt_Key_6;
        if(!codeOtp.match(/^[0-9.]+$/)) {
            this.setState({errorMessage: 'El código debe ser numérico', warningMessage: undefined})
        } else {

            Rest<{type: string, valueUuId: string, code: string}, LoginOperationResponse>().operation({
                type: 'OtpValidation',
                valueUuId: this.state.valueUuId!,
                code: codeOtp
            }).then(response => {
                if (response && response.valid) {
                    Rest<{type: string, valueUuId: string, codeOtp: string}, LoginOperationResponse>().operation({
                        type: 'Start',
                        valueUuId: this.state.valueUuId!,
                        codeOtp: codeOtp
                    }).then(response => {
                        this.props.history.push('/app');
                    });
                } else {
                    this.setState({errorMessage: this.props.t('codeResultLogin:'+response.codeResult), warningMessage: undefined, codeResult:response.codeResult})
                }
            });
            
        }
    }

    private tryAgain = () => {
        this.setState({errorMessage: undefined, warningMessage: undefined, codeResult: undefined, valueUuId: undefined, email: undefined})
        this.props.history.push('/login');
    }

    private onOTPPaste = (event: ClipboardEvent<HTMLInputElement>, form: FormInstance) => {
        const pastedValue = event.clipboardData.getData('Text');
        if (pastedValue && pastedValue.length === 6) {
            form.setFieldsValue({
                txt_Key_1: pastedValue.charAt(0),
                txt_Key_2: pastedValue.charAt(1),
                txt_Key_3: pastedValue.charAt(2),
                txt_Key_4: pastedValue.charAt(3),
                txt_Key_5: pastedValue.charAt(4),
                txt_Key_6: pastedValue.charAt(5),
            });
        }
    }

    private onClickForgotPassword = () => {
        this.setState({forgotPasswordVisible: true, warningPasswordMessage: undefined, errorPasswordMessage: undefined, mailSent: undefined});
    }

    private closePasswordForm = () => {
        this.setState({forgotPasswordVisible: false, warningPasswordMessage: undefined, errorPasswordMessage: undefined, mailSent: undefined});
    }
    
    private onForgotPassword = (values: Store) => {
        Rest<{type: string, userName: string}, number>().operation({type: 'RegeneratePassword', userName: values.username})
        .then(response => {
            if (response === 1) {
                this.setState({errorPasswordMessage: this.props.t('passwordUserError')});
            }
            if (response === 2) {
                this.setState({errorPasswordMessage: this.props.t('passwordUserBloqError')});
            }
            if (response === 0) {
                this.setState({mailSent: true, errorPasswordMessage: undefined, warningPasswordMessage: undefined});
            }
        });
    }

    private validationPasswordFailed = () => {
        this.setState({warningPasswordMessage: this.props.t('emptyFieldPasswordWarning')});
    }

    private onOtpCancel = () => {
        this.setState({valueUuId: undefined, codeResult: undefined, otpCancelled: true, errorMessage: undefined, warningMessage: undefined});
    }

    private onNewOtpRequest = () => {
        if(typeof this.state.valueUuId === 'undefined') {
            return;
        }

        Rest<{type: string, valueUuId: string}, string>()
            .operation({type: 'ResendOtpCode', valueUuId: this.state.valueUuId})
            .then(response => {
                if(response) {
                    this.setState({valueUuId:  response, errorMessage: undefined, warningMessage: undefined});
                    notification['success']({
                        message: this.props.t('codigoReenviado')
                    });
                } else {
                    this.setState({valueUuId:  undefined, errorMessage: undefined, warningMessage: undefined});
                }
            });
    }
}

export default withTranslation('login')(withRouter(LoginContainer));
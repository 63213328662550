import { faUserInjured } from '@fortawesome/free-solid-svg-icons/faUserInjured';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LOGOERECETA from './images/logo.png';

class NotValidLink extends React.Component<WithTranslation & RouteComponentProps> {

    public render() {

        return (
            <div className='enlace_token_container'>
                <div className='enlace_token_card_container'>
                    <div className='enlace_token_card'>
                        <div className='enlace_token_logo'><img src={LOGOERECETA}  alt=''/></div>
                        <div className='enlace_token_result'>
                            <div className='enlace_token_result-logo'>
                                <div className='not-valid-logo'>
                                    <FontAwesomeIcon icon={faUserInjured} />
                                </div>
                                </div>
                            <div className='enlace_token_result-text'>
                                <div>{this.props.t('notValid')}</div>
                                <div>{this.props.t('getAnotherLink')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation('notValidLink')(withRouter(NotValidLink));
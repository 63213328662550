import React from 'react';
import {ICuenta} from './entities/Cuenta';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import ProviderAccountForm from './ProviderAccountForm';
import {FormInstance} from 'antd';
import Provincia from './entities/Provincias';

interface IProps {
    accountDetailFormVisible: boolean;
    accountDetailFormTitle?: string;
    selectedAccount?: ICuenta;
    provincias?: Provincia[];
    provinciasLoaded?: boolean;
    onCancel: () => void;
    onSubmit: (form: FormInstance) => void;
    loadProvinciasInfo: () => void;
}

export interface IState {

}

class ProviderAccountFormContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {};


    componentDidMount() {
        if (!this.props.provincias || this.props.provincias.length === 0) {
            this.props.loadProvinciasInfo();
        }
    }


    onCancel = () => {
        this.props.onCancel();
    };

    onSubmit = (form: FormInstance) => {

        //OOOK...
        this.props.onSubmit(form);

    };


    render() {
        return (
            <>
                <ProviderAccountForm
                    {...this.state}
                    formTitle={this.props.accountDetailFormTitle}
                    provincias={this.props.provincias}
                    provinciasLoaded={this.props.provinciasLoaded}
                    selectedAccount={this.props.selectedAccount}
                    onCancel={this.onCancel}
                    onSubmit={this.onSubmit}
                    visible={this.props.accountDetailFormVisible}
                />

            </>

        );
    }
}

export default withTranslation('providerAccountListContainer')(withRouter(ProviderAccountFormContainer));
import React, { createRef } from 'react';

import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import MainLayout from './MainLayout';
import Login from './login/LoginContainer';
import { LoadingOutlined } from '@ant-design/icons';
import EventHub, { Event } from './utils/eventHub';
import { setHistory as setRestHistory } from './utils/rest';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import RecoverPasswordContainer from './recoverPassword/RecoverPasswordContainer';
import RecoverPasswordCorrect from './recoverPassword/RecoverPasswordCorrect';
import NotValidLink from './NotValidLink';
import WelcomeContainer from './welcome/welcomeContainer';

interface IState {
    showLoadingMask?: boolean; 
}

export default class App extends React.Component<{}, IState> {

    public state: IState = {showLoadingMask: false};

    private restCalls: number = 0;
    private timerFadeInEffect?: any;
    private timerFadeOutEffect?: any;
    private loadingMaskRef = createRef<HTMLDivElement>();

    public componentDidMount() {

        EventHub.on(Event.LOADING_ON, () => {
            this.maskOn();
        });

        EventHub.on(Event.LOADING_OFF, () => {
            this.maskOff();
        });
    } 

    public render() {

        return (
            <>
                {this.state.showLoadingMask && <div ref={this.loadingMaskRef} className='app-loading-mask'><LoadingOutlined style={{ fontSize: 48 }} spin /></div>}
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <AppRoutesWithRouter/>
                </BrowserRouter>
            </>
        );
    }

    private maskOn = () => {
        this.restCalls++;

        if (this.restCalls === 1) {
            clearTimeout(this.timerFadeOutEffect);
            this.setState({showLoadingMask: true}, () => {
                this.timerFadeInEffect = setTimeout(() => this.loadingMaskRef.current!.style.opacity = '1', 50);
            });
        }
    }

    private maskOff = () => {
        this.restCalls--;

        if (this.restCalls < 0) {
            this.restCalls = 0;
        }

        if (this.restCalls === 0 && this.loadingMaskRef.current) {
            clearTimeout(this.timerFadeInEffect);
            this.loadingMaskRef.current!.style.opacity = '0';
            clearTimeout(this.timerFadeOutEffect);
            this.timerFadeOutEffect = setTimeout(() => this.setState({showLoadingMask: false}), 500);
        }
    }
}

class AppRoutes extends React.Component<RouteComponentProps> {

    public componentDidMount() {
        setRestHistory(this.props.history);
    }
    
    public render() {
        return (
            <Switch>
                <Route path={'/registro/:token'} component={WelcomeContainer} />
                <Route path='/app' component={MainLayout} />
                <Route path='/login' component={Login} />
                <Route path={'/regeneracion-password/:token'} component={RecoverPasswordContainer} />
                <Route path={'/regeneracion-password-correcta'} component={RecoverPasswordCorrect} />
                <Route path={'/enlaceNoValido'} component={NotValidLink} />
                <Redirect to='login'/>
            </Switch>
        );
    }
}

const AppRoutesWithRouter = withRouter(AppRoutes);
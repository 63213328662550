import {AutoComplete, Button, Col, DatePicker, FormInstance, Input, Modal, Row, Select, Upload, Form} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IState as IStateContainer} from './ProviderFormContainer';
import './Provider.scss';
import {UploadOutlined} from '@ant-design/icons';
import {UploadFile} from 'antd/lib/upload/interface';
import PrestadorDetailData from './entities/PrestadorDetailData';
import moment from 'moment';
import DownloadDocItem from './entities/DownloadDocItem';
import RcFileEx from './entities/RcFileEx';
import EnumPrestadorTypeIdentificacion from '../enum/EnumPrestadorTypeIdentificacion';
import Highlighter from 'react-highlight-words';
import Rest from '../utils/rest';
import _ from 'lodash';
import {getUnaccentText} from '../utils/utils';

interface IProps {
    visible?: boolean;
    disablePoblacion: boolean;
    isNewPrestador: boolean;
    acceptedFileTypes: string;
    onCancel: () => void;
    selectedPrestador?: PrestadorDetailData;
    downloadDocList?: DownloadDocItem[];
    onDownloadDocFile: (info: any) => void;
    onSubmit: (form: FormInstance) => void;
    CPChange: (cp: any, form: FormInstance) => void;
    onFormValuesChanged: (form: FormInstance, v: any, k: any) => void;
    onFileUpload: (file: RcFileEx, groupId: string, form: FormInstance) => boolean;
    onFileRemove: (file: UploadFile, groupId: string) => void;
}

const ProviderForm = (props: IProps & IStateContainer) => {

    const {t} = useTranslation(['providerList']);
    const [form] = Form.useForm();
    const [dniLenght, setDniLength] = useState<number>(9);
    const [searchList, setSearchList] = useState<string[]>([]);
    const [, setInputValue] = React.useState('');
    const [valLicencia, setValLicencia] = useState<{ key: string, value: string, label: string }[]>([]);
    const [selectedOption, setSelectedOption] = React.useState<{
        value: string, label: string, key: string
    } | string | undefined>('');
    const searchLicencia = _.debounce((value: string) => doSearchLicencia(value), 500);
    const [cuentaState, setCuentaState] = useState<{
        extraMessage: string; disabled: boolean, required?: boolean
    }>({extraMessage: t('newAccountPlaceholder'), disabled: false, required: false});

    //Dejar en blanco para crear una cuenta de tipo individual para este prestador, y añadir el periodo de prueba de 30 días a partir de hoy mismo


    const doSearchLicencia = (value: string) => {

        if (value.trim().length < 3) {
            if (value.trim().length === 0) {
                handleClear();
            }

            return;
        }

        const words = value.trim().split(/\s+/);
        setSearchList(words);

        Rest<{ type: string; value: string }, { idCuenta: number; cuentaData: string }[]>()
            .operation({type: 'SelectCuentaByNameOrNif', value}, true)
            .then(response => {
                const ret = response.map(e => {
                    return {value: e.cuentaData, label: e.cuentaData, key: e.idCuenta.toString()};
                });
                setValLicencia(ret);
            });
    };

    const handleSelected = (value: any, option: any) => {
        setSelectedOption(option);
        form.setFieldsValue({idCuenta: option.key});
    };

    const clearSearchLicencia = () => {
        setValLicencia([]);
    };

    const handleClear = () => {
        clearSearchLicencia();
        setInputValue('');
        setSelectedOption('');
        form.setFieldsValue({idCuenta: undefined});
    };

    const doHighlight = (text: string) => {
        return (
            <Highlighter
                highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                searchWords={searchList}
                autoEscape={true}
                textToHighlight={text}

            />
        );
    };


    const dniChange = (val: any) => {
        if (val.key === 4) {
            setDniLength(20);
        } else {
            setDniLength(9);
            let x = form.getFieldValue('valueNif');
            x = typeof x === 'undefined' ? undefined : x.toString().substring(0, 9);
            form.setFieldsValue({valueNif: x});
        }
    };


    const FileDocComp = (itemLabel: string, itemName: string, groupId: string, opt?: boolean) => {
        if (props.isNewPrestador) {
            return (
                <Form.Item label={t(itemLabel)} name={itemName}
                           rules={[{required: !opt}]}
                >
                    <Upload
                        multiple={false}
                        accept={props.acceptedFileTypes}
                        beforeUpload={(file) => props.onFileUpload(file, groupId, form)}
                        onRemove={(file) => props.onFileRemove(file, groupId)}>
                        <Button
                            icon={<UploadOutlined/>}>{t('seleccionaArchivos')}</Button>
                    </Upload>
                </Form.Item>
            );
        } else {
            return (
                <Form.Item label={t(itemLabel)} name={itemName}
                           rules={[{required: !opt}]}
                           initialValue={props.downloadDocList ? props.downloadDocList.filter(e => e.groupId === groupId) : undefined}
                >
                    <Upload
                        multiple={false}
                        accept={props.acceptedFileTypes}
                        fileList={props.downloadDocList?.filter(e => e.groupId === groupId)}
                        onDownload={props.onDownloadDocFile}
                        showUploadList={{
                            showDownloadIcon: true,
                            showRemoveIcon: true,
                        }}
                        beforeUpload={(file) => props.onFileUpload(file, groupId, form)}
                        onRemove={(file) => props.onFileRemove(file, groupId)}>
                        <Button
                            icon={<UploadOutlined/>}>{t('seleccionaArchivos')}</Button>
                    </Upload>

                </Form.Item>
            );
        }

    };


    let initialValues: any = {};
    if (props.isNewPrestador) {
        initialValues =
            {
                formTitle: t('newPrestador'),
                colegiadoPrefix: {label: 'Colegiado', key: 1, value: 1},
                nifPrefix: {label: 'DNI', key: 1, value: 1},
            };
    } else if (props.selectedPrestador && props.visible) {
        const sp = props.selectedPrestador;
        initialValues =
            {
                formTitle: t('editarPrestador') + sp.nameNombre + ' ' + sp.nameApellido1 + (sp.nameApellido2 ? ' ' + sp.nameApellido2 : ''),
                nifPrefix: {value: sp.typeIdentificacion},
                valueNif: sp.codeIdentificacion,

                colegiadoPrefix: {value: sp.colegiadoTipo},
                valueColegiado: sp.numeroColegiado,
                valueColegio:  sp.idColegio!=undefined ? {value: sp.idColegio}: undefined,

                valueNombre: sp.nameNombre,
                valuePrimerApellido: sp.nameApellido1,
                valueSegundoApellido: sp.nameApellido2,
                valueFechaDeNacimiento: sp.dateNacimiento!=undefined ? moment(sp.dateNacimiento) : undefined,

                valueEspecialidad: sp.codeEspecialidad!=undefined ?  {value: sp.codeEspecialidad}: undefined,
                valueDireccion: sp.valueDireccion,
                valueDetalleDireccion: sp.valueDireccion2,

                valueCodigoPostal: sp.codeCodigopostal,
                valueProvincia: sp.codeProvincia,
                valuePoblacion: sp.codemunicipio,
                valueEmail: sp.valueEmail,
                valueTelefono: sp.valueTelefono,
                typeSexo: sp.typeSexo!=undefined ? {value: sp.typeSexo} : undefined,
                cuentaData: sp.cuentaData,
                idCuenta: sp.idCuenta,
                typeEstado: sp.typeEstado,
            };
    }

    useEffect(() => {
        if (props.visible) {
            if (props.isNewPrestador || props.selectedPrestador?.typeEstado && props.selectedPrestador.typeEstado == 1) {
                setCuentaState({extraMessage: t('newAccountPlaceholder'), disabled: false, required: false});
            } else {
                if (props.selectedPrestador?.typeEstado) {
                    if (props.selectedPrestador.typeEstado < 4) {
                        setCuentaState({
                            ...cuentaState,
                            extraMessage: t('enterAccount'), disabled: false, required: true,
                        });


                    } else {
                        setCuentaState({extraMessage: '', disabled: true, required: false});
                    }
                }
            }
        }

    }, [props.visible]);


    //Sync field values

    const {Option} = Select;

    const nifPrefixSelector = (
        <Form.Item name='nifPrefix' noStyle>
            <Select labelInValue style={{width: 120}}
                    disabled={!props.isNewPrestador}
                    onChange={dniChange}
                    filterOption={(inputValue, option) =>
                        option!.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())}>
                <Option key={1} value={1}>DNI</Option>
                <Option key={2} value={2}>NIE</Option>
                <Option key={4} value={4}>Pasaporte</Option>
            </Select>
        </Form.Item>
    );

    const colegiadoPrefixSelector = (
        <Form.Item name='colegiadoPrefix' noStyle>
            <Select style={{width: 120}} labelInValue disabled={!props.isNewPrestador}
                    filterOption={(inputValue, option) =>
                        option!.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())}>
                <Option key={1}
                        value={EnumPrestadorTypeIdentificacion.NUMERO_COLEGIADO}>{t('prestadorTypeIdentificacion:' + EnumPrestadorTypeIdentificacion.NUMERO_COLEGIADO)}</Option>
                <Option key={2}
                        value={EnumPrestadorTypeIdentificacion.TARJETA_MILITAR}>{t('prestadorTypeIdentificacion:' + EnumPrestadorTypeIdentificacion.TARJETA_MILITAR)}</Option>
            </Select>
        </Form.Item>
    );

    const checkChar = (e: any) => {
        if ('0123456789'.indexOf(e.key) === -1) {
            e.preventDefault();
        }
    };

    const sexoOficial = (
        <Form.Item label={t('sex')} name='typeSexo' rules={[{required: true}]}>
            <Select labelInValue
                    filterOption={(inputValue, option) =>
                        option!.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())}>
                <Option key={1} value={1}>Hombre</Option>
                <Option key={2} value={2}>Mujer</Option>
                <Option key={0} value={0}>Otro</Option>
            </Select>
        </Form.Item>
    );

    return (
        <Modal
            title={initialValues!.formTitle}
            visible={props.visible || false}
            cancelText={t('buttons:cancel')}
            okText={t('buttons:save')}
            onCancel={props.onCancel}
            onOk={() => props.onSubmit(form)}
            style={{top: 40}}
            destroyOnClose
            width={1250}
        >
            <Form layout='vertical' form={form} size='large' initialValues={initialValues}
                  onValuesChange={(v: any, k: any) => props.onFormValuesChanged(form, v, k)}
            >
                <Row style={{marginBottom: '10px', fontSize: '16px'}}>
                    <b>{t('datosPersonales')}</b>
                </Row>

                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item label={t('documentoDeIdentidad')} name='valueNif'
                                   rules={[{required: true}]}
                        >
                            <Input addonBefore={nifPrefixSelector} maxLength={dniLenght}
                                   disabled={!props.isNewPrestador}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('numeroDeColegiado')} name='valueColegiado' rules={[{required: true}]}>
                            <Input addonBefore={colegiadoPrefixSelector} maxLength={256}
                                   disabled={!props.isNewPrestador}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('colegioProfesional')} name='valueColegio'
                                   >
                            <Select allowClear autoClearSearchValue showSearch={true} labelInValue
                                    disabled={!props.isNewPrestador && props.selectedPrestador?.idColegio != null}
                                    filterOption={(inputValue, option) =>
                                        option!.props.children
                                            .toString()
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                    }
                            >
                                {props.colegiosList?.map(pi => {
                                    return (
                                        <Option
                                            key={pi.idColegio}
                                            value={pi.idColegio}>
                                            {pi.nombreColegio}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                   
                </Row>
                <Row gutter={12}>
                    
                    <Col span={12}>
                        <Form.Item label={t('especialidadColumnTitle')} name='valueEspecialidad'
                                   rules={[{required: true}]}>
                            <Select allowClear autoClearSearchValue showSearch={true} labelInValue
                                    filterOption={(inputValue, option) =>
                                        option!.props.children
                                            .toString()
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                    }
                            >
                                {props.especialidadList?.map(pi => {
                                    return (
                                        <Option
                                            key={pi.codeEspecialidad}
                                            value={pi.codeEspecialidad}>
                                            {pi.descEspecialidad}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item label={t('nombre')} name='valueNombre' rules={[{required: true}]}>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('primerApellido')} name='valuePrimerApellido' rules={[{required: true}]}>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('segundoApellido')} name='valueSegundoApellido'>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label={t('fechaDeNacimiento')} name='valueFechaDeNacimiento'
                                   rules={[{required: true}]}>
                            <DatePicker format='L'/>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        {sexoOficial}
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item label={t('direccion')} name='valueDireccion' rules={[{required: true}]}>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('detalleDireccion')} name='valueDetalleDireccion'>
                            <Input maxLength={120}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('codigoPostal')} name='valueCodigoPostal' rules={[{required: true}]}>
                            <Input maxLength={5} minLength={5} onKeyPress={checkChar}
                                   placeholder={t('introduceCodigoPostal')}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={12}>
                    <Col span={6}>
                        <Form.Item label={t('provincia')} name='valueProvincia'
                                   rules={[{required: true}]}
                        >
                            <Select
                                placeholder={t('selectProvincia')}
                                showSearch={true}
                                defaultActiveFirstOption={true}
                                filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                            >
                                {props.provinciaPorCp?.map(pi => {
                                    return (
                                        <Option
                                            key={pi.codeProvincia}
                                            label={getUnaccentText(pi.nameProvincia)}
                                            value={pi.codeProvincia}>
                                            {pi.nameProvincia}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('poblacion')} name='valuePoblacion' rules={[{required: true}]}
                        >
                            <Select
                                showSearch={true}
                                disabled={props.disablePoblacion}
                                placeholder={t('selectPoblacion')}
                                filterOption={(input, option) => ('' + option?.label).toUpperCase().includes(input.toUpperCase())}
                            >
                                {props.municipioList?.map(pi => {
                                    return (
                                        <Option
                                            key={pi.codeMunicipio}
                                            value={pi.codeMunicipio}
                                            label={getUnaccentText(pi.nameMunicipio)}
                                        >

                                            {pi.nameMunicipio}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('email')} name='valueEmail' rules={[{
                            required: true,
                            type: 'email', message: t('incorrectEmail'), validateTrigger: 'props.onSubmit',
                        }]}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t('telefono')} name='valueTelefono' rules={[{required: true}]}>
                            <Input maxLength={128}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{marginBottom: '10px', marginTop: '10px', fontSize: '16px'}}>
                    <b>{t('documentacion')}</b>
                </Row>

                <Row gutter={12}>
                    <Col flex='0 0 20%' style={{overflow: 'hidden'}}>
                        {FileDocComp('documentoDeIdentidad', 'subirDocIdentidad', '1')}
                    </Col>
                    <Col flex='0 0 20%' style={{overflow: 'hidden'}}>
                        {FileDocComp('seguroResponsabilidad', 'subirSeguroResponsabilidad', '2')}
                    </Col>
                    <Col flex='0 0 20%' style={{overflow: 'hidden'}}>
                        {FileDocComp('carnetDeColegiado', 'subirCarnetDeColegiado', '3')}
                    </Col>

                    <Col flex='0 0 20%' style={{overflow: 'hidden'}}>
                        {FileDocComp('certificadoDeIdoneidad', 'subirCertificadoDeIdoneidad', '4')}
                    </Col>
                    <Col flex='0 0 20%' style={{overflow: 'hidden'}}>
                        {FileDocComp('otros', 'subirOtros', '5', true)}
                    </Col>
                </Row>
                {/*<Row style={{marginBottom: '10px', marginTop: '10px', fontSize: '16px'}}>*/}
                {/*    <b>{t('cuentaFacturacion')}</b>*/}
                {/*</Row>*/}


                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name='cuentaData' style={{width: '100%'}}
                            rules={[{required: cuentaState.required}]}
                            extra={cuentaState.extraMessage}
                            label={<b style={{fontSize: '16px'}}>{t('cuentaFacturacion')}</b>}

                        >
                            <AutoComplete
                                disabled={cuentaState.disabled}
                                className='custom-autocomplete'
                                options={valLicencia.map(e => {
                                        return {
                                            value: e.value,
                                            key: e.key,
                                            label: doHighlight(e.value),
                                        };
                                    },
                                )}
                                onSearch={searchLicencia}
                                onSelect={handleSelected}
                                allowClear
                                onClear={handleClear}
                                style={{width: '100%'}}
                            >
                                <Input disabled={cuentaState.disabled}/>
                            </AutoComplete>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item hidden name='idCuenta' style={{width: '100%'}} noStyle>
                    <Input/>
                </Form.Item>
                <Form.Item hidden name='typeEstado' style={{width: '100%'}} noStyle>
                    <Input/>
                </Form.Item>


            </Form>
        </Modal>
    );

};

export default ProviderForm;
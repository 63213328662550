import React, { ReactNode } from 'react';
import ProviderListContainer from '../providers/ProviderListContainer';
import UsuariosOperadoresListContenedor from '../operadores/OperadoresListContenedor';
import ProviderAccountListContainer from '../providers/ProviderAccountListContainer';
import HerramientasDesarrollador from '../providers/ProviderAccountListContainer';
import MailsPruebaContainer from '../developers/mailsPruebaContainer';

export interface MenuOptionConfig {
    id: string;
    menuCaption: string;
    tabCaption: string;
}

export enum COMPONENT_IDS {
    ListadoDePrestadoresContenedor = 'prestadores',
    ListadoDeCuentas = 'cuentas',
    ListadoDeUsuariosOperadoresContenedor = 'usuariosOperadores'
}


export default {

    menuConfig: [
        { id: 'w', menuCaption: 'menu:productWorkshop', tabCaption: 'menu:productWorkshop' },
        { id: COMPONENT_IDS.ListadoDePrestadoresContenedor, menuCaption: 'menu:providerList', tabCaption: 'menu:providerList' },
        { id: COMPONENT_IDS.ListadoDeCuentas, menuCaption: 'menu:providerCuentas', tabCaption: 'menu:providerCuentas' },
        { id: COMPONENT_IDS.ListadoDeUsuariosOperadoresContenedor, menuCaption: 'menu:usuariosOperadoresList', tabCaption: 'menu:usuariosOperadoresList' }
    ] as MenuOptionConfig[],

    getConfigById(id: string) {
        const menuConfig = this.menuConfig.find(c => c.id === id);
        if (menuConfig) {
            return menuConfig;
        } else {
            return { id: id, menuCaption: 'Not implemented yet ' + id, tabCaption: 'Not implemented yet ' + id };
        }
    },

    getComponentById(id: string, addTab?: (tabCaption: string, component: ReactNode) => void) {
        switch (id) {
            case COMPONENT_IDS.ListadoDePrestadoresContenedor:
                return <ProviderListContainer />
            case COMPONENT_IDS.ListadoDeCuentas:
                return <ProviderAccountListContainer />
            case COMPONENT_IDS.ListadoDeUsuariosOperadoresContenedor:
                return <UsuariosOperadoresListContenedor />
            case 'w1':
                return <></>;

            default:
                return <div />;
        }
    },

}